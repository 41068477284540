<!-- 新建页面模版 -->
<template>
  <div class="big_box d-flex flex-column bg-white">
    <div class="d-flex payment_one align-items-center">
      <div class="circular"></div>
      <div class="To_be_paid">待支付</div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="Rtbp">请在</div>
        <div class="Rtbp_time">{{ hours }}小时 {{ minutes }}分钟 {{ seconds }}秒</div>
        <div class="Rtbp">完成支付，超时后将取消订单</div>
      </div>
      <!-- <div v-if="pay_status === 0 " style="font-size: 18px;color: red;">订单超时已取消</div> -->
    </div>
    <div class="d-flex flex-column justify-content-center payment_two">
      <div class="d-flex">
        <div class="Order_number">订单编号：</div>
        <div class="Order_number">{{ order_sn }}</div>
      </div>
      <div class="d-flex" style="margin-top: 20px;">
        <div class="Order_number">订单价格：</div>
        <div class="Order_money">￥{{ all_data.order_amount }}</div>
      </div>
    </div>
    <div class="d-flex flex-column payment_three">
      <div class="d-flex pack_pay">请选择支付方式</div>
      <div class="d-flex">
        <div @click="getpaytype(item)" v-for="item in all_data.lists"
          :class="{ select_paytype: pay_type === item.pay_way }" :key="item.id"
          class="pay_type d-flex justify-content-center align-items-center">
          <img :src="$getImageUri(item.icon)" alt="抱歉图片加载失败">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column  align-items-end">
      <div @click="showPaymentModal" class="immediate_pay d-flex justify-content-center align-items-center">立即支付</div>
    </div>
    <div class="d-flex justify-content-center align-items-center pop_pay">
      <payment-modal v-if="isPaymentModalVisible" @close="hidePaymentModal" :payData="payData" :pay_name="pay_name"
        :money="money"></payment-modal>
    </div>
  </div>
</template>

<script>
import paymentmodal from '@/components/PaymentModal/PaymentModal.vue'
import { Getpayway, InitiatePay, Getordercancel, GetpayStatus } from "@/api";
export default {
  name: 'payment',
  components: {
    'payment-modal': paymentmodal,
  },
  data() {
    return {
      pay_status: "", // 支付状态
      go_payment: false,
      targetTime: "", // 设置目标时间
      hours: 0,
      minutes: 0,
      seconds: 0,
      timer: null,
      payData: {},  // 生成二维码数据
      pay_name: "",  // 选中支付name
      money: "",  // 支付金额
      pay_type: "", // 支付方式id
      all_data: {},
      order_sn: "",  // 订单编号
      isPaymentModalVisible: false,
    }
  },
  mounted() {
    this.order_sn = this.$route.query.order_sn
    this.Getpayway();
  },
  beforeDestroy() {
    this.clearTimer()
  },
  watch: {
    pay_status(newval, oldval) {
      if (newval == 1) {
        clearInterval(this.timer);
        this.$router.push({ path: "./PersonalCenter" })
        this.$nextTick(() => {  // 使用 $nextTick 延迟触发事件
          // 跳转订单列表
          this.$bus.$emit('select_tabs', '1-1');
        })
      }
    }
  },
  methods: {
    // 清除定时器
    clearTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        const currentTime = new Date();
        const timeDifference = this.targetTime - currentTime;
        if (timeDifference <= 0) {
          clearInterval(this.timer); // 在组件销毁前清除定时器
          this.executeFunction();
        } else {
          this.calculateTime(timeDifference);
        }
      }, 1000);
    },
    // 倒计时计算
    async calculateTime(timeDifference) {
      this.hours = Math.floor(timeDifference / (1000 * 60 * 60));
      this.minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      this.seconds = Math.floor((timeDifference / 1000) % 60);

      let params_ = { sn: this.order_sn, from: 'order' }
      console.log('订单编号', this.order_sn)

      let data_ = await GetpayStatus(params_)
      this.pay_status = data_.data.pay_status;
      console.log('支付状态', this.pay_status)
    },

    // 定时执行函数
    async executeFunction() {
      if (this.pay_status == 1) {
        this.clearTimer()

        this.$message({
          message: '已成功支付',
          type: 'success'
        });

        return this.$router.push({ path: "./PersonalCenter" })
      } else {
        // 取消订单
        let params = { sn: this.order_sn }
        let data = await Getordercancel(params);
        if (data.code) {
          this.isConfirmOrCancel()
        }
      }
    },
    showPaymentModal() {
      if (this.pay_type) {
        let params = {
          sn: this.order_sn,
          from: 'order',
          pay_way: this.pay_type
        }
        // console.log(params, '------生成支付二维码api---参数')

        InitiatePay(params).then((res) => {
          // console.log(res, '------生成支付二维码api---success')

          if (res.code == 1) {
            this.payData = res.data;
            this.isPaymentModalVisible = true;
          } else {
            this.$message({
              message: `抱歉, ${res.msg}`,
              type: 'warning'
            });
          }
        }).catch((err) => {
          console.log(err, '------生成支付二维码api---error')

          this.$message({
            message: '抱歉, 当前支付不可用, 请稍候重试',
            type: 'warning'
          });
        })
      } else {
        this.$message({
          message: '请选择支付方式',
          type: 'warning'
        });
      }
    },
    hidePaymentModal() {
      this.isPaymentModalVisible = false;
    },
    isConfirmOrCancel() {
      this.$confirm(
        '当前订单支付超时，已自动取消',
        '温馨提示',
        {
          confirmButtonText: '确定',
          type: 'warning'
        }
      )
        .then(() => {
          this.clearTimer()
          this.$router.push({ path: "./PersonalCenter" })
        }).catch(() => {
          this.isConfirmOrCancel()
        })
    },
    // 获取可支付列表
    async Getpayway() {
      let params = {
        sn: this.order_sn,
        from: 'order',
        scene: '4'
      }
      let data = await Getpayway(params)
      console.log(data, '-----apiPaymentMethodGet')
      // data.data.lists = data.data.lists.filter(i => i.pay_way == 5 || i.pay_way == 8 || i.pay_way == 14)

      this.all_data = data.data;
      this.money = this.all_data.order_amount;
      this.targetTime = new Date(this.all_data.cancel_time)

      if (this.all_data.lists && this.all_data.lists.length > 0) {
        this.all_data.lists.sort((a, b) => {
          return a.sort - b.sort
        })
        this.pay_type = this.all_data.lists[0].pay_way
      }

      if (this.pay_status == '') this.startTimer()
    },
    getpaytype(item) {
      this.pay_type = item.pay_way;
      this.pay_name = item.name;
    }
  }
}
</script>

<style lang="less" scoped>
.big_box {
  position: relative;
  z-index: 1;
  width: 1200px;
  height: calc(100vh - 378px);
  margin: 0 auto 50px;
}

.circular {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #ef963e;
}

.To_be_paid {
  font-size: 24px;
  color: #1d2129;
  margin: 0px 20px 0px 10px;
}

.Rtbp {
  color: #1d2129;
  font-size: 14px;
}

.Rtbp_time {
  color: #ff334c;
  font-size: 14px;
}

.payment_one {
  padding-left: 20px;
  height: 95px;
}

.payment_two {
  padding-left: 20px;
  height: 100px;
  border-bottom: 1px dashed #e5e6eb;
  border-top: 1px dashed #e5e6eb;
}

.payment_three {
  padding-top: 20px;
  cursor: pointer;
}

.pack_pay {
  margin-left: 20px;
}

.pay_type {
  margin: 20px 0px 0px 20px;
  width: 200px;
  height: 70px;
  border: 1px dashed #c9cdd4;

  img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
}

.select_paytype {
  border: 2px dashed #ff334c;
}

.immediate_pay {
  width: 120px;
  height: 45px;
  background-color: #ff334c;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  margin: 50px 20px 0px 0px;
  cursor: pointer !important;
}

.Order_number {
  font-size: 14px;
  color: #1d2129;
}

.Order_money {
  font-size: 14px;
  color: #ff334c;
}

.pop_pay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}</style>