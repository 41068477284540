import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import config from './config'
import user from './user'
import shopcart from './shopcart'

// 引入插件api
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
	modules: {
		config,
		user,
		shopcart
	},
	plugins: [ // 把vuex的数据存储到sessionStorage    
		createPersistedState({
			storage: window.sessionStorage,
		}),
	]
})