<!-- 新建页面模版 -->
<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center justify-content-between BrowsingHistory">
      <div class="d-flex">
        <div class="active"> <i @click="back_top" style="font-size: 25px;" class="el-icon-back"></i></div>
        <div style="margin-left: 10px;">订单状态</div>
      </div>
      <div class="order_status">{{ order_status_desc }}</div>
    </div>

    <div class="d-flex align-items-center address_remarks">
      <div v-if="alldata.address" class="d-flex align-items-center">
        <div>收货地址:</div>
        <div class="carousel">
          <div class="address_item d-flex flex-column">
            <div class="shop_name">{{ $store.state.user.company_data.company }}</div>
            <div class="d-flex text_number">
              <div>{{ alldata.address.contact }}</div>
              <div style="margin-left: 10px;">{{ alldata.address.mobile }}</div>
            </div>
            <div class="address">{{ alldata.address.address }}</div>
          </div>
        </div>
      </div>
      <div v-if="alldata.user_remark" class="d-flex align-items-center">
        <div>订单备注:</div>
        <div class="carousel">
          <div>{{ alldata.user_remark }}</div>
        </div>
      </div>
      <div v-if="expressName || (alldata.other_info && alldata.other_info.length > 0)" class="d-flex align-items-center">
        <div>其他信息:</div>
        <div class="carousel">
          <div v-if="expressName">快递方式：{{ expressName }}</div>
          <div style="margin-top: 20px;">
            <span>随货信息：</span>
            <div class="d-flex align-items-center">
              <div v-for="(item, index) in alldata.other_info" :key="item + `${index + 1}`">
                <text style="margin-left: 20rpx;">{{ item }}</text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column square">
      <div class="d-flex align-items-center Ordering_title">
        <div class="d-flex text">
          <div>下单时间：</div>
          <div>{{ alldata.create_time }}</div>
        </div>
        <div v-if="alldata.pay_time" class="d-flex text">
          <div>付款时间：</div>
          <div>{{ alldata.pay_time }}</div>
        </div>
        <div class="d-flex text">
          <div>订单编号：</div>
          <div>{{ alldata.sn }}</div>
        </div>
        <div v-if="alldata.express_time" class="d-flex text">
          <div>发货时间：</div>
          <div>{{ alldata.express_time }}</div>
        </div>
        <div v-if="alldata.confirm_take_time" class="d-flex text">
          <div>成交时间：</div>
          <div>{{ alldata.confirm_take_time }}</div>
        </div>
        <div v-if="alldata.refund_create_time" class="d-flex text">
          <div>发起退款时间：</div>
          <div>{{ alldata.refund_create_time }}</div>
        </div>
        <div v-if="alldata.refund_finish_time" class="d-flex text">
          <div>退款到账时间：</div>
          <div>{{ alldata.refund_finish_time }}</div>
        </div>
      </div>
      <div class="d-flex justify-content-between" style="padding: 5px 0px 0px 10px;">
        <div class="box_margin d-flex justify-content-between flex-grow-1">
          <div class="information d-flex justify-content-center">商品信息</div>
          <div class="d-flex justify-content-center">供货价</div>
          <div class="d-flex justify-content-center">券后价</div>
          <div class="d-flex justify-content-center">数量</div>
        </div>
        <div class="Price_width d-flex justify-content-center align-items-center">合计</div>
      </div>
      <div class="d-flex justify-content-center align-items-center justify-content-between"
        style="padding: 10px 0px 20px 10px;">
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex  box_margin align-items-center justify-content-between"
            v-for="(item, index) in  alldata.order_goods" :key="index">
            <div class="d-flex  information">
              <div class="d-flex align-items-center justify-content-center">
                <img style="width: 75px;height: 75px;" :src="$getImageUri(item.image)" alt="抱歉图片加载失败" />
              </div>
              <div class="d-flex flex-column">
                <label style="color: #1D2129;font-size: 14px;font-weight: 600;">{{ item.goods_name }}</label>
                <label>{{ item.spec }}</label>
                <div v-if="item.expired_date" class="d-flex">
                  <label style="color: #FF334C;">效期优于</label>
                  <label>{{ item.expired_date }}</label>
                </div>
                <label>{{ item.supplier_name }}</label>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              ¥{{ item.original_price }}</div>
            <div>¥{{ item.goods_price }}</div>
            <div class="goods_cart_number">x<span>{{ item.goods_num }}</span></div>
          </div>
        </div>
        <div class="Price_width d-flex justify-content-center">¥{{ alldata.order_amount }}</div>
      </div>
    </div>
    <div class="d-flex flex-column flex_width align-items-end">
      <div class="price_detail">
        <div></div>
        <div>
          <div v-if="alldata.original_price" class="CalculatePrice">
            <div>供货价：</div>
            <label>+¥{{ alldata.original_price }}</label>
          </div>
          <div v-if="alldata.discount_sell_amount" class="CalculatePrice discount_reduction">
            <div>代金券：</div>
            <label>-¥{{ alldata.discount_sell_amount.replace('-', '') }}</label>
          </div>
          <div v-if="alldata.goods_sell_price" class="CalculatePrice">
            <div>券后价：</div>
            <label>+¥{{ alldata.goods_sell_price }}</label>
          </div>
          <div v-if="alldata.discount_promotion_amount" class="CalculatePrice discount_reduction">
            <div>活动减免：</div>
            <label>-¥{{ alldata.discount_promotion_amount.replace('-', '') }}</label>
          </div>
          <div v-if="alldata.express_price" class="CalculatePrice">
            <div>基础运费：</div>
            <label>+¥{{ alldata.express_price }}</label>
          </div>
          <div v-if="alldata.discount_express_amount" class="CalculatePrice discount_reduction">
            <div>运费减免：</div>
            <label>-¥{{ alldata.discount_express_amount.replace('-', '') }}</label>
          </div>
          <div v-if="alldata.discount_goods_amount" class="CalculatePrice discount_reduction">
            <div>优惠券减免：</div>
            <label>-¥{{ alldata.discount_goods_amount.replace('-', '') }}</label>
          </div>
          <div v-if="alldata.discount_money_amount" class="CalculatePrice discount_reduction">
            <div>现金券减免：</div>
            <label>-¥{{ alldata.discount_money_amount.replace('-', '') }}</label>
          </div>
          <div v-if="alldata.refund_status == 1" class="CalculatePrice discount_reduction">
            <div>退款总金额：</div>
            <label>-¥{{ alldata.refund_money }}</label>
          </div>
          <div v-if="alldata.refund_status == 1" class="CalculatePrice">
            <div>退款总数量：</div>
            <label>x {{ alldata.refund_number }}</label>
          </div>
          <div class="d-flex align-items-center" style="margin: 20px 0;">
            <span>实付金额</span>
            <div class="pay_amount"><span style="font-size: 16px;">￥</span>{{ alldata.order_amount }}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="d-flex justify-content-center align-items-center">
      <div v-for="(data, index_) in getOrderStatusButtons(alldata.btn)" :key="index_"
        class="d-flex to_money align-items-center justify-content-center">
        <el-button @click="onOrderButtons(data.event, alldata.sn, alldata.invoicePath)" :type="data.style">{{ data.name
        }}</el-button>
      </div>
    </div>

    <!-- 查看物流弹窗 -->
    <el-dialog title="物流信息" :visible.sync="logisticsPopup" width="30%" center :modal-append-to-body="false">
      <el-timeline>
        <el-timeline-item v-for="(ls, index) in logisticsInfo" :key="index" :icon="ls.icon" :type="ls.type"
          :color="ls.color" :size="ls.size" :timestamp="ls.time">
          {{ ls.desc }}
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
  </div>
</template>

<script>
import { Getorderdetail } from "@/api";
import { Getorderlists, Getorderconfirm, GetorderorderTraces, Getordercancel, GetorderbuyAgain, GetorderExpressIdList } from '@/api'
export default {
  name: 'orderList-details',
  props: {
    order_status_desc: {
      type: String,
      default: ""
    },
    sn: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      alldata: {},
      ButtonsMap: {
        payment: {
          event: 'payment',
          name: '立即付款',
          style: 'danger'
        },
        succeed: {
          event: 'succeed',
          name: '确认收货',
          style: 'success'
        },
        evaluate: {
          event: 'evaluate',
          name: '去评价',
          style: 'info'
        },
        content: {
          event: 'content',
          name: '查看内容',
          style: 'info'
        },
        express: {
          event: 'express',
          name: '查看物流',
          style: 'info'
        },
        close: {
          event: 'close',
          name: '取消订单',
          style: 'warning'
        },
        delete: {
          event: 'delete',
          name: '删除订单',
          style: 'danger'
        },
        pickup: {
          event: 'pickup',
          name: '查看提货码',
          style: 'normal'
        },
        invoice: {
          event: 'invoice',
          name: '电子发票',
          style: 'info'
        },
        again: {
          event: 'again',
          name: '再次购买',
          style: 'primary'
        }
      },
      logisticsPopup: false,
      logisticsInfo: [],
      orderRemarkActive: 1,
      expressName: ''
    }
  },
  mounted() {
    this.Getorderdetail();
  },
  methods: {
    back_top() {
      this.$emit('Getback', false)
    },
    // 获取订单其他信息
    getOrderOtherInfo() {
      GetorderExpressIdList().then((res) => {
        this.expressName = res.data.find(i => i.id == this.alldata.express_id).name
      })
    },
    // 获取订单详情
    Getorderdetail() {
      if (this.sn) {
        let params = { sn: this.sn }
        Getorderdetail(params).then((res) => {
          this.alldata = res.data
        }).then(() => {
          this.getOrderOtherInfo()
        }).catch((err) => {
          console.log(err, '------获取订单详情api---error')
        })
      }
    },
    // 获取订单状态按钮组
    getOrderStatusButtons(buttonStatus) {
      let buttons = [];

      if (buttonStatus) {
        // 删除
        // buttonStatus.delete_btn && buttons.push(this.ButtonsMap['delete'])
        // 取消订单
        buttonStatus.cancel_btn == 1 && buttons.push(this.ButtonsMap['close'])
        // 查看内容
        buttonStatus.content_btn == 1 && buttons.push(this.ButtonsMap['content'])
        // 查看物流
        buttonStatus.delivery_btn == 1 && buttons.push(this.ButtonsMap['express'])
        // 评论
        buttonStatus.comment_btn == 1 && buttons.push(this.ButtonsMap['evaluate'])
        // 确认收货
        buttonStatus.confirm_btn == 1 && buttons.push(this.ButtonsMap['succeed'])
        // 提货码
        buttonStatus.pickup_btn == 1 && buttons.push(this.ButtonsMap['pickup'])
        // 立即付款
        buttonStatus.pay_btn == 1 && buttons.push(this.ButtonsMap['payment'])
        // 再次购买
        buttonStatus.again_btn == 1 && buttons.push(this.ButtonsMap['again'])
        // 电子发票
        buttonStatus.invoice_btn == 1 && buttons.push(this.ButtonsMap['invoice'])
      }
      return buttons
    },

    // 点击订单按钮映射处理方法
    onOrderButtons(event, orderID, invoicePath) {
      switch (event) {
        // 立即付款(跳转付款页面，传sn)
        case 'payment':
          return this.handlePayment(orderID)
        // 确认收货(/order/confirm)
        case 'succeed':
          return this.handleSucceed(orderID)
        // 评论(无操作)
        case 'evaluate':
          return this.handleEvaluate(orderID)
        // 查看物流(跳转弹窗掉接口/order/orderTraces)
        case 'express':
          return this.handleExpress(orderID)
        // 取消订单(/order/cancel)
        case 'close':
          return this.handleClose(orderID)
        // 提货码(无操作)
        case 'pickup':
          return this.handlePickup(orderID)
        // 查看内容(无操作)
        case 'content':
          return this.handleContent(orderID)
        // 电子发票
        case 'invoice':
          return this.handleInvoice(orderID, invoicePath)
        // 再次购买(掉接口/order/buyAgain, 跳转购物车页面) 
        case 'again':
          return this.handleBuyAgain(orderID)
      }
    },
    // 立即付款
    handlePayment(id) {
      this.$router.push({ path: "./payment", query: { order_sn: id } })
    },
    // 确认收货
    async handleSucceed(id) {
      let params = { sn: id }
      let data = await Getorderconfirm(params);
      console.log('确认收货', data)
      this.Getorderlists()
    },
    // 查看物流
    async handleExpress(id) {
      let params = { sn: id }
      let res = await GetorderorderTraces(params);

      let data = res.data.traces
      if (data.length > 0) {
        for (var i in data) {
          if (i == 0) {
            data[i]['size'] = 'large'
            data[i]['type'] = 'primary'
          }
        }

        this.logisticsInfo = data
        this.logisticsPopup = true
      } else {
        this.$message({
          type: 'warning',
          offset: this.$store.state.config.outer_height,
          message: '抱歉，当前暂无物流信息'
        })
      }
    },
    // 取消订单
    async handleClose(id) {
      let params = { sn: id }
      let data = await Getordercancel(params);
      console.log('取消订单', data)
      this.Getorderlists()
    },
    // 电子发票
    handleInvoice(id, path) {
      if (path) {
        axios(path, {//pdf的位置
          responseType: 'blob', //重要代码
        }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = "先大拼药药电子发票.pdf" //保存到本地的文件名称
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
      } else {
        this.$message({
          type: 'warning',
          offset: this.$store.state.config.outer_height,
          message: '抱歉，当前暂无电子发票信息'
        })
      }
    },
    // 再次购买
    async handleBuyAgain(id) {
      let params = { sn: id }
      let data = await GetorderbuyAgain(params);
      this.$isRouterParams('ShoppingCart')
    }
  }
}
</script>

<style></style>

<style lang="less" scoped>
.address_remarks {
  padding: 0 20px 0 30px;
  font-size: 16px;
  flex-wrap: wrap;

  div {
    margin-right: 50px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.carousel {
  padding: 10px 10px;
  margin: 20px 10px 20px 0;
  width: 260px;
  border: 1px solid #c9cdd4;
  overflow-y: auto;

  .address_item {
    line-height: 20px;
  }

  .shop_name {
    color: #1d2129;
    font-size: 14px;
    font-weight: 600;
  }

  .text_number {
    margin: 5px 0px 0px 0px;
    color: #1d2129;
    font-size: 14px;
  }

  .address {
    color: #1d2129;
    font-size: 14px;
    margin: 10px 0px 0px 0px;
  }
}

.BrowsingHistory {
  width: 95%;
  margin: 0 auto;
  padding-top: 10px;
  height: 30px;
  border-bottom: 1px solid #e5e6eb;
  color: #1d2129;
  font-size: 16px;

  .order_status {
    color: #ff334c;
    border: 1px dashed #ff334c;
    padding: 3px 5px;
    border-radius: 10px;
  }
}

.Ordering_title {
  width: 100%;
  border-bottom: 2px dashed #c9cdd4;
  padding: 10px 20px;
  white-space: nowrap;

  .text {
    font-size: 14px;
    color: #1d2129;
    margin: 0 10px;

    div {
      &:nth-child(1) {
        font-weight: 550;
      }
    }
  }
}

.square {
  overflow-x: auto;
  width: 95%;
  margin: 0 auto;
  border: 1px solid #c9cdd4;
}

.information {
  width: 500px;
}

.goods_cart_number {
  font-weight: 600;

  span {
    font-size: 14px;
  }
}

.Price_width {
  width: 100px;
  margin-top: 10px;
}

.box_margin {
  margin: 10px 20px 0px 10px;

  label {
    font-size: 14px;
    color: #4e5969;
    margin: 5px 0px 0px 5px;
  }
}

.small_box {
  line-height: 50px;
  margin-left: 30px;

  span {
    font-size: 16px;
    font-weight: 600;
  }

  label {
    font-size: 18px;
    color: #1d2129;
  }
}

.flex_width {
  width: 95%;
  margin: 0 auto;
  line-height: 40px;

  span {
    font-weight: 550;
    font-size: 18px;
    margin-right: 20px;
  }

  label {
    color: #1d2129;
    font-size: 20px;
  }

  .pay_amount {
    font-size: 28px;
    color: #ff334c;

    span {
      margin-right: 0;
    }
  }
}

.active:hover {
  cursor: pointer;
  color: #ff334c;
}

.to_money {
  margin: 5px 0px 0px 10px;
}

.price_detail {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  font-size: 14px;
  padding: 20px 20px 0;
}

.CalculatePrice {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 180px;

  label {
    margin: 0px 0px 0px 30px;
  }
}

.discount_reduction {
  label {
    color: #ff334c;
  }
}
</style>