<!-- 优惠券组件 -->
<template>
  <div class="d-flex flex-wrap">
    <div class="d-flex flex-column justify-content-between smallbox" :style="getbackground(item.type, item.is_receive)" v-for="(item, index) in list" :key="index">
      <div class="d-flex align-items-center" style="padding-left: 10px;">
        <div class="d-flex align-items-end ">
          <div class="money">¥</div>
          <div class="money_num">{{ item.money }}</div>
        </div>
        <div class="d-flex flex-column" style="padding-left: 20px;">
          <div class="name_style">{{ item.name }}</div>
          <div class="condition_style">{{ item.condition == undefined ? item.condition_tips : item.condition }}
          </div>
        </div>
      </div>
      <div class="effective_time">{{ item.effective_time == undefined ? item.use_time_tips : item.effective_time }}
      </div>
      <div class="d-flex align-items-center justify-content-between use_">
        <div v-if="item.use_scene || item.goods_tips" class="use_scene">
          {{ item.use_scene == undefined ? item.goods_tips : item.use_scene }}
        </div>
        <div v-else class="use_scene">无优惠券介绍</div>
        <!-- 未使用状态 -->
        <div v-if="!input_state && !isDisabled && item.status == 0" @click="Get_Voucher(item)" class="is_available">
          去使用
        </div>
        <!-- 未领取&已领取状态 -->
        <div v-else-if="!input_state && !isDisabled && item.status != 1 && item.status != 3" @click="Get_Voucher(item)" :class="item.is_available != 1 && item.is_empty != 1 ? 'is_unclaimed' : 'is_available'">
          {{ item.is_available == 1 ? '已领取' : item.is_empty == 1 ? '已抢光' : '领取' }}
        </div>
        <!-- 选择框状态 -->
        <div v-if="input_state && !is_disabled_checkbox">
          <div v-if="is_radio && item.is_select" class="is_available">已选择</div>
          <input v-else v-model="item.is_select" :disabled="is_disabled_checkbox" @change="change_checkbox(item)" :type="is_radio ? 'radio' : 'checkbox'" />
        </div>
      </div>
      <div v-if="item.is_receive == 1" class="d-flex absolute">
        <img style="width: 53px;height: 53px;" src="./images/Received.png" alt="图片已丢失" />
      </div>
    </div>
  </div>
</template>

<script>
import { Getcouponreceive } from "@/api"
export default {
  name: 'voucher',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    // type用来控制领券中心的优惠券类型
    type: {
      type: String,
      default: ''
    },
    // 是否禁用优惠券
    isDisabled: {
      type: Boolean,
      default: false
    },
    // 是否显示复选框
    input_state: {
      type: Boolean,
      default: false
    },
    // 是否显示单选框
    is_radio: {
      type: Boolean,
      default: false
    },
    // 是否禁用复选框
    is_disabled_checkbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOptions: [], // 选中数组
      new_list: [],
    }
  },
  watch: {
    list(newval) {
      if (newval) this.new_list = this.list;
    },
  },
  methods: {
    // 领取优惠券
    async Get_Voucher(data) {
      if (data.is_available == 1 || data.is_empty == 1) return false
      if (data.status == 0) return this.$isRouterParams('gategory')

      let params = { id: data.id };
      let dataobjet = await Getcouponreceive(params);
      console.log('领取状态', dataobjet)
      if (dataobjet.code == 1) {
        this.$message({
          type: 'success',
          message: '领取成功'
        })
      } else {
        this.$message.error(dataobjet.msg);
      }
      this.$emit('ToRefresh')
    },
    getbackground(type, state_type) {
      if (state_type == 1) {
        return 'background: linear-gradient(90deg, #999999 0%, #C7C7C7 100%)';
      } else {
        if (type) {
          switch (type) {
            case 1:
              return 'background: linear-gradient(135deg, #ffb983 0%, #ff334c 100%)';
            case 2:
              return 'background: linear-gradient(135deg, #91D2FF 0%, #0082DC 100%)'
            case 3:
              return 'background: linear-gradient(135deg, #FF9D72 0%, #FE611C 100%)'
            case 4:
              return 'background: linear-gradient(135deg, #9feab6 0%, #13ab43 100%)'
            default:
              break;
          }

        }
      }
    },
    change_checkbox(data) {
      if (data.is_select) {
        // 选中状态
        // 一、  type相同
        if (this.selectedOptions.some(item => item.type === data.type)) {
          // 给原始数组替换新选中的id
          let old_id = "";
          this.selectedOptions = this.selectedOptions.map(item => {
            if (item.type === data.type) {
              old_id = item.id;
              return { id: data.id, type: data.type };
            }
            return item;
          });
          this.new_list = this.new_list.map(obj => {
            if (obj.id === old_id) {
              return { ...obj, is_select: false };
            }
            return obj;
          });
          // 二、  type选中1和4
        } else if (this.selectedOptions.some(item => (item.type == 1 && data.type == 4) || (item.type == 4 && data.type == 1))) {
          this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: '抱歉，商品优惠券与折扣券只可选择一种'
          })

          this.$nextTick(() => {
            data.is_select = !data.is_select
            this.$forceUpdate()
          })
          const removeObj = { id: data.id, type: data.type };
          this.selectedOptions = this.selectedOptions.filter(item => item.id !== removeObj.id);
        } else {
          this.selectedOptions.push({
            id: data.id,
            type: data.type
          });
        }
      } else {
        // 取消状态,删除id和type
        const removeObj = { id: data.id, type: data.type };
        this.selectedOptions = this.selectedOptions.filter(item => item.id !== removeObj.id);
      }
      this.$emit('GetselectedOptions', data)
    }
  }
}
</script>

<style lang="less" scoped>
.smallbox {
  position: relative;
  width: 275px;
  height: 120px;
  color: #ffffff;
  margin: 0 15px 15px 0;
  overflow: hidden;

  &:nth-last-child(1) {
    margin-right: 0;
  }

  .absolute {
    position: absolute;
    top: -5px;
    right: 0px;
  }
}

.money {
  font-size: 18px;
  margin-bottom: 5px;
}

.money_num {
  font-size: 40px;
  padding: 0px 0px 0px 5px;
}

.name_style {
  font-size: 16px;
}

.condition_style {
  font-size: 10px;
}

.effective_time {
  font-size: 12px;
  color: #ffffff;
  padding: 0px 0px 0px 10px;
}

.use_ {
  padding: 10px 5px;
  background: linear-gradient(90deg, #f2f2f2 0%, #f2f2f2 100%);
}

.use_scene {
  color: #4e5969;
  font-size: 14px;
}

.is_unclaimed,
.is_available {
  cursor: pointer;
  color: #ff334c;
  font-size: 14px;
}

.is_unclaimed {
  padding: 2px 8px;
  border-radius: 10px;
  background-color: #fa6576;
  color: #ffffff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
</style>