<template>
  <div class="loading-overlay">
    <div>{{ Text }}</div>
    <div class="loading-spinner">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    Text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.loading-overlay {
  font-size: 18px;
  color: #ccc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /*遮罩层的颜色和不透明度 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /*设置遮罩层的层级，确保覆盖在其他元素之上 */
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff334c;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>