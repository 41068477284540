// 标签品牌商品卡片
<template>
    <div v-if="goodsListObj.content.goodsList.length > 0" class="exchange" @click="toGategory('')" >
        <div class="exchange_top" 
            :style="{ background: `repeating-linear-gradient(to bottom,${goodsListObj.color},${goodsListObj.color_end}` }">
            <div class="exchange_left" :style="{ color: this.titleColor }">
                <div class="text_one">{{ goodsListObj.title }}</div>
                <div class="text_two" :style="{ color: this.subTitleColor }">{{ goodsListObj.subheading }}</div>
            </div>
            <div class="exchange_right" :style="{ color: this.subTitleColor }">
                <div>查看更多</div>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <div class="exchange_bottom">
            <div class="item_box" v-for="(item, index) in goodsListObj.content.goodsList" :key="index" @click.stop="toGategory(item.id)">
                <img class="medicine" :src="$getImageUri(item.pc_image)" alt="抱歉图片加载失败" />
            </div>
            <template v-for="index in goodsNumber">
                <div v-if="goodsListObj.content.goodsList && index > goodsListObj.content.goodsList.length" class="item_box"
                    :key="index">
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "goodsCard-index",
    props: {
        goodsListObj: {
            type: Object,
            default: () => { }
        },
        titleColor: {
            type: String,
            default: () => '#ffffff'
        },
        subTitleColor: {
            type: String,
            default: () => '#ffffff'
        }
    },
    computed: {
        goodsNumber() {
            return Math.ceil(this.goodsListObj.content.goodsList.length / 5) * 5;
        }
    },
    methods: {
        toGategory(id) {
              this.$isRouterParams('gategory', { type: 'brand', id: id })
        }
    }
}
</script>

<style lang="less" scoped>
.medicine {
    width: 100%;
    // height: 173.077px;
}

.exchange_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.exchange {
    width: 1200px;
    margin: 20px auto 0;
    border-radius: 10px;
    background-color: #fff;
    cursor: pointer;

    .exchange_top {
        height: 82.692px;
        border-radius: 15.385px 15.385px 0px 0px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 15.385px;

        .exchange_left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;


            .text_one {
                color: #1D2129;
                font-size: 24px;
            }

            .text_two {
                font-size: 14px;
                margin-left: 20px;
            }
        }
    }

    .exchange_bottom {
        display: flex;
        // align-items: flex-start;
        justify-content: space-between;
        padding: 0 15.385px;
        flex-flow: wrap;
        align-content: flex-start;

        .item_box {
            flex: 0 0 18%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // height: 250px;

            // border-radius: 0px 0px 15.385px 15.385px;
            // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
            margin-top: 20px;

            .box_top {
                width: 211.538px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 30.769px;
                background: #ff334c;
                font-size: 19.231px;
                color: #ffffff;
                padding: 6px;
                box-sizing: border-box;
            }

            .detail {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                padding: 5px 0 15.385px 0;
                box-sizing: border-box;

                .detail_name {
                    width: 100%;
                    font-size: 18px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .specifications {
                    color: #86909c;
                    font-size: 14px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .detail_price {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .new_price {
                        color: #ff334c;
                        font-size: 16px;
                    }

                    .old_price {
                        color: #86909c;
                        font-size: 14px;
                        text-decoration: line-through;
                        margin-left: 10.462px;
                    }
                }
            }
        }
    }
}
</style>