// 诊所联盟
<template>
  <div class="join-alliance">
    <div v-if="qualificationMark == 0">
      <el-radio v-model="isJoinAlliance" :disabled="isDisabledJoin" @input="isJoinChecked"><span>{{ joinAllianceMessage }}</span></el-radio>
    </div>
    <div class="rich-text-container">
      <div v-html="content" class="content_html"></div>
    </div>
  </div>
</template>

<script>
import {
  apiGetClinicAlliance,
  apiGetShopAgreement,
  apiSetClinicAlliance
} from '@/api/index'

export default {
  name: "clinic-alliance",
  data() {
    return {
      content: '',
      isDisabledJoin: false,
      isJoinAlliance: false,
      companyId: 0,
      joinAllianceMessage: '加入诊所联盟',
      // 0-我的页面跳转 1-资质页面跳转
      qualificationMark: 0
    }
  },
  mounted() {
    let getParams = this.$store.state.config.clinicAlliance_params
    if (getParams && getParams.company_id) {
      this.qualificationMark = 1
      this.isGetShopAgreement(getParams)
    } else {
      this.isGetClinicAllianceDetail()
    }
  },
  beforeDestroy() {
    this.$store.commit('setCAparams', '')
  },
  methods: {
    isGetShopAgreement(params) {
      apiGetShopAgreement({
        company_id: params.company_id,
        type: 'clinic_alliance'
      }).then(res => {
        this.content = res.data.content
        this.$store.commit('setCAparams', '')
      }).catch(err => {
        console.log(err, '------诊所联盟协议api---error')
      })
    },
    isGetClinicAllianceDetail() {
      apiGetClinicAlliance().then(res => {
        console.log(res, '------诊所联盟详情api---success')

        this.content = res.data.content
        if (res.data.user_clinic_alliance == 1) {
          this.isJoinAlliance = true
          this.isDisabledJoin = true
          this.joinAllianceMessage = '您已加入诊所联盟'
        }
      }).catch(err => {
        console.log(err, '------诊所联盟详情api---error')
        return this.$message({
          type: 'error',
          offset: this.$store.state.config.outer_height,
          message: '抱歉, 获取诊所联盟信息失败, 请稍候重试'
        })
      })
    },
    isJoinChecked() {
      if (this.isDisabledJoin == false) {
        this.$confirm(
          `您确定要加入诊所联盟吗`,
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            apiSetClinicAlliance().then(res => {
              this.$message({
                type: 'success',
                offset: this.$store.state.config.outer_height,
                message: '加入诊所联盟成功'
              })
              this.isJoinAlliance = true
              this.isDisabledJoin = true
              this.joinAllianceMessage = '您已加入诊所联盟'
            }).catch(err => {
              console.log(err, '------加入诊所联盟api---error')
              return this.$message({
                type: 'error',
                offset: this.$store.state.config.outer_height,
                message: err
              })
            })
          })
          .catch(() => {
            this.isJoinAlliance = false
          })
      }
    }
  }
}
</script>

<style>
.rich-text-container {
  font-size: 16px;
  line-height: 30px;
}

.rich-text-container img {
  width: 450px;
  height: auto;
  margin: 5px 5px;
}
</style>

<style lang="less" scoped>
.join-alliance {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 980px !important;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;

  div {
    :nth-child(1) {
      margin: 10px auto;
    }
  }
}

.content_html {
  height: 90vh;
}
</style>