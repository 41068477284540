<template>
  <div class="payment_modal d-flex flex-column align-items-center justify-content-center"
    :style="{ width: outer_width - 16.2 + 'px', height: outer_height + 60 + 'px' }">
    <!-- 弹窗内容 -->
    <div class="pay_pop d-flex flex-column align-items-center">
      <div class="d-flex align-items-center" style="padding: 20px 20px; width: 100%;">
        <div></div>
        <div class="d-flex flex-grow-1 justify-content-center pay_size">{{ pay_name }}</div>
        <i class="el-icon-close colsesize" @click="$emit('close')"></i>
      </div>
      <div id="payType" class="square"></div>
      <div class="d-flex Scan justify-content-center">
        {{ payData.pay_way == 5 ? '请使用微信扫一扫支付' : payData.pay_way == 8 ? '请使用支付宝扫一扫支付' : '请使用微信或支付宝扫一扫支付' }}
      </div>
      <div class="d-flex six">
        <div class="preee">
          需付金额：
        </div>
        <div class="moneyyy">
          <span>¥</span>{{ money }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
  name: 'payment-modal',
  props: {
    payData: {
      type: Object,
      default: () => { }
    },
    money: {
      type: String,
      default: ""
    },
    pay_name: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      aliform: "",
      outer_width: 3000,
      outer_height: 2000,
    }
  },
  created() {
    this.outer_width = window.outerWidth
    this.outer_height = window.outerHeight
  },
  mounted() {
    console.log('payType', this.payData)

    // 支付宝支付
    if (this.payData.pay_way == 3) {
      // this.aliform = this.payData.config;  //data.data就是支付宝返回给你的form,获取到的表单内容,具体样子可见上面的图片
      // this.$nextTick(() => {
      //     // 获取订单详情来轮询支付结果
      //     // this.getOrderDetail();
      //     document.write(this.aliform)
      // });
      // const htmlString = this.payData.config;
      // console.log('htmlString', htmlString)
      // const tempDiv = document.createElement('div');
      // tempDiv.innerHTML = htmlString;
      // const formElement = tempDiv.querySelector('form');
      // const formAction = formElement.getAttribute('action');
      // // console.log('获取地址', formAction);
      // this.Generate_QR_code(formAction)

      // 假设result是后端返回的from字符串
      const newWindow = window.open('', '_self');
      newWindow.document.write(this.payData.config);
      newWindow.focus();


      // document.write(this.payData.config)

      // 微信扫码代付 || 招行扫码支付 || 招行代付
    } else if (this.payData.pay_way == 5 || this.payData.pay_way == 8 || this.payData.pay_way == 14) {
      this.Generate_QR_code(this.payData.config.code_url)
    }
    // 余额支付
    else if (this.payData.pay_way == 7) {
      this.$message({
        message: '抱歉, 当前支付不可用, 请稍候重试',
        offset: this.$store.state.config.outer_height,
        type: 'warning'
      });
    } else {
      console.log(this.payData.pay_way, '------支付pay_way---error')

      this.$message({
        message: '抱歉, 当前支付不可用, 请稍候重试',
        offset: this.$store.state.config.outer_height,
        type: 'warning'
      });
    }

    window.addEventListener('resize', () => {
      this.outer_width = window.outerWidth
      this.outer_height = window.outerHeight
    })
  },
  methods: {
    Generate_QR_code(url) {
      new QRCode(document.getElementById('payType'), {
        text: url,
        width: 250, // 二维码宽度
        height: 250 // 二维码高度
      });
    }
  }
  // 子组件的逻辑和方法
};
</script>

<style scoped>
.payment_modal {
  z-index: 10085;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.pay_pop {
  z-index: 10086;
  width: 700px;
  height: 490px;
  background-color: #ffffff;
}

.colsesize {
  font-size: 30px;
}

.pay_size {
  color: #1d2129;
  font-size: 18px;
}

#payType {
  margin: 30px 20px 0px 0px;
}

.Scan {
  font-size: 18px;
  color: #1d2129;
  width: 100%;
  margin: 20px 0px 0px 0px;
}

/deep/.preee {
  font-size: 18 !important;
  color: #1d2129;
  font-weight: 500;
}

/deep/.moneyyy {
  font-size: 28 !important;
  color: #ff334c;

  span {
    font-size: 18px !important;
    margin-right: 2px;
  }
}

.six {
  margin: 20px 0px 0px 0px;
  font-size: 18px;
}

.aliform {
  width: 1px;
  height: 1px;
  opacity: 0;
}
</style>