<template>
  <div class="no-data">
    <img class="img_" src="./images/nodata.png" alt="No Data Image">
  </div>
</template>

<script>
export default {
  name: 'NoData'
}
</script>

<style>
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 185px - 140px);
  font-size: 16px;
  color: #999;
}
