<!-- 商品详情 -->
<template>
  <div class="big_box d-flex flex-column">
    <div class="d-flex flex-column" v-if="isLoading && datalist">
      <div class="d-flex bg-white detail_top_info">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-center align-items-start img_currentImage">
            <el-tooltip class="item" effect="dark" content="点击图片可预览大图" placement="left-start">
              <el-image style="width: 420px; height: 350px" :src="$getImageUri(currentImage)" :preview-src-list="imglist">
              </el-image>
            </el-tooltip>
          </div>
          <div class="d-flex align-items-center box_prevImage"
            :style="{ overflowX: imglist.length > 4 ? 'scroll' : 'hidden' }">
            <div class="prevImage" @click="prevImage"><i class="el-icon-arrow-left"></i></div>
            <div v-for="(image, index) in imglist" :key="index" @click="selectImage(index)"
              :class="{ 'selected': currentIndex === index }" class="selectImage">
              <img style="height: 70px;width: 70px;" :src="$getImageUri(image)" alt="抱歉图片加载失败">
            </div>
            <div class="nextImage" @click="nextImage"><i class="el-icon-arrow-right"></i></div>
          </div>
        </div>

        <div class="d-flex flex-column Advent_box">
          <div class="d-flex flex-column Advent_center_box">
            <div class="d-flex align-items-center Advent_content_box">
              <div class="Advent">{{ datalist.expired_type_name ? datalist.expired_type_name : '想买便宜药 先大拼药药'
              }}<span>|</span></div>
              <div class="Advent_num">{{ datalist.expired_type_name ? '数量有限 售完为止' : '中国医药大健康资源聚合平台' }} </div>
            </div>
            <div class="d-flex align-items-center Undershirt mgt10">
              <span v-if="datalist.medical_insurance"
                :class="{ 'class-A': datalist.medical_insurance == '甲类', 'class-B': datalist.medical_insurance == '乙类' }">医保{{
                  datalist.medical_insurance }}</span>
              <span>{{ datalist.name }}</span>
            </div>
            <div class="specifications mgt10">
              <span>规格</span>
              <span>{{ datalist.spec }}</span>
            </div>

            <div class="price_info mgt10">
              <div class="price_info_top">
                <div class="top_price">
                  <div>
                    <span class="mgr30">供货价:</span>
                    <span class="mgr30"><span style="font-size: 14px;">￥</span>{{ datalist.lineation_price }}</span>
                  </div>
                  <div class="mgt10">
                    <span class="mgr30" v-if="datalist.activity_sn == '' || datalist.activity_sn == -1">券后价:</span>
                    <span class="mgr30" v-else>活动价:</span>
                    <el-tooltip v-if="isCheckShowQuota(datalist)" :value="true" effect="dark" placement="right-start">
                      <div slot="content" v-for="(collapse_item, collapse_index) in datalist.step_price"
                        :key="collapse_index">
                        购买{{ collapse_item.start_num }}-{{ collapse_item.end_num }}盒享{{ priceName(datalist.activity_sn)
                        }}<span style="color: #FF334C"><span style="font-size: 10px;">￥</span>{{ collapse_item.price
}}</span>
                      </div>
                      <span><span class="text_theme_color" style="font-size: 16px;">￥</span><span
                          class="activity_price_style">{{ datalist.activity_status == 2 ? datalist.activity_price :
                            datalist.sell_price }}</span></span>
                    </el-tooltip>
                    <span v-else><span class="text_theme_color" style="font-size: 16px;">￥</span><span
                        class="activity_price_style">{{ datalist.activity_status == 2 ? datalist.activity_price :
                          datalist.sell_price }}</span></span>
                  </div>
                </div>
                <div class="monthly_sales">
                  <p>{{ datalist.sales_num }}</p>
                  <p>月销/件</p>
                </div>
              </div>
              <div v-if="datalist.coupon && datalist.coupon.length > 0" @click="couponPopup = true"
                class="price_info_bottom">
                <div class="d-flex align-items-center">
                  <span class="mgr30">优惠券</span>
                  <div class="d-flex align-items-center justify-content-center voucher" @click="couponPopup = true">{{
                    datalist.coupon[0].name }}</div>
                </div>
                <div>
                  <i style="font-size: 20px;" class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center cost">
              <div class="freight">邮</div>
              <div class="FreeShipping">{{ datalist.freight_fee_text }}</div>
            </div>

            <div class="d-flex justify-content-between align-items-center Summary">
              <div class="d-flex flex-column">
                <div class="retail">零售价</div>
                <div class="retail_price">¥{{ datalist.cost_price }}</div>
              </div>
              <div class="d-flex flex-column">
                <div class="profit">赚</div>
                <div class="profit_price">{{ Math.floor((datalist.cost_price - datalist.sell_price) * 100) / 100 }}</div>
              </div>
              <div class="d-flex flex-column">
                <div class="interest">毛利率</div>
                <div class="interest_rate">¥{{ datalist.rate }}</div>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between filter_box">
              <div class="d-flex justify-content-between align-items-center filter_box_width">
                <div
                  v-if="datalist.stock > 0 && (datalist.is_activity_goods == 1 && datalist.activity_stock <= 0) || datalist.stock <= 0">
                  <el-button type="info" round disabled>已售罄</el-button>
                </div>
                <div v-else class="d-flex align-items-center">
                  <div>数量</div>
                  <div class="d-flex filter_box_margin">
                    <el-input-number ref="in"
                      @change="(currentValue, oldValue) => changenum(currentValue, oldValue, datalist)"
                      :step="datalist.step_number" @input="handleInput" size="large" v-model="cartNumber"
                      :key="inputNumberKey" :min="0" label="描述文字" @focus="stepperFocus"
                      @blur="isStepperFocusMark = false"></el-input-number>
                  </div>
                </div>
                <div class="filter_color">
                  库存：{{ datalist.stock }}
                </div>
                <div class="d-flex">
                  <el-button type="danger" @click="addCart">加入购物车</el-button>
                </div>
                <div class="d-flex filter_color collect_goods"
                  :class="datalist.is_collect ? 'collect_goods_selected' : ''" @click="collect(datalist.is_collect)">
                  <i :class="datalist.is_collect ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
                  <span style="margin-left: 5px">{{ datalist.is_collect ? '取消收藏' : '收藏商品' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 活动商品 -->
      <div v-if="datalist.activity_sn && datalist.activity_sn != -1" class="d-flex special_box">
        <div class="d-flex align-items-center justify-content-center flex-grow-1">
          <!-- <div class="hydrochloric">{{ datalist.name }}</div> -->
          <div class="d-flex align-items-center justify-content-center special_small">
            <span v-if="datalist.activity_type_text" :style="Getpickcolor(datalist.activity_type_text)"
              class="specialoffer">
              {{ datalist.activity_type_text }}活动商品</span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-grow-1 ">
          <div class="d-flex flex-column limit">
            <div>
              {{ datalist.activity_intro }}
            </div>
            <div>活动时间：{{ datalist.activity_start_time }} 至 {{ datalist.activity_end_time }}</div>
          </div>
        </div>
      </div>
      <!-- 商品组合包含商品 -->
      <div v-if="goodsGroupList && goodsGroupList.length > 0" class="d-flex flex-column details_box">
        <div class=" d-flex align-items-center justify-content-center details">
          商品组合包含商品
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <commodity :tyoe_list="goodsGroupList" isDisabledStep></commodity>
        </div>
      </div>
      <div class="d-flex flex-column tabel_box">
        <div class=" d-flex align-items-center justify-content-center commodity">
          商品参数
        </div>
        <div class="d-flex justify-content-center">
          <table class="table">
            <tbody>
              <tr>
                <td>商品效期</td>
                <td style="width: 200px;">{{ datalist.expired_date }}</td>
                <td>生产厂家</td>
                <td style="width: 200px;">{{ datalist.supplier_name }}</td>
              </tr>
              <tr>
                <td>包装单位</td>
                <td>{{ datalist.unit_name }}</td>
                <td>规格</td>
                <td>{{ datalist.spec }}</td>
              </tr>
              <tr>
                <td>中包装</td>
                <td>{{ datalist.middle_package_number }}/{{
                  datalist.middle_package_unit_name }}</td>
                <td>件数量</td>
                <td>{{ datalist.large_package_number }}/{{
                  datalist.large_package_unit_name }}</td>
              </tr>
              <tr>
                <td>批准文号</td>
                <td>{{ datalist.approval_no }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <recommand></recommand>
      <div v-if="datalist.content" class="d-flex flex-column details_box">
        <div class=" d-flex align-items-center justify-content-center details">
          商品详情
        </div>
        <div class="rich-text-container d-flex align-items-center justify-content-center">
          <div v-html="datalist.content"></div>
        </div>
      </div>
    </div>
    <NoData v-if="!datalist"></NoData>

    <!-- 领取优惠券弹窗 -->
    <el-dialog title="领优惠券" :visible.sync="couponPopup" width="30%" center :modal-append-to-body="false">
      <div class="d-flex flex-wrap">
        <div class="d-flex flex-column justify-content-between smallbox"
          :style="getbackground(item.type, item.is_receive)" v-for="(item, index) in couponList" :key="index">
          <div class="d-flex align-items-center" style="padding: 0px 0px 0px 10px;">
            <div class="d-flex align-items-end ">
              <div class="money">¥</div>
              <div class="money_num">{{ item.money }}</div>
            </div>
            <div class="d-flex flex-column" style="padding: 0px 0px 0px 20px;">
              <div class="name_style">{{ item.name }}</div>
              <div class="condition_style">{{ item.condition ? item.condition_tips : item.condition }}
              </div>
            </div>
          </div>
          <div class="effective_time">{{ item.effective_time ? item.use_time_tips : item.effective_time }}
          </div>
          <div class="d-flex align-items-center justify-content-between use_">
            <div v-if="item.use_scene || item.goods_tips" class="use_scene">
              {{ item.use_scene ? item.goods_tips : item.use_scene }}
            </div>
            <div @click="Get_Voucher(item)" class="is_available">
              {{ item.is_available == 1 ? '已领取' : item.is_empty == 1 ? '已抢光' : '领取' }}
            </div>
          </div>
          <div v-if="item.is_receive == 1" class="d-flex absolute">
            <img style="width: 53px;height: 53px;" src="./images/Received.png" alt="图片已丢失" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Getcouponreceive } from "@/api"
import addCartCheck from '@/mixins/addCartCheck'
import { GetGoodsdetail, GetcollecthandleCollectgoods, Getcartlist, apiGoodsDetailVisit } from "@/api"
export default {
  name: 'ProductDetails',
  mixins: [addCartCheck],
  data() {
    return {
      datalist: [],
      imglist: [],
      currentIndex: 0,
      isLoading: false,
      couponPopup: false,
      couponList: [],
      // 是否显示优惠券复选框
      input_state: false,
      // 是否禁用优惠券复选框
      state_disabled: false,
      // 购物车数量
      cartNumber: 0,
      // 商品组合包含商品列表 
      goodsGroupList: [],
      goodsId: ''
    }
  },
  created() {
    this.GetGoodsdetail()
    this.isGoodsVisit()
  },
  computed: {
    currentImage() {
      if (this.imglist) {
        return this.imglist[this.currentIndex];
      } else {
        return "";
      }
    },
    route_params() {
      try {
        let routerParams = JSON.parse(this.$params.data)
        this.goodsId = routerParams && routerParams.id ? routerParams.id : routerParams.goods_id ? routerParams.goods_id : routerParams.code
        return routerParams
      } catch (e) {
        console.log(e, '------商品详情获取路由参数失败')
        this.$message({
          type: 'error',
          offset: this.$store.state.config.outer_height,
          message: '抱歉当前无法查看当前商品详情, 请稍候重试'
        })
        return this.$router.go(-1)
      }
    }
  },
  methods: {
    addCart() {
      this.$refs.in.setCurrentValue(this.cartNumber + this.datalist.step_number);
    },
    isGoodsVisit() {
      apiGoodsDetailVisit({
        goods_id: this.goodsId
      }).then((res) => {
        console.log(res, '-----商品详情商品浏览量api---success')
      }).catch((err) => {
        console.log(err, '-----商品详情商品浏览量api---error')
      })
    },
    // 领取优惠券
    async Get_Voucher(data) {
      let params = { id: data.id };
      let dataobjet = await Getcouponreceive(params);
      console.log('领取状态', dataobjet)
      if (dataobjet.code == 1) {
        this.$message({
          message: '领取成功',
          type: 'success'
        })
      } else {
        this.$message.error(dataobjet.msg);
      }
      this.$emit('ToRefresh')
    },
    getbackground(type, state_type) {
      if (state_type == 1) {
        return 'background: linear-gradient(90deg, #999999 0%, #C7C7C7 100%)';
      } else {
        if (type) {
          switch (type) {
            case 1:
              return 'background: linear-gradient(135deg, #ffb983 0%, #ff334c 100%)';
            case 2:
              return 'background: linear-gradient(135deg, #91D2FF 0%, #0082DC 100%)'
            case 3:
              return 'background: linear-gradient(135deg, #FF9D72 0%, #FE611C 100%)'
            case 4:
              return 'background: linear-gradient(135deg, #9feab6 0%, #13ab43 100%)'
            default:
              break;
          }
        }
      }
    },
    // 收藏商品
    async collect(is_collect) {
      let params = {
        is_collect: !is_collect,
        code: this.datalist.code
      };
      console.log('params', params)
      let data = await GetcollecthandleCollectgoods(params);
      console.log('收藏商品', data)
      this.GetGoodsdetail();
    },
    // 商品详情列表
    async GetGoodsdetail() {
      console.log(this.route_params, '------商品详情跳转参数')
      let params = {
        code: this.route_params.code,  // 商品编码
        activity_sn: !this.route_params.activity_sn ? -1 : this.route_params.activity_sn,
        goods_id: this.goodsId
      };

      GetGoodsdetail(params).then((res) => {
        console.log(res, '------商品详情api---success')
        if (res.code != 1) return this.backPage(res.msg)

        this.datalist = res.data;
        this.imglist = this.datalist.goods_image;
        this.couponList = this.datalist.coupon
        if (this.datalist.goods_group && this.datalist.goods_group.length > 0) this.goodsGroupList = this.datalist.goods_group

        Getcartlist().then((val) => {
          let zat = this.$findGoodname(res.data, val);
          zat.then(result => {
            this.cartNumber = result;
          })
        })
      }).catch((err) => {
        console.log(err, '------商品详情api---error')
        this.backPage(err)
      })

      this.isLoading = true;
    },
    backPage(errMessage) {
      this.$message({
        type: 'error',
        offset: this.$store.state.config.outer_height,
        message: `${errMessage}`
      })
      this.$router.go(-1)
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    nextImage() {
      if (this.currentIndex < this.imglist.length - 1) {
        this.currentIndex++;
      }
    },
    selectImage(index) {
      this.currentIndex = index;
    }
  }
}
</script>

<style>
.rich-text-container {
  font-size: 16px;
  line-height: 30px;
}

.rich-text-container img {
  width: 1200px;
  height: auto;
}
</style>

<style lang="less" scoped>
.big_box {
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
}

.selected {
  border: 2px solid red;
}

.mgt10 {
  margin-top: 10px;
}

.mgr30 {
  margin-right: 30px;
}

.text_theme_color {
  color: #ff334c !important;
}

.detail_top_info {
  padding: 10px 20px 10px 0;
}

.img_currentImage {
  margin: 0 10px;
  font-size: 30px;
}

.box_prevImage {
  width: 400px;
  padding: 30px 20px 0;
  cursor: pointer;
}

.prevImage {
  font-size: 20px;
}

.selectImage {
  margin-left: 10px;
}

.nextImage {
  font-size: 20px;
}

.Advent_box {
  width: 100%;
}

.Advent_content_box {
  color: #ffffff;
  background-color: #ff334c;
  padding: 10px 20px;
}

.Advent {
  font-size: 18px;

  span {
    margin: 0 10px;
  }
}

.Advent_num {
  font-size: 14px;
}

.Undershirt,
.specifications {
  margin-left: 20px;
}

.Undershirt {
  font-size: 24px;
  font-weight: 550;

  .class-A,
  .class-B {
    font-size: 18px;
    border-radius: 5px;
    padding: 0 5px;
    margin-right: 5px;
  }

  .class-A {
    border: 1px solid #2d44dc;
    color: #2d44dc;
  }

  .class-B {
    border: 1px solid #f19429;
    color: #f19429;
  }
}

.specifications {
  font-size: 16px;
  color: #596372;

  span {
    &:nth-child(1) {
      margin-right: 30px;
    }
  }
}

.price_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 15px 10px;

  .top_price {
    .activity_price_style {
      color: #ff334c;
      font-size: 24px !important;
    }
  }

  .monthly_sales {
    text-align: center;

    p {
      &:nth-child(1) {
        font-size: 16px;
        color: #ff334c;
      }

      &:nth-child(2) {
        color: #87919d;
        margin-top: 5px;
      }
    }
  }

  .price_info_top,
  .price_info_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    cursor: pointer;

    span {
      color: #87919d;
      font-size: 18px;
    }
  }

  .price_info_bottom {
    margin-top: 18px;
  }
}

.price {
  padding: 20px 10px;
  width: 465px;
  background-color: #ff334c;
  color: #ffffff;
  font-size: 18px;
  margin: 10px 0 0 20px;
}

.certificate {
  margin: 10px 0px 0px 20px;
  cursor: pointer;
}

.preferential {
  font-size: 16px;
  color: #4e5969;
}

.voucher {
  border: 1px solid #ff334c;
  font-size: 14px;
  color: #ff334c;
  border-radius: 6px;
  padding: 2px 6px;
}

.cost {
  margin: 20px 0 10px 20px;
  font-size: 14px;
}

.freight {
  color: #fff;
  background-color: #ff334c;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}

.FreeShipping {
  margin-left: 5px;
  color: #4e5969;
}

.Summary {
  padding: 10px 20px 10px;
  margin: 10px 0 20px 0;
  width: 465px;
  border-top: 1px solid #e5e6eb;
  border-bottom: 1px solid #e5e6eb;
  font-size: 14px;
}

.retail,
.profit,
.interest {
  font-weight: 600;
}

.retail {
  font-size: 16;
  color: #1d2129;
}

.retail_price {
  font-size: 16;
  color: #ff334c;
}

.profit {
  font-size: 16;
  color: #1d2129;
}

.profit_price {
  font-size: 16;
  color: #ff334c;
}

.interest {
  font-size: 16;
  color: #1d2129;
}

.interest_rate {
  font-size: 16;
  color: #ff334c;
}

.unknown_box {
  height: 30px;
  padding: 0px 0px 0px 20px;
}

.unknown_box_one {
  color: #ff334c;
  font-size: 24px;
  font-weight: 600;
}

.unknown_box_two {
  font-size: 12px;
  color: #86909c;
  margin-left: 5px;
  text-decoration: line-through;
}

.filter_box {
  width: 90%;
  padding: 20px 20px;
}

.filter_box_width {
  width: 600px;
  // width: 465px;
}

.filter_box_margin {
  margin-left: 10px;
}

.filter_color {
  color: #86909c;
}

.collect_goods {
  display: flex;
  align-items: center;
  font-size: 20px;
  border: 1px solid #86909c;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}

.collect_goods_selected {
  color: #ff334c;
  border: 1px solid #ff334c;
}

.special_box {
  padding: 20px;
  background-color: #ffffff;
  margin-top: 20px;
}

.hydrochloric {
  color: #1d2129;
  font-size: 18px;
}

.special_small {
  padding: 5px 5px;
  font-size: 18px;
  margin-left: 10px;

  .specialoffer {
    border: 1px solid #ff334c;
    border-radius: 10px;
    color: #ff334c;
    padding: 5px 10px;
  }
}

.limit {
  color: #1d2129;
  font-size: 14px;
}

.tabel_box {
  background-color: #ffffff;
  margin-top: 20px;
}

.commodity {
  height: 50px;
  color: #1d2129;
  font-size: 20px;
  font-weight: 600;
}

.details_box {
  padding-bottom: 10px;
  background-color: #ffffff;
  margin: 20px 0 0;
}

.details {
  height: 50px;
  color: #1d2129;
  font-size: 20px;
  font-weight: 600;
}

.table {
  border-collapse: collapse;
}

th,
td {
  color: #1d2129;
  font-size: 16px;
  text-align: center;
  width: 100px;
  height: 40px;
  border: 1px solid #ccc;
  padding: 8px;
}

.smallbox {
  position: relative;
  width: 275px;
  height: 120px;
  color: #ffffff;
  margin: 0 20px 20px 0;
  overflow: hidden;

  &:last-child {
    margin-right: auto;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  .absolute {
    position: absolute;
    top: -5px;
    right: 0px;
  }
}

.money {
  font-size: 18px;
  margin-bottom: 5px;
}

.money_num {
  font-size: 40px;
  padding: 0px 0px 0px 5px;
}

.name_style {
  font-size: 16px;
}

.condition_style {
  font-size: 10px;
}

.effective_time {
  font-size: 12px;
  color: #ffffff;
  padding: 0px 0px 0px 10px;
}

.use_ {
  padding: 10px 5px;
  background: linear-gradient(90deg, #f2f2f2 0%, #f2f2f2 100%);
}

.use_scene {
  color: #4e5969;
  font-size: 14px;
}

.is_available {
  cursor: pointer;
  color: #ff334c;
  font-size: 14px;
}
</style>