//当前这个模块：API进行统一管理
import requests from '@/api/request'

// 应用配置
export function getConfig() {
  return requests({
    url: '/config/getConfig',
    method: 'get'
  })
}

//登录的接口:请求体携带参数 phone&&password
export function reqUserLogin(data) {
  return requests({
    url: '/login/account',
    method: 'POST',
    data
  })
}

// 店铺列表
export function GetusershopList(data) {
  return requests({
    url: '/user/shopList',
    method: 'post',
    data
  })
}

// 商品推荐列表
export function Getgoodsrecommand(data) {
  return requests({
    url: '/goods/recommand',
    method: 'get',
    params: data
  })
}

// 商品列表
export function GetGoodslists(data) {
  return requests({
    url: '/Goods/lists',
    method: 'get',
    params: data
  })
}

// 商品详情
export function GetGoodsdetail(data) {
  return requests({
    url: '/goods/detail',
    method: 'get',
    params: data
  })
}

// 分类列表
export function GetClassificationList(data) {
  return requests({
    url: '/goods_category_use/lists',
    method: 'get',
    params: data
  })
}

// 品牌列表
export function GetbrandList(data) {
  return requests({
    url: '/goods_brand/brandLists',
    method: 'get',
    params: data
  })
}

// 超值换购列表
export function GetExchangeactivityLists(data) {
  return requests({
    url: '/exchange/activityLists',
    method: 'get',
    params: data
  })
}

// 限时秒杀
export function GetseckillactivityLists(data) {
  return requests({
    url: '/seckill/activityLists',
    method: 'get',
    params: data
  })
}

// 领劵中心
export function Getcouponlists(data) {
  return requests({
    url: '/coupon/lists',
    method: 'get',
    params: data
  })
}

// 商城公告
export function Getshop_noticelists(data) {
  return requests({
    url: '/shop_notice/lists',
    method: 'get',
    params: data
  })
}

// 获取购物车数量列表
export function Getcartlist(data) {
  return requests({
    url: '/cart/lists',
    method: 'get',
    params: data
  })
}

// 消息中心
export function Getnoticelists(data) {
  return requests({
    url: '/notice/lists',
    method: 'get',
    params: data
  })
}

// 消息通知未读消息
export function getNotReadNotice(data) {
  return requests({
    url: '/notice/index',
    method: 'get',
    params: data
  })
}

// 我的优惠券列表
export function Getmycoupon(data) {
  return requests({
    url: '/coupon/my',
    method: 'get',
    params: data
  })
}

// 添加购物车
export function Editcartchange(data) {
  return requests({
    url: '/cart/change',
    method: 'post',
    data
  })
}

// 购物车页面信息
export function GetcartdoCart(data) {
  return requests({
    url: '/cart/doCart',
    method: 'get',
    params: data
  })
}

// 购物车页面选中或取消接口
export function Editcartselected(data) {
  return requests({
    url: '/cart/selected',
    method: 'post',
    data
  })
}

// 购物车页面批量或单个删除
export function Editcartbatch_del(data) {
  return requests({
    url: '/cart/batch_del',
    method: 'post',
    data
  })
}

// 结算页信息
export function GetorderplaceOrder(data) {
  return requests({
    url: '/order/placeOrder',
    method: 'post',
    data
  })
}

// 店铺列表
export function GetuseraddressList(data) {
  return requests({
    url: '/user/addressList',
    method: 'get',
    params: data
  })
}

// 修改收货地址
export function EditusersetDefault(data) {
  return requests({
    url: '/user/setDefault',
    method: 'post',
    data
  })
}

// 获取订单可选快递信息
export function GetorderExpressIdList(data) {
  return requests({
    url: '/order/orderExpressIdList',
    method: 'get',
    params: data
  })
}

// 获取订单可选快递信息
export function GetorderOtherInfoList(data) {
  return requests({
    url: '/order/orderOtherInfoList',
    method: 'get',
    params: data
  })
}

// 提交订单
export function submitOrder(data) {
  return requests({
    url: '/order/submitOrder',
    method: 'post',
    data
  })
}

// 提交订单
export function GetcheckOrderSubmit(data) {
  return requests({
    url: '/order/checkOrderSubmit',
    method: 'get',
    params: data
  })
}

// 结算页获取可用代金券
export function getCouponList(data) {
  return requests({
    url: '/order/getCouponList',
    method: 'post',
    data
  })
}

// 结算页获取可用代金券
export function getcartaddCartOther(data) {
  return requests({
    url: '/cart/addCartOther',
    method: 'get',
    params: data
  })
}

// 获取可用支付列表
export function Getpayway(data) {
  return requests({
    url: '/pay/payway',
    method: 'post',
    data
  })
}

// 发起支付
export function InitiatePay(data) {
  return requests({
    url: '/pay/prepay',
    method: 'post',
    data
  })
}

// 首页接口
export function GetindexappIndex(data) {
  return requests({
    url: '/index/appIndex',
    method: 'get',
    params: data
  })
}

// 订单列表
export function Getorderlists(data) {
  return requests({
    url: '/order/lists',
    method: 'get',
    params: data
  })
}

// 确认收货
export function Getorderconfirm(data) {
  return requests({
    url: '/order/confirm',
    method: 'post',
    data
  })
}

// 获取订单物流信息
export function GetorderorderTraces(data) {
  return requests({
    url: '/order/orderTraces',
    method: 'post',
    data
  })
}

// 取消订单
export function Getordercancel(data) {
  return requests({
    url: '/order/cancel',
    method: 'post',
    data
  })
}

// 再次购买
export function GetorderbuyAgain(data) {
  return requests({
    url: '/order/buyAgain',
    method: 'post',
    data
  })
}

// 获取订单详情
export function Getorderdetail(data) {
  return requests({
    url: '/order/detail',
    method: 'get',
    params: data
  })
}

// 我的钱包
export function Getuserwallet(data) {
  return requests({
    url: '/user/wallet',
    method: 'get',
    params: data
  })
}

// 我的收藏列表
export function Getcollectlists(data) {
  return requests({
    url: '/collect/lists',
    method: 'get',
    params: data
  })
}

// 操作收藏商品
export function GetcollecthandleCollectgoods(data) {
  return requests({
    url: '/collect/handleCollectgoods',
    method: 'post',
    data
  })
}

// 我的历史足迹
export function Getfootprintlists(data) {
  return requests({
    url: '/footprint/lists',
    method: 'get',
    params: data
  })
}

// 获取会员中心个人资料
export function Getusercentre(data) {
  return requests({
    url: '/user/centre',
    method: 'get',
    params: data
  })
}

// 获取所在地区
export function GetRegisterApicompany(data) {
  return requests({
    url: '/RegisterApi/company',
    method: 'get',
    params: data
  })
}

// 获取采购人员列表
export function GetuserbuyerList(data) {
  return requests({
    url: '/user/buyerList',
    method: 'get',
    params: data
  })
}

// 获取手机验证码
export function reqGetCode(data) {
  return requests({
    url: '/login/captcha',
    method: 'post',
    data
  })
}

// 获取隐私协议和用户协议
export function getPolicyAgreement(data) {
  return requests({
    url: '/config/getPolicyAgreement',
    method: 'get',
    params: data
  })
}

// 用户退出登录
export function reqLogout(data) {
  return requests({
    url: '/login/logout',
    method: 'post',
    data
  })
}

// 我的积分列表
export function GetmySignLog(data) {
  return requests({
    url: '/sign/mySignLog',
    method: 'get',
    params: data
  })
}

// 领取优惠券
export function Getcouponreceive(data) {
  return requests({
    url: '/coupon/receive',
    method: 'post',
    data
  })
}

// 积分列表
export function Getsignlists(data) {
  return requests({
    url: '/sign/lists',
    method: 'GET',
    params: data
  })
}

// 签到
export function Editsign(data) {
  return requests({
    url: '/sign/sign',
    method: 'POST',
    data
  })
}

// 客服（业务员）列表
export function GetsellerList(data) {
  return requests({
    url: '/user/sellerList',
    method: 'GET',
    params: data
  })
}

// 获取支付状态
export function GetpayStatus(data) {
  return requests({
    url: '/pay/payStatus',
    method: 'post',
    data
  })
}

// 店铺切换
export function GetusershopSwitch(data) {
  return requests({
    url: '/user/shopSwitch',
    method: 'post',
    data
  })
}

// 店铺切换
export function apiJumpLink(params) {
  return requests({
    url: '/index/jumpLink',
    method: 'get',
    params
  })
}

// 清空浏览历史
export function apiFootprintDel() {
  return requests({
    url: '/footprint/del',
    method: 'get',
  })
}

// 诊所联盟详情
export function apiGetClinicAlliance() {
  return requests({
    url: '/user/getClinicAlliance',
    method: 'get',
  })
}

// 诊所联盟协议
export function apiGetShopAgreement(data) {
  return requests({
    url: '/register_info/getShopAgreement',
    method: 'post',
    data
  })
}

// 加入诊所联盟
export function apiSetClinicAlliance() {
  return requests({
    url: '/user/setClinicAlliance',
    method: 'get'
  })
}

// 经营许可证号
export function apiCopyright() {
  return requests({
    url: '/config/copyright',
    method: 'get'
  })
}

// 应用更新检测
export function apiUpdateApp(data) {
  return requests({
    url: '/config/getVersion',
    method: 'post',
    data
  })
}

// 获取签到说明
export function apiSignGetRemark() {
  return requests({
    url: '/sign/getRemark',
    method: 'get'
  })
}

// 商品详情商品浏览记录
export function apiGoodsDetailVisit(params) {
  return requests({
    url: '/goods/visit',
    method: 'get',
    params
  })
}

// 首页访客记录
export function apiVisit(params) {
  return requests({
    url: '/index/visit',
    method: 'post',
    params
  })
}