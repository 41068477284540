// 品牌甄选
<template>
  <div v-if="brandSelectListObj.pc_status == 1 && brandSelectListObj.list.length > 0" class="selection"
    @click="toCategroy('')">
    <div class="select_top">
      <div class="select_left">
        <div class="brand">药企直供</div>
        <div class="supply">860家药企直供</div>
      </div>
      <!-- <div class="select_right" style="color: #86909c;"> -->
      <div class="select_right" style="color: #4E5969;">
        <div>查看更多</div>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="select_bottom">
      <div v-if="brandSelectListObj.list.length >= 1" class="bottom_left"
        @click.stop="toCategroy(brandSelectListObj.list[0].id)"
        :style="{ backgroundImage: `url(${$getImageUri(brandSelectListObj.list.length == 1 ? brandSelectListObj.list[0].pc_image_super : brandSelectListObj.list[0].pc_image_big)})`, marginRight: brandSelectListObj.list.length > 1 ? '15px' : '' }">
      </div>
      <div v-if="brandSelectListObj.list.length == 2 && brandSelectListObj.list[1]" class="bottom_left"
        @click.stop="toCategroy(brandSelectListObj.list[1].id)"
        :style="{ backgroundImage: `url(${$getImageUri(brandSelectListObj.list[1].pc_image_big)})` }">
      </div>
      <div v-if="brandSelectListObj.list.length == 3" class="bottom_right">
        <div v-if="brandSelectListObj.list[1]" class="box_one" @click.stop="toCategroy(brandSelectListObj.list[1].id)"
          :style="{ backgroundImage: `url(${$getImageUri(brandSelectListObj.list[1].pc_image)})` }">
        </div>
        <div v-if="brandSelectListObj.list[2]" class="box_two" @click.stop="toCategroy(brandSelectListObj.list[2].id)"
          :style="{ backgroundImage: `url(${$getImageUri(brandSelectListObj.list[2].pc_image)})` }">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "brand-index",
  props: {
    brandSelectListObj: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    toCategroy(id) {
      this.$isRouterParams('gategory', { type: 'brand', id: id })
    }
  }
}
</script>

<style lang="less" scoped>
.selection {
  width: 1200px;
  height: 426.923px;
  background: linear-gradient(to bottom, #fff4a5, #ffffff);
  border-radius: 15.385px;
  box-sizing: border-box;
  padding: 20px 15.385px;
  margin: 20px auto 0;
  cursor: pointer;
  font-family: PingFang SC;

  .select_top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .brand {
        font-size: 24px;
        color: #1D2129;
      }

      .supply {
        color: #4E5969;
        font-size: 14px;
        margin-left: 26.923px;
      }
    }

    .select_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #fff;
    }
  }

  .select_bottom {
    height: 321.154px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: no-repeat;

    .bottom_left {
      width: 100%;
      height: 100%;
      border-radius: 15.385px;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .words {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 15.385px 20px;

        .adver_name {
          color: #3f73b6;
          font-size: 30.769px;
        }

        .adver_btn {
          width: 230.769px;
          line-height: 38.462px;
          border-radius: 38.462px;
          background: #3f73b6;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          margin-top: 11.538px;
        }
      }

      .adver {
        width: 273.077px;
        height: 163.462px;
        margin: 20px 26.923px 15.385px 26.923px;
      }
    }

    .bottom_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .box_one {
        width: 326.923px;
        height: 153.846px;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 15.385px;
        box-sizing: border-box;
        padding: 15.385px 0;
        position: relative;
        background-size: 326.923px 153.846px;

        .text_one {
          color: #ca9131;
          font-size: 20px;
        }

        .btn_one {
          width: 107.692px;
          border-radius: 38.462px;
          line-height: 30.769px;
          background: #ca9131;
          font-size: 19.231px;
          color: #ffffff;
          text-align: center;
        }

        .img {
          width: 148.077px;
          height: 90.385px;
          position: absolute;
          bottom: 11.538px;
        }
      }

      .box_two {
        width: 326.923px;
        height: 153.846px;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 15.385px;
        margin-top: 15.385px;
        box-sizing: border-box;
        padding: 15.385px 0;
        position: relative;
        background-size: 326.923px 153.846px;

        .text_two {
          color: #d3478f;
          font-size: 20px;
        }

        .btn_two {
          background: #d3478f;
          color: #ffffff;
          width: 107.692px;
          border-radius: 38.462px;
          line-height: 30.769px;
          font-size: 19.231px;
          text-align: center;
        }

        .img {
          width: 148.077px;
          height: 90.385px;
          position: absolute;
          bottom: 11.538px;
        }
      }
    }
  }
}
</style>