// 专区广场
<template>
  <div v-if="prefectureListObj.pc_status && prefectureListObj.list.length > 0" class="rank">
    <!-- <div class="Special_area_square d-flex flex-column align-items-center justify-content-center">
      <div>
        专区广场
      </div>
      <div style="margin-bottom: 30px;">
        <img class="Special_area_square_img" src="./images/rectangle.png" />
      </div>
    </div> -->
    <!-- 四张小图布局 -->
    <div v-if="isIncludesBigImg == false && prefectureListObj.list.length == 4" class="not_show_big" v-masonry>
      <div v-for="(item, index) in prefectureListObj.list" :key="index + 'k2'" class="not_show_bigImg" v-masonry-tile
        @click="$indexJumpClick(item)">
        <img :src="$getImageUri(item.pc_img)" alt="抱歉图片加载失败" />
      </div>
    </div>
    <!-- 一张大图四张小图布局 -->
    <div v-else-if="oneBigFourSmall" class="five_layout">
      <div class="five_layout_big" @click="$indexJumpClick(prefectureListObj.list[0])">
        <img :src="$getImageUri(prefectureListObj.list[0].pc_img_big)" alt="抱歉图片加载失败" />
      </div>
      <div class="five_layout_small">
        <div v-for="(f, i) in prefectureListObj.list" :key="i" @click="$indexJumpClick(f)">
          <img v-if="i != 0" :src="$getImageUri(f.pc_img_middle)" alt="抱歉图片加载失败" />
        </div>
      </div>
    </div>
    <!-- 其它情况布局 -->
    <div v-else class="zone" v-masonry>
      <div v-for="(v, ix) in prefectureListObj.list" :key="ix" :class="v.pc_is_show == 1 ? 'zone-item_odd' : 'zone-item'"
        v-masonry-tile @click="$indexJumpClick(v)">
        <img :src="v.pc_is_show == 1 ? $getImageUri(v.pc_img_big) : $getImageUri(v.pc_img)" alt="抱歉图片加载失败" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rank-index",
  props: {
    isIncludesBigImg: {
      type: Boolean,
      default: false
    },
    prefectureListObj: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    oneBigFourSmall() {
      let obj = this.prefectureListObj
      if (obj && obj.list && obj.list.length == 5) {
        if (obj.list[0].pc_is_show == 1) {
          return true
        } else {
          return false
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.rank {
  background-color: #ffffff;
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0 0 20px;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  font-family: PingFang SC;

  .Special_area_square {
    position: relative;
    height: 45px;
    width: 100%;
    font-size: 24px;


    .Special_area_square_img {
      position: absolute;
      margin-top: 5px;
      margin-left: -40px;
    }
  }
}

.zone,
.five_layout,
.not_show_big {
  margin-bottom: 5px;

  img {
    border-radius: 15px;
    width: 100%;
    height: 100%;
  }
}

.five_layout {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .five_layout_big,
  .five_layout_small {
    img {
      margin: 0 20px 15px 0;
    }
  }

  .five_layout_big {
    img {
      width: 275px;
      height: 340px;
    }
  }

  .five_layout_small {
    display: flex;
    flex-wrap: wrap;

    img {
      width: 422px;
      height: 160px;
    }
  }
}

.zone {
  display: flex;
  flex-wrap: wrap;

  .zone-item,
  .zone-item_odd {
    width: 275px;
    margin: 0 20px 20px 0;

    &:nth-last-child(1) {
      margin-bottom: 16px;
    }
  }

  .zone-item {
    height: 160px;
  }

  .zone-item_odd {
    height: 340px;
  }
}

.not_show_big {
  display: flex;
  flex-wrap: wrap;

  .not_show_bigImg {
    margin: 0 20px 20px 0;
    width: 568px;
    height: 280px;

    &:nth-last-child(1) {
      margin-bottom: 16px;
    }
  }
}
</style>