<template>
  <div class="big_box d-flex flex-column">
    <div class="d-flex Notifications">通知中心</div>
    <div class="d-flex flex-column justify-content-center for_popup" v-for="item in data_list.data.lists" :key="item.id">
      <div class="d-flex align-items-center Internal">
        <div class="d-flex align-items-center">
          <div class="circular">
          </div>
          <div class="item_title">
            {{item.title}}
          </div>
        </div>
        <div class="create_time">
          {{item.create_time}}
        </div>
      </div>
      <div class="d-flex align-items-center item_content">
        {{item.content}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'messagecenter',
  data() {
    return {
    }
  },
  mounted() {
    this.Getdatalist();
  },
  computed: {
    data_list() {
      return this.$params.datalist
    }
  },
  methods: {
    handleClick(tab, event) {
    },
    // 消息中心列表
    Getdatalist() {
      console.log('data', this.data_list)
    },
  }
}
</script>

<style lang="less" scoped>
.big_box {
  height: 700px;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
}

.for_popup {
  margin: 10px 10px 0px 20px;
}

.Internal {
  height: 30px;
}

.circular {
  height: 10px;
  width: 10px;
  background-color: #00b578;
  border-radius: 50%;
}

.item_title {
  color: #1d2129;
  font-size: 12px;
  margin-left: 5px;
}

.create_time {
  margin-left: 50px;
  color: #86909c;
  font-size: 2px !important;
}

.item_content {
  padding: 5px 0px 10px 0px;
  border-bottom: 1px solid #e5e6eb;
  font-size: 10px;
  color: #4e5969;
}

.Notifications {
  font-weight: bold;
  margin: 10px 0px 0px 20px;
  color: #1d2129;
  font-size: 16px;
}
</style>