<template>
  <div class="swiper-container" ref="cur">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="carousel in list" :key="carousel.id">
        <img width="740px" :src="$getImageUri(carousel.imgUrl)" alt="抱歉图片加载失败" />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: "Carsousel",
  props: ["list"],
  watch: {
    list: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          var mySwiper = new Swiper(
            this.$refs.cur,
            {
              loop: true,
              pagination: {
                el: ".swiper-pagination",
                //点击小球的时候也切换图片
                clickable: true,
              },
              navigation: {
                // 如果需要前进后退按钮
                nextEl: "swiper-button-next",
                prevEl: "swiper-button-prev"
              },
            });
        });
      }
    }
  },
}
</script>

<style scoped></style>