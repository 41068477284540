<!-- 新建页面模版 -->
<template>
  <div class="big_box d-flex flex-column">
    <div class="d-flex align-items-center bg-white Conditionalzone">
      <div class="d-flex vforbox">
        <div>排序方式：</div>
        <div class="cursor" :class="{ 'selected': Now_id == item.id}" @click="Conditionalzone(item.id)" v-for="(item, index) in datalist" :key="index">{{item.name}}</div>
      </div>
    </div>
    <commodity @pageindex="getpageindex" :tyoe_list="tyoe_list" :pageInfo="pageInfo"></commodity>
    <NoData v-if="!tyoe_list.length"></NoData>
    <recommand></recommand>
  </div>
</template>

<script>
import NoData from '@/components/NoData/index';
import eventBus from './js/eventBus.js';
import { GetGoodslists } from "@/api";
export default {
  name: 'Search',
  components: {
    NoData
  },
  data() {
    return {
      tyoe_list: [],
      pageInfo: {},
      Now_id: '',
      params: {
        keywords: '',
        page_size: 10,
        page_no: 1
      },
      datalist: [{
        name: '智能排序',
        id: 0
      }, {
        name: '价格从低到高',
        id: 1
      }, {
        name: '销量从高到低',
        id: 2
      }, {
        name: '人气从高到低',
        id: 3
      }]
    }
  },
  mounted() {
    eventBus.$on('Search_input', (data) => {
      this.params.keywords = data;
    });
    this.GetGoodslists();
  },
  computed: {
  },
  methods: {
    getpageindex(data) {
      this.params.page_no = data;
      this.GetGoodslists();
    },
    Conditionalzone(id) {
      this.Now_id = id

      switch (id) {
        case 0:
          this.params.sort = 0
          break
        case 1:
          this.params.sort = 1
          break
        case 2:
          this.params.sort = 2
          break
        case 3:
          this.params.sort = 3
          break
      }
      this.GetGoodslists()
    },
    // 搜索列表
    async GetGoodslists() {
      if (!this.params.keywords) {
        this.params.keywords = this.$params.input;
      }
      let datalist = await GetGoodslists(this.params);
      this.tyoe_list = datalist.data.data;
      this.pageInfo.index = datalist.data.page_no;
      this.pageInfo.size = datalist.data.page_size;
      this.pageInfo.total = datalist.data.count;
    },
  },
  watch: {
    'params.keywords': {
      deep: true,
      handler: function () {
        this.GetGoodslists();
      }
    },
    'params.Now_id': {
      deep: true,
      handler: function () {
        this.GetGoodslists();
      }
    }
  },
  beforeDestroy() {
    eventBus.$off('Search_input');
  }
}
</script>

<style lang="less" scoped>
.big_box {
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.Conditionalzone {
  font-size: 16px;
  color: #1d2129;
  width: 1200px;
  height: 56px;
}

.vforbox {
  width: 100%;
  gap: 30px;
  padding-left: 10px;
}

.cursor {
  cursor: pointer;
}

/* 选中样式 */
.selected {
  color: #ff334c;
}
</style>