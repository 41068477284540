<!-- 个人资料 -->
<template>
  <div class="big_box bg-white d-flex flex-column">
    <div class="d-flex flex-column" style="overflow-x: auto;">
      <div class="d-flex personaldata">
        个人资料
      </div>
      <div class="d-flex flex-column buttom_box">
        <div class="d-flex align-items-center">
          <span>头像</span>
          <div class="head">
            <img style="width: 50px; height: 50px;border-radius: 50%;" :src="$getImageUri(all_data.avatar)" alt="抱歉图片加载失败" />
          </div>
        </div>
        <div class="d-flex">
          <span>昵称</span>
          <label>{{ all_data.nickname }}</label>
        </div>
        <div class="d-flex">
          <span>会员手机号码</span>
          <label>{{ all_data.mobile }}</label>
        </div>
        <div class="d-flex">
          <span>企业名称</span>
          <label v-if="company.company">{{ company.company }}</label>
        </div>
        <div class="d-flex">
          <span>企业法人</span>
          <label v-if="company.company_master">{{ company.company_master }}</label>
        </div>
        <div class="d-flex">
          <span>企业类型</span>
          <label v-if="company.user_type_name">{{ company.user_type_name }}</label>
          <div v-if="company.user_type_id == 1 || company.user_type_id == 3" class="d-flex align-items-center">
            <el-radio v-model="isJoinAlliance" :disabled="isDisabledJoin"></el-radio>
            <el-tooltip :value="true" effect="dark" placement="top-start">
              <span class="join_alliance_mes" @click="isJoinChecked">{{ joinAllianceMessage }}</span>
              <div slot="content">
                点击文字查看协议详情
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="d-flex">
          <span>所在地区</span>
          <label>{{ RegisterApicompany.address }}</label>
        </div>
        <div class="d-flex">
          <span>注册地址</span>
          <label>{{ shoplist.register_address }}</label>
        </div>
        <div class="d-flex">
          <span>收货人</span>
          <label v-for="(item, index) in take_address" :key="index">有效期：{{ item.expired_date }} 姓名：{{ item.realname }}
            手机号：{{ item.mobile }} 地址：{{ item.address }}</label>
        </div>
        <div class="d-flex">
          <span>采购人</span>
          <label v-for="(item, index) in buyerList" :key="index">有效期：{{ item.expired_date }} 姓名：{{ item.realname }}
            手机号：{{ item.mobile }}</label>
        </div>
        <div class="d-flex">
          <span>经营范围</span>
          <label>{{ RegisterApicompany.businessScope }}</label>
        </div>
        <div class="d-flex">
          <span>发证机关</span>
          <label>{{ RegisterApicompany.belongOrg }}</label>
        </div>
        <div class="d-flex">
          <span>证书编号</span>
          <label>{{ RegisterApicompany.creditNo }}</label>
        </div>
        <div class="d-flex">
          <span>发证时间</span>
          <label>{{ shoplist.license_date }}</label>
        </div>
        <div class="d-flex">
          <span>证书有效期</span>
          <label>{{ shoplist.license_expired }}</label>
        </div>
        <div class="d-flex">
          <span>发票类型</span>
          <label>{{ shoplist.invoice_type == 0 ? '电子' : '纸质' }}</label>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div @click="Exit_login" class="exit_login d-flex align-items-center justify-content-center">退出登录</div>
    </div>
  </div>
</template>

<script>
import { Getusercentre, GetRegisterApicompany, GetusershopList, GetuseraddressList, GetuserbuyerList, apiGetClinicAlliance, apiSetClinicAlliance } from "@/api";
export default {
  name: 'personaldata',
  data() {
    return {
      shoplist: {},
      all_data: {},
      RegisterApicompany: {},
      take_address: [],
      buyerList: [],
      isJoinAlliance: false,
      isDisabledJoin: false,
      joinAllianceMessage: '加入诊所联盟'
    }
  },
  mounted() {
    this.Getusercentre();
    this.GetRegisterApicompany();
    this.GetusershopList();
    this.GetuseraddressList();
    this.GetuserbuyerList()
    this.isGetClinicAllianceDetail()
  },
  computed: {
    company() {
      return this.$store.state.user.company_data
    }
  },
  methods: {
    // 验证是否已加入诊所联盟
    isGetClinicAllianceDetail() {
      apiGetClinicAlliance().then(res => {
        console.log(res, '------诊所联盟详情api---success')

        this.content = res.data.content
        if (res.data.user_clinic_alliance == 1) {
          this.isJoinAlliance = true
          this.isDisabledJoin = true
          this.joinAllianceMessage = '您已加入诊所联盟'
        }
      }).catch(err => {
        console.log(err, '------诊所联盟详情api---error')
        return this.$message({
          type: 'error',
          offset: this.$store.state.config.outer_height,
          message: '抱歉, 获取诊所联盟信息失败, 请稍候重试'
        })
      })
    },
    isJoinChecked() {
      if (this.isDisabledJoin == false) {
        this.$confirm(
          `您确定要加入诊所联盟吗`,
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            apiSetClinicAlliance().then(res => {
              this.$message({
                type: 'success',
                offset: this.$store.state.config.outer_height,
                message: '加入诊所联盟成功'
              })
              this.isJoinAlliance = true
              this.isDisabledJoin = true
              this.joinAllianceMessage = '您已加入诊所联盟'
            }).catch(err => {
              console.log(err, '------加入诊所联盟api---error')
              return this.$message({
                type: 'error',
                offset: this.$store.state.config.outer_height,
                message: err
              })
            })
          })
          .catch(() => {
            this.isJoinAlliance = false
          })
      } else {
        let getCompanyInfo = this.$store.state.user.company_data
        this.$store.commit('setCAparams', { mark: 1, company_id: getCompanyInfo.company_id })
        this.$nextTick(() => {
          this.$bus.$emit('select_tabs', '3-3');
        })
      }
    },
    // 会员中心信息
    async Getusercentre() {
      let data = await Getusercentre()
      this.all_data = data.data;
    },
    // 获取所在地区
    async GetRegisterApicompany() {
      let params = {
        keyword: this.company.company_name
      }
      let data = await GetRegisterApicompany(params)
      this.RegisterApicompany = data.data
    },
    // 获取企业信息
    async GetusershopList() {
      let datalist = await GetusershopList();
      if (datalist.code == 1) {
        this.shoplist = datalist.data.shop[0];
      }
    },
    // 获取收货地址列表
    async GetuseraddressList() {
      let data = await GetuseraddressList();
      this.take_address = data.data.account;
    },
    // 获取采购人员列表
    async GetuserbuyerList() {
      let data = await GetuserbuyerList();
      this.buyerList = data.data.account;
      console.log('获取采购人员列表', data)
    },

    // 退出登录
    async Exit_login() {
      let user_Login = await this.$store.dispatch('userLogout');
      console.log('退出登录', user_Login)
    }
  }
}
</script>

<style lang="less" scoped>
.big_box {
  width: 980px !important;
  height: calc(100vh - 185px - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  color: #4e5969;
  font-size: 14px;
  padding: 0px 0px 10px 0px;

  label {
    margin: 0px 0px 0px 30px;
    font-size: 14px;
    color: #1d2129;
  }

  .buttom_box {
    padding: 20px 0px 20px 30px;
    border-bottom: 1px solid #e5e6eb;
    gap: 15px;
  }

  .personaldata {
    width: 95%;
    margin: 0 auto;
    padding: 10px 0px 0px 0px;
    height: 30px;
    border-bottom: 1px solid #e5e6eb;
    color: #1d2129;
    font-size: 16px;
  }

  .head {
    margin: 0px 0px 0px 30px;
    width: 50px;
    height: 50px;
    /* background: #D8D8D8;; */
  }

  .exit_login {
    cursor: pointer;
    margin: 20px 0px 0px 30px;
    width: 100px;
    height: 30px;
    background: #ff334c;
    color: #ffffff;
    font-size: 14px;
  }

  .join_alliance_mes {
    cursor: pointer;
    margin-left: -8px;
  }
}
</style>