<template>
  <div class="box d-flex justify-content-center">
    <!-- 登录 -->
    <div class="img_login">
      <img src="./images/main_bg.png" alt="暂无登录主图">
    </div>
    <div class="loginform">
      <div class="tab clearFix d-flex justify-content-center">
        <div @click="showlogin('code')" class="current" :class="{ select_model: model == 'code' }">短信验证码登录</div>
        <div @click="showlogin('Account')" class="current" :class="{ select_model: model == 'Account' }">账号密码登录</div>
      </div>

      <!-- 账号密码登录 -->
      <div v-if="model == 'Account'" class="content">
        <div class="form">
          <div class="input-text clearFix">
            <el-input v-model="phone" type="text" placeholder="请输入登录账号" autocomplete="on" clearable
              prefix-icon="el-icon-user"></el-input>
          </div>
          <div class="input-text clearFix">
            <el-input v-model="password" type="password" placeholder="请输入密码" autocomplete="on" show-password clearable
              @keyup.enter="userLogin" prefix-icon="el-icon-key"></el-input>
          </div>
          <div class="setting clearFix d-flex align-items-center">
            <label style="margin-right: 8px;">
              <input name="m10" type="checkbox" v-model="select_state">
            </label>
            <label class="user" @click="select_state = !select_state">我已阅读同意</label>
            <label class="UserAgreement" @click="get_show('user')">
              《先大拼药药用户协议》
            </label>
            <label class="user">
              和
            </label>
            <label class="UserAgreement" @click="get_show('privacy')">
              《隐私政策》
            </label>
          </div>
          <button class="btn" @click="userLogin">立即登录</button>
        </div>
      </div>

      <!-- 验证码登录 -->
      <div v-if="model == 'code'" class="content">
        <div class="form">
          <div class="input-text clearFix">
            <el-input v-model="phone" type="text" placeholder="请输入手机号" autocomplete="on" clearable
              prefix-icon="el-icon-user"></el-input>
          </div>
          <div class="input-text clearFix d-flex align-items-center">
            <el-input v-model="password" type="text" placeholder="请输入验证码" clearable @keyup.enter="userLogin"
              prefix-icon="el-icon-key"></el-input>
            <div v-if="!timerRunning" @click="getcode"
              class="getcode flex-grow-1 d-flex justify-content-center align-items-center">获取验证码
            </div>
            <div v-if="timerRunning" class="code_ d-flex flex-grow-1 align-items-center justify-content-center">
              <div>{{ remainingTime }}s</div>
              <span>后重新获取</span>
            </div>
          </div>
          <div class="setting clearFix d-flex align-items-center">
            <label style="margin-right: 8px;">
              <input name="m10" type="checkbox" v-model="select_state">
            </label>
            <label class="user" @click="select_state = !select_state">我已阅读同意</label>
            <label class="UserAgreement" @click="get_show('user')">
              《先大拼药药用户协议》
            </label>
            <label class="user">
              和
            </label>
            <label class="UserAgreement" @click="get_show('privacy')">
              《隐私政策》
            </label>
          </div>
          <button class="btn" @click="userLogin">立即登录</button>
        </div>
      </div>

      <!-- 版权 -->
      <div class="copyright">Copyright ©{{ isYear }} 山东先大健康产业股份有限公司 版权所有</div>
    </div>

    <!-- 用户和隐私协议弹窗 -->
    <div v-if="privacy_pop" class="d-flex justify-content-center align-items-center pop_pay">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between" style="font-size: 16px;">
          <span>协议声明</span>
          <i class="el-icon-close" @click="privacy_pop = false"></i>
        </div>
        <div class="d-flex flex-wrap pop_describe">
          <div>请您仔细阅读协议，其中有对您权利义务的特别约定等重要条款，请点击同意后方可使用本应用。</div>
        </div>
        <div class="agreement d-flex justify-content-center align-items-center">
          {{ select_agreement == 'user' ? '用户协议' : '隐私协议' }}</div>
        <div class="pop_content" v-html="select_agreement == 'user' ? user_Agreement.content : privacy_Agreement.content">
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <el-button @click="agree_continue" type="danger">同意并继续</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from '@/utils/tools'
import { getPolicyAgreement, getConfig } from "@/api";
import store from '@/store'
import {
  mapActions
} from "vuex"
export default {
  name: 'Login',
  data() {
    return {
      select_state: false,
      select_agreement: "", // 选中协议
      privacy_pop: false,
      privacy_Agreement: "", // 隐私协议
      user_Agreement: "", // 用户协议
      model: 'Account',  // 登录模式
      isfinish: false,
      //收集表单数组
      // 手机号
      phone: '',
      //密码
      password: '',
      code: "", // 手机验证码
      timerRunning: false,
      remainingTime: 60,
      // 正在登录标识
      isLoging: false
    }
  },
  created() {
    this.$version()
  },
  mounted() {
    this.getPolicyAgreement()
  },
  watch: {
    model() {
      this.phone = "";
      this.password = "";
    }
  },
  computed: {
    isYear() {
      return dateFormat(new Date())[0].substring(0, 4)
    }
  },
  methods: {
    ...mapActions(['getConfigs']),
    //登录
    async userLogin() {
      const { phone, password } = this;
      let params;
      if (this.model == 'code') {
        params = {
          scene: 2, // 登录方式
          terminal: 4, // 注册来源:1=微信小程序 2=微信公众号 3=手机H5登录 4=电脑PC 5=苹果app 6=安卓app 7=字节小程序(头条)
          account: phone, // 用户名，手机号
          code: password // 密码
        }
      } else {
        params = {
          scene: 1, // 登录方式
          terminal: 4, // 注册来源:1=微信小程序 2=微信公众号 3=手机H5登录 4=电脑PC 5=苹果app 6=安卓app 7=字节小程序(头条)
          account: phone, // 用户名，手机号
          password: password // 密码
        }
      }
      // console.log('登录参数', params)

      if (this.phone && this.password) {
        if (!this.select_state) {
          this.$message({
            message: '请先阅读《先大拼药药用户协议》和《隐私政策》协议',
            type: 'error'
          })
          return;
        }

        // 手机号登录
        if (this.model == 'code') {
          this.isCheckCompany(params)
        } else {
          this.isCheckCompany(params)
        }
      } else {
        this.$message({
          message: '请输入账号、密码或验证码',
          type: 'error'
        })
      }
    },
    // 验证是否已注册资质
    async isCheckCompany(params) {
      try {
        let user_Login = await this.$store.dispatch('userLogin', params);
        console.log('user_Login', user_Login)
        //登录跳转到home首页
        if (user_Login.code == 1) {
          this.isfinish = true;
          if (user_Login.data.company.company_sn == "") {
            return this.$message({
              message: '请前往先大拼药药APP注册资质后重新登录',
              type: 'error'
            })
          } else {
            this.isGetConfig(user_Login)
          }
        }
      } catch (e) {
        this.$message({
          message: e.message,
          type: 'error'
        })
      }
    },
    showlogin(type) {
      if (type == 'code') {
        this.model = 'code';
      } else {
        this.model = 'Account';
      }
    },
    get_show(type) {
      this.select_agreement = type;
      this.privacy_pop = true;
      // console.log(this.privacy_pop)
    },
    isGetConfig(user_Login) {
      if (this.isLoging) return false
      this.isLoging = true
      getConfig().then((res) => {
        console.log(res, '------获取应用配置api---success2')
        this.$store.commit('setConfig', res.data)
      }).then(() => {
        this.$router.push({ path: './home', query: { data: user_Login } })
        this.$message({
          message: '登录成功',
          type: 'success'
        })

        setTimeout(() => {
          this.isLoging = false
        }, 3000)
      })
        .catch((err) => {
          console.log(err, '------获取应用配置api---error')
          this.$message({
            message: '抱歉，暂时无法获取应用配置，请稍候重试',
            type: 'warning'
          })
          this.isLoging = false
        })
    },
    // 获取验证码
    async getcode() {
      if (this.phone) {
        let params = { mobile: this.phone }
        let datalist = await this.$store.dispatch('getCode', params)
        this.code = datalist.data.code
        this.timerRunning = true
        this.countdown()
        console.log(this.code, '------登录短信验证码')
      } else {
        this.$message({
          message: '请输入手机号',
          type: 'error'
        })
      }
    },

    // 倒计时
    countdown() {
      if (this.remainingTime > 0) {
        setTimeout(() => {
          this.remainingTime--;
          this.countdown();
        }, 1000);
      } else {
        this.timerRunning = false;
        this.remainingTime = 60;
      }
    },
    // 获取隐私
    async getPolicyAgreement() {
      let data_privacy = await getPolicyAgreement({ type: 1 });
      let data_user = await getPolicyAgreement({ type: 2 });
      this.privacy_Agreement = data_privacy.data;
      this.user_Agreement = data_user.data;
    },

    // 同意并继续
    agree_continue() {
      this.privacy_pop = false;
      this.select_state = true;
    }
  }
}
</script>

<style lang="less" scoped>
// @media screen and(max-width: 1200px) {
//   .img_login {
//     img {
//       opacity: 0;
//       transition: opacity 1s ease-in-out;
//     }
//   }
// }

.box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 550px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img_login {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 1s ease-in-out;

  img {
    width: 100%;
    height: 100%;
  }
}

.loginform {
  position: relative;
  width: 420px;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);

  .tab {
    width: 302px;
    text-align: center;

    .current {
      margin: 0px 0px 0px 20px;
      height: 26px;
      width: 126px;
      cursor: pointer !important;
      color: #86909c;
      font-size: 18px;
    }
  }

  .content {
    width: 380px;
    height: 316px;
    box-sizing: border-box;
    padding: 18px;

    .form {
      margin: 15px 0 18px 0;
      font-size: 12px;
      line-height: 18px;
      cursor: pointer;

      .input-text {
        margin-bottom: 16px;

        .getcode {
          width: 125px;
          height: 36px;
          line-height: 36px;
          border: 1px solid #ff334c;
          font-size: 14px;
          color: #ff334c;
          margin-left: 10px;
          border-radius: 5px;
        }

        .code_ {
          width: 200px;

          div {
            color: #ff334c;
            margin: 0 5px;
          }
        }

        .pwd {
          background-position: -72px -201px;
        }
      }

      .setting {
        label {
          float: left;
        }

        .forget {
          float: right;
        }
      }

      .btn {
        background-color: #ff334c;
        padding: 6px;
        border-radius: 0;
        font-size: 16px;
        font-family: 微软雅黑;
        word-spacing: 4px;
        border: 1px solid #e1251b;
        color: #fff;
        width: 100%;
        height: 36px;
        margin-top: 50px;
        outline: none;
      }
    }

    .call {
      margin-top: 30px;

      ul {
        float: left;

        li {
          float: left;
          margin-right: 5px;
        }
      }

      .register {
        float: right;
        font-size: 15px;
        line-height: 38px;
      }

      .register:hover {
        color: #4cb9fc;
        text-decoration: underline;
      }
    }
  }

  .copyright {
    position: absolute;
    left: 12%;
    bottom: 20px;
    color: #999;
  }
}

.UserAgreement {
  color: #ff334c;
}

.user {
  margin: 0 3px;
  color: #86909c;
}

.select_model {
  border-bottom: 1px solid #ff334c;
  color: #ff334c !important;
}

.pop_pay {
  padding: 20px 5px 20px 10px;
  background-color: #ffffff;
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.1);

  .pop_content {
    width: 800px;
    height: 500px;
    overflow-x: auto;
  }

  .agreement {
    font-size: 20px !important;
    font-weight: 600;
    margin: 20px 0px 20px 0px;
  }

  .pop_describe {
    margin: 30px 0px 0px 0px;
  }
}
</style>