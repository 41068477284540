<!-- // 商品列表组件 -->
<template>
  <div class="d-fel flex-column">
    <!-- <div v-if="isGoodsAdList && isGoodsAdList.status == 1 && isGoodsAdList.list.length > 0 && pageInfo.index > 1" class="goods_ad_list">
      <img :src="$getImageUri(isGoodsAdList.list[newAdImgIndex].img)" alt="抱歉图片加载失败">
    </div> -->
    <div v-if="tyoe_list && tyoe_list.length > 0" class="d-flex align-items-center flex-wrap type_box">
      <div v-for="(item, index) in tyoe_list" :key="index" class="for_box d-flex flex-column">
        <div class="d-flex flex-column" style="width: 191px;" title="点击商品图片查看商品详情">
          <div class="card_herf">
            <div class="for_image" @click="card_herf(item)">
              <img class="underlay_img" :src="$getImageUri(item.image)" alt="抱歉图片加载失败" />
              <img v-if="item.frame" class="border-image" :src="$getImageUri(item.frame)" alt="抱歉图片加载失败" />
              <div v-if="item.type == 2" class="temporary_goods">{{ item.expired_type_name ? item.expired_type_name :
                '临期商品' }}</div>
            </div>
            <div class="specialoffer_title">
              <span v-if="item.medical_insurance"
                :class="{ 'class-A': item.medical_insurance == '甲类', 'class-B': item.medical_insurance == '乙类' }">医保{{
                  item.medical_insurance }}</span>
              <span v-if="item.activity_type_text" :style="Getpickcolor(item.activity_type_text)" class="specialoffer">
                {{ item.activity_type_text }}活动</span>
              <span class="goods_name">{{ item.name }}</span>
            </div>
            <div class="spec gray_tone">{{ item.spec }}</div>
            <div v-if="item.expired_date" class="d-flex  period_of_validity align-items-center">
              <div style="color: #FF334C;">效期优于</div>
              <div style="color: #1D2129; font-size: 14px;">{{ item.expired_date }}</div>
            </div>
            <div class="company gray_tone" :title="item.supplier_name">{{ item.supplier_name }}</div>
            <div class="d-flex exchange_price  align-items-center">
              <div>
                零售价￥{{ item.cost_price }}
              </div>
              <div>
                毛利率{{ item.rate }}%
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center sup_div">
            <div class="Supplyprice">
              供货价¥{{ item.lineation_price }}
            </div>
            <div v-if="item.middle_package_number > 0" @click="middleOrLargePackage(0, item)" class="inpackage"
              :class="{ select_Pack: item.middle_package_status == true || (item.mini_buy_number == item.middle_package_number && !item.large_package_status) }">
              中包{{ item.middle_package_number }}
            </div>
            <div v-if="item.large_package_number > 0" @click="middleOrLargePackage(1, item)" class="Package"
              :class="{ select_Pack: item.large_package_status == true }">
              件装{{ item.large_package_number }}
            </div>
          </div>

          <div class="Post_coupon_price d-flex justify-content-between align-items-center">
            <div class="d-flex coupon_price">
              {{ item.activity_sn && item.activity_sn != -1 ? '活动价：¥' : '券后价：¥' }}
              {{ item.sell_price }}
            </div>
            <div v-if="item.stock > 0 && (item.is_activity_goods == 1 && item.activity_stock <= 0) || item.stock <= 0">
              <el-button type="info" size="small" round disabled>已售罄</el-button>
            </div>
            <div v-else-if="isCheckShowQuota(item) && !addon_number">
              <el-tooltip :value="true" effect="light" placement="bottom-start">
                <div slot="content" v-for="(collapse_item, collapse_index) in item.step_price" :key="collapse_index">
                  购买{{ collapse_item.start_num }}-{{ collapse_item.end_num }}盒享{{ priceName(item.activity_sn) }}<span
                    style="color: #FF334C"><span style="font-size: 10px;">￥</span>{{ collapse_item.price }}</span>
                </div>
                <el-input-number @change="(currentValue, oldValue) => changenum(currentValue, oldValue, item)"
                  :disabled="isDisabledStep" :step="item.step_number" size="mini" @input="handleInput"
                  v-model="item.goods_num" :key="inputNumberKey" :min="0" label="描述文字" @focus="stepperFocus"
                  @blur="isStepperFocusMark = false"></el-input-number>
              </el-tooltip>
            </div>
            <div v-else-if="!addon_number" class="d-flex">
              <el-input-number @change="(currentValue, oldValue) => changenum(currentValue, oldValue, item)"
                :disabled="isDisabledStep" :step="item.step_number" size="mini" @input="handleInput"
                v-model="item.goods_num" :key="inputNumberKey" :min="0" label="描述文字" @focus="stepperFocus"
                @blur="isStepperFocusMark = false"></el-input-number>
            </div>
            <div class="d-flex select_goods justify-content-center align-items-center" v-if="addon_number">
              <el-button v-if="item.is_selected" type="primary" size="mini" round>已选择</el-button>
              <el-button v-else :disabled="is_disabled == 0 ? true : false" size="mini" round
                @click="Edit_shop(item)">选择</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoData v-else></NoData>
    <div class="d-flex" v-if="pageInfo.total" style="padding:1rem;">
      <el-pagination :current-page="pageInfo.index ? pageInfo.index : 1" :page-size="pageInfo.size"
        @current-change="handlePageChange" layout="total, prev, pager, next, jumper" :total="pageInfo.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Getcartlist } from '@/api'
import addCartCheck from '@/mixins/addCartCheck'
export default {
  name: 'commodity',
  props: {
    tyoe_list: {
      type: Array,
      default: () => []
    },
    pageInfo: {
      type: Object,
      default: function () {
        return {
          oldval: "",
          index: 1,
          size: 10,
          total: 0,
        }
      }
    },
    addon_number: {
      type: Boolean,
      default: false
    },
    // 是否禁用步进器
    isDisabledStep: {
      type: Boolean,
      default: false
    },
    is_disabled: {
      type: [Number, String],
      default: 1
    },
    // 首页商品列表广告图
    isGoodsAdList: {
      type: Object,
      default: () => { }
    },
    // 首页商品列表展示广告图索引值
    isShowAdImgindex: {
      type: [Number, String],
      default: 0
    }
  },
  mixins: [addCartCheck],
  data() {
    return {
      num: "",
      prevCount: 0,
      pageIndex: 1,
      // 广告图索引props接收值
      newAdImgIndex: 0
    }
  },
  watch: {
    tyoe_list(newval) {
      if (newval.length) {
        this.GetExchangelists();
      }
    },
  },
  mounted() {
    this.newAdImgIndex = this.isShowAdImgindex
  },
  methods: {
    handlePageChange(page) {
      if (this.pageindex < page) {
        this.newAdImgIndex++
      } else {
        if (this.isGoodsAdList && this.isGoodsAdList.list) {
          if (this.newAdImgIndex < this.isGoodsAdList.list.length) {
            this.newAdImgIndex++
          } else {
            this.newAdImgIndex--
          }
        }
      }
      if (this.isGoodsAdList && (!this.isGoodsAdList.list[this.newAdImgIndex] || !this.isGoodsAdList.list[this.newAdImgIndex].img)) this.newAdImgIndex = 0

      this.pageindex = page;

      this.$emit('pageindex', this.pageindex)
    },
    // 获取中间购物车个数num
    async GetExchangelists() {
      this.cartlist = await Getcartlist('');
      let data_list = this.tyoe_list;
      if (data_list.length) {
        data_list.forEach((item, index) => {
          let zat = this.$findGoodname(item, this.cartlist);
          zat.then(res => {
            data_list[index]['goods_num'] = res;
            this.$forceUpdate();
          })
          data_list[index].is_activity_goods = data_list[index].is_activity_goods == undefined ? 0 : data_list[index].is_activity_goods;
        });
        this.tyoe_list = data_list;
      }
    },
    card_herf(data) {
      this.$isRouterParams('ProductDetails', { data: JSON.stringify(data) })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/[data-v-4298b343] .el-tabs--card>.el-tabs__header .el-tabs__item {
  font-size: 16px;
}

/deep/.el-tabs--card>.el-tabs__header {
  border: 0;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: none;
}

/deep/.el-tabs__nav {
  background-color: none;
  padding: 10px 0px 0px 30px;
}

/deep/.el-tabs__item.is-active {
  color: #ff334c;
}

/deep/.el-tabs__item:hover {
  color: #ff334c;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
  /* color: #000000; */
  font-size: 18px;
  border-left: none;
}

.gray_tone {
  color: #999;
}

.big_box {
  position: relative;
  z-index: 10;
  width: 1200px;
  margin-top: 180px !important;
  /* height: 445px; */
  margin: 0 auto;
}

.sort {
  margin-top: 10px;
  color: #1d2129 !important;
  font-size: 16px;
  height: 50px;
  background-color: #ffffff;
}

.type_box {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 10px 10px 0;
  border-radius: 5px;
}

.for_box {
  margin: 0 18px 18px 0;
  width: 221px;
  height: 434px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

  &:last-child {
    margin-right: auto;
  }

  &:nth-child(5n) {
    margin-right: 0;
  }
}

.for_image {
  position: relative;
  margin: 15px 0px 10px 10px;
  width: 198px;
  height: 198px;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  .underlay_img {
    width: 100%;
    height: 100%;
  }

  .border-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.temporary_goods {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #ff334c;
  color: #fff;
}

.specialoffer_title {
  margin-left: 10px;
  display: flex;
  flex-direction: row;

  .class-A,
  .class-B {
    margin-right: 5px;
    border-radius: 5px;
    padding: 0 3px;

    white-space: nowrap;
  }

  .class-A {
    border: 1px solid #2d44dc;
    color: #2d44dc;
  }

  .class-B {
    border: 1px solid #f19429;
    color: #f19429;
  }

  .specialoffer {
    border: 1px solid #ff334c;
    border-radius: 5px;
    font-size: 12px;
    color: #ff334c;
    margin-right: 5px;
    padding: 0 3px;
    white-space: nowrap;
  }

  .goods_name {
    align-items: flex-start;
    width: 120px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.spec {
  margin: 5px 0px 0px 10px;
  font-size: 14px;
}

.period_of_validity {
  margin: 5px 0px 0px 10px;

  div {
    &:nth-child(1) {
      margin-right: 3px;
    }
  }
}

.company {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0px 0px 10px;
  font-size: 14px;
}

.exchange_price {
  margin: 5px 0px 0px 10px;
  font-size: 12px;
  color: #1d2129;

  div {
    &:nth-child(1) {
      margin-right: 5px;
    }
  }
}

.sup_div {
  margin: 5px 0px 0px 10px;
}

.Supplyprice {
  color: #4e5969;
  font-size: 12px;
  margin-right: 10px;
}

.select_Pack {
  background-color: #ffeeef;
  border: 1px solid #ff334c;
  color: #ff334c;
  border-radius: 5px;
  padding: 2px 2px;
}

.inpackage,
.Package {
  cursor: pointer !important;
  cursor: pointer !important;
  color: #1d2129;
  font-size: 10px;
  font-weight: 550;
}

.inpackage {
  margin-right: 5px;
}

.Post_coupon_price {
  margin: 10px 0px 0px 10px;
  font-size: 12px;

  .coupon_price {
    color: #ff334c;
  }
}

.card_herf {
  cursor: pointer !important;
}

.select_goods {
  width: 48px;
  height: 20px;
  background: #ff334c;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
}

.goods_ad_list {
  margin: 20px auto;

  img {
    width: 100%;
    height: 240px;
    border-radius: 10px;
  }
}
</style>