<!-- 签到代金券 -->
<template>
  <div class="big_box d-flex flex-column">
    <div class="top d-flex flex-column bg-white">
      <div class="d-flex SignVoucher">
        我的代金券
      </div>
      <div class="d-flex flex-grow-1">
        <!-- 左侧 -->
        <div class="d-flex flex-column justify-content-center align-items-center box_left">
          <div v-if="all_data.earn && all_data.earn[0]">
            <img style="width: 100px;height: 100px;" :src="$getImageUri(all_data.earn[0].icon)" alt="抱歉图片加载失败" />
          </div>
          <div v-if="all_data.earn && all_data.earn[0]" class="d-flex box_left_label">{{ all_data.earn[0].integral }}</div>
          <div class="d-flex justify-content-center">
            <div v-if="all_data.earn && all_data.earn[0]" @click="Go_sign" :class="{ box_left_sign_true: all_data.earn[0].is_done }" class="box_left_sign d-flex justify-content-center align-items-center">
              {{ all_data.earn[0].is_done ? '今日已签到' : '立即签到' }}
            </div>
          </div>
        </div>
        <!-- 右侧签到卡 -->
        <div class="d-flex flex-column date_table">
          <div class="d-flex align-items-center box_right_sign">
            <div>已累计签到{{ all_data.user && all_data.user.continuous_days ? all_data.user.continuous_days : 0 }}天</div>
            <div>
              <el-tooltip effect="dark" placement="right-start">
                <i class="el-icon-question"></i>
                <div slot="content">
                  <div v-if="commendContent" v-html="commendContent" class="sign_remarks"></div>
                  <div v-else>抱歉, 暂无签到说明</div>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div class="d-flex flex-column box_right_card" v-for="(item, index) in all_data.lists" :key="index">
              <div :class="{ box_right_card_circular_select: item.is_sign }" class="d-flex justify-content-center align-items-center box_right_card_circular">
                +{{ item.integral }}
              </div>
              <div class="d-flex justify-content-center box_right_card_day">{{ item.day }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-grow-1 flex-column bg-white box_buttom_table">
      <div class="box_center d-flex flex-column bg-white">
        <div class="table-detail">最近明细</div>
        <div class="table-container" v-if="list.length">
          <table>
            <tr>
              <th>金额</th>
              <th>详细说明</th>
              <th>记录时间</th>
            </tr>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ item.change_amount }}</td>
              <td>{{ item.remark }}</td>
              <td>{{ item.create_time }}</td>
            </tr>
          </table>
        </div>
        <NoData v-if="!list.length"></NoData>
      </div>
    </div>
  </div>
</template>

<script>
import NoData from '@/components/NoData/index'
import { GetmySignLog, Getsignlists, Editsign, apiSignGetRemark } from "@/api";
export default {
  name: 'SignVoucher',
  components: {
    NoData,
  },
  data() {
    return {
      list: [],
      all_data: {},
      rules: ''
    }
  },
  created() {
    this.signGetRemark()
  },
  mounted() {
    this.GetmySignLog()
    this.Getsignlists()
  },
  computed: {
    commendContent() {
      let arr = this.rules.split("")
      return arr.map((item) => {
        return item === '；' ? "<br>" : item
      }).join("")
    }
  },
  methods: {
    signGetRemark() {
      apiSignGetRemark().then((res) => {
        this.rules = res.data.remark
      })
    },
    // 我的积分列表
    async GetmySignLog() {
      let data = await GetmySignLog();
      this.list = data.data;
    },
    // 卡片积分列表
    async Getsignlists() {
      let data = await Getsignlists();
      if (data.code) {
        this.all_data = data.data;
      }
    },
    // 签到
    async Go_sign() {
      let edit = await Editsign();
      if (edit.code) {
        this.GetmySignLog();
        this.Getsignlists()
        this.$message({
          message: '签到成功',
          type: 'success'
        })
      } else {
        this.$message.warning(edit.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.big_box {
  width: 980px !important;
  height: calc(100vh - 185px - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f6f7f9;

  .top {
    height: 400px;

    .SignVoucher {
      width: 95%;
      margin: 0 auto;
      padding: 10px 0;
      border-bottom: 1px solid #e5e6eb;
      color: #1d2129;
      font-size: 16px;
    }
  }

  .box_center {
    width: 95%;
    margin: 0 auto;
  }
}

.box_left_label {
  font-size: 24px;
  color: #1d2129;
  font-weight: 600;
  margin-top: 20px;
}

.box_left_sign {
  cursor: pointer;
  width: 220px;
  height: 30px;
  background: #ff334c;
  border-radius: 5px;
  color: #ffffff;
}

.box_left_sign_true {
  color: #ffffff;
  background-color: #d8d8d8;
}

.box_left_square {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background: #d8d8d8;
}

.box_left {
  width: 40vh;
}

.table-container {
  border-collapse: collapse;
}

table {
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.date_table {
  width: 60vh;
  margin-bottom: 20px;
}

.box_right_sign {
  font-size: 14px;
  color: #1d2129;
  margin: 10px 0px 0 20px;
}

.box_right_card {
  margin: 20px 0px 0px 20px;
  flex-basis: calc((100% - 10 * 10px) / 11);
}

.box_right_card_circular {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f2f3f5;
  font-size: 12px;
  color: #4e5969;
}

.box_right_card_circular_select {
  background-color: #ff334c;
  color: #ffffff;
}

.box_right_card_day {
  font-size: 12px;
  color: #4e5969;
  margin-top: 5px;
}

.box_buttom_table {
  margin-top: 20px;
  padding-top: 10px;
  overflow-x: auto;
}

.table-detail {
  color: #1d2129;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
}

.sign_remarks {
  font-size: 16px;
  line-height: 30px;
}
</style>