<template>
  <div class="big_box d-flex flex-column">
    <!-- 图片 -->
    <div class="image_text">
      <img src="./images/Couponcenter.png" alt="抱歉活动图片加载失败" />
    </div>
    <!-- tab页 -->
    <div style="margin-top: 20px;" class="flex-grow-1 centerbox d-flex flex-column ">
      <div class="d-flex justify-content-center tabclass">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="商品优惠券" name="Productvoucher"></el-tab-pane>
          <el-tab-pane label="免邮券" name="Fsv"></el-tab-pane>
          <el-tab-pane label="现金券" name="coupon"></el-tab-pane>
          <el-tab-pane label="商品折扣券" name="pdc"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="coupon_list">
        <voucher v-if="datalist.length > 0" @ToRefresh="ToRefresh" :list="datalist" :type="data_type"></voucher>
        <NoData v-else></NoData>
      </div>
    </div>
  </div>
</template>

<script>
import { Getcouponlists } from "@/api";
export default {
  name: 'Couponcenter',
  data() {
    return {
      data_type: "",
      activeName: 'all',
      datalist: []
    }
  },
  mounted() {
    this.GetExchangelists();
  },
  computed: {
  },
  watch: {
    data_type() {
      this.GetExchangelists()
    }
  },
  methods: {
    ToRefresh() {
      this.GetExchangelists();
    },
    handleClick(tab, event) {
      if (tab.index == '0') {
        this.data_type = "";
      } else {
        this.data_type = tab.index;
      }
    },
    // 领劵中心列表
    async GetExchangelists() {
      let params = { type: this.data_type }
      let data = await Getcouponlists(params);
      if (data.code == 1) {
        this.datalist = data.data.lists;
      }
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.el-tabs__nav-scroll {
  border-bottom: 1px solid #e5e6eb;
}

/deep/[data-v-0d36a062] .el-tabs--card > .el-tabs__header .el-tabs__nav {
  background-color: #ffffff;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #ff334c;
}

/deep/.el-tabs__nav {
  padding-left: 30px;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}

/deep/.el-tabs__item.is-active {
  color: #ff334c;
}

/deep/.el-tabs__item:hover {
  color: #ff334c;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  /* color: #000000; */
  font-size: 18px;
  border-left: none;
}

.big_box {
  height: 100vh;
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
}

.image_text {
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #fff;

  img {
    width: 100%;
    height: 200px;
  }
}

.tabclass {
  width: 100%;
  margin-top: 10px;
}

.coupon_list {
  margin-left: 20px;
}

.centerbox {
  background-color: #ffffff;
}
</style>