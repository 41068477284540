<!-- 订单列表 -->
<template>
  <div ref="bigBoxRefs" class="big_box d-flex flex-column bg-white">
    <div v-if="!details" class="d-flex flex-column bg-white">
      <div class="d-flex" :style="{ position: orderTabsFixed ? 'fixed' : '' }">
        <el-tabs v-model="activeName" type="card" @tab-click="orderTabsChange">
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="待付款" name="0"></el-tab-pane>
          <el-tab-pane label="待发货" name="1"></el-tab-pane>
          <el-tab-pane label="待收货" name="2"></el-tab-pane>
          <el-tab-pane label="已完成" name="3"></el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="all_data && all_data.lists && all_data.lists.length > 0">
        <div class="d-flex flex-column justify-content-center align-items-center Ordering_box" v-for="(item, index) in all_data.lists" :key="index">
          <div class="d-flex justify-content-between align-items-center Ordering_title">
            <div class="d-flex text">
              <div>下单时间：</div>
              <div>{{ item.create_time }}</div>
            </div>
            <div class="d-flex text">
              <div>订单编号：</div>
              <div>{{ item.sn }}</div>
            </div>
            <div class="pob">{{ item.order_status_desc }}</div>
          </div>
          <div class="d-flex Products bg-white align-items-center justify-content-between">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center flex-grow-1 border_right" v-for="(items, indexs) in item.order_goods" :key="indexs">
                <div class="d-flex align-items-center" style="margin: 0 10px;">
                  <img style="width: 74px;height: 74px;" :src="$getImageUri(items.image)" alt="抱歉图片加载失败" />
                </div>
                <div class="d-flex flex-column goods_info">
                  <div class="Cold">{{ items.goods_name }}</div>
                  <div class="rai">{{ items.spec }}</div>
                  <div class="rai"><span style="color: #ff334c;">效期优于</span> {{ items.expired_date }}</div>
                  <div class="rai">{{ items.supplier_name }}</div>
                </div>
                <div class="d-flex flex-column align-items-center som_sup">
                  <div class="som">供货价</div>
                  <div class="sup">¥213</div>
                </div>
                <div class="d-flex flex-column align-items-center som_sups">
                  <div class="som">券后价</div>
                  <div class="sup">¥167</div>
                </div>
                <div class="d-flex align-items-center num_box">
                  X{{ items.goods_num }}
                </div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center flex-grow-1 Product_amount">
              <div class="Product d-flex">共{{ item.total_num }}件商品</div>
              <div class="d-flex">
                <div class="Product">应付金额：</div>
                <div class="amount">¥{{ item.order_amount }}</div>
              </div>
            </div>
            <div class="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
              <div v-for="(data, index_) in getOrderStatusButtons(item.btn)" :key="index_" class="d-flex to_money align-items-center justify-content-center">
                <el-button @click="onOrderButtons(data.event, item.sn, item.invoice_path)" :type="data.style">{{
                  data.name }}</el-button>
              </div>
              <el-button @click="Getdetails(item.order_status_desc, item.sn)" style="margin-top: 10px;">查看详情</el-button>
            </div>
          </div>
        </div>
      </div>
      <NoData v-else></NoData>
    </div>
    <!-- 详情 -->
    <orderList-details @Getback="Getback" v-if="details" :order_status_desc="order_status_desc" :sn="sn"></orderList-details>

    <!-- 查看物流弹窗 -->
    <el-dialog title="物流信息" :visible.sync="logisticsPopup" width="30%" center :modal-append-to-body="false">
      <el-timeline>
        <el-timeline-item v-for="(ls, index) in logisticsInfo" :key="index" :icon="ls.icon" :type="ls.type" :color="ls.color" :size="ls.size" :timestamp="ls.time">
          {{ ls.desc }}
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import orderList_details from "./orderList_details.vue";
import { Getorderlists, Getorderconfirm, GetorderorderTraces, Getordercancel, GetorderbuyAgain } from "@/api";
import NoData from '@/components/NoData/index'
export default {
  name: 'orderList',
  components: {
    NoData,
    'orderList-details': orderList_details
  },
  data() {
    return {
      order_status_desc: "",  // 订单状态
      sn: "",    // 订单编号
      details: false,
      all_data: {},
      activeName: "all",
      ButtonsMap: {
        payment: {
          event: 'payment',
          name: '立即付款',
          style: 'danger'
        },
        succeed: {
          event: 'succeed',
          name: '确认收货',
          style: 'success'
        },
        evaluate: {
          event: 'evaluate',
          name: '去评价',
          style: 'info'
        },
        content: {
          event: 'content',
          name: '查看内容',
          style: 'info'
        },
        express: {
          event: 'express',
          name: '查看物流',
          style: 'info'
        },
        close: {
          event: 'close',
          name: '取消订单',
          style: 'warning'
        },
        delete: {
          event: 'delete',
          name: '删除订单',
          style: 'danger'
        },
        pickup: {
          event: 'pickup',
          name: '查看提货码',
          style: 'normal'
        },
        invoice: {
          event: 'invoice',
          name: '电子发票',
          style: 'info'
        },
        again: {
          event: 'again',
          name: '再次购买',
          style: 'primary'
        }
      },
      logisticsPopup: false,
      logisticsInfo: [],
      orderTabsFixed: true
    }
  },
  watch: {
    activeName() {
      this.Getorderlists()
    }
  },
  mounted() {
    this.Getorderlists()
    window.addEventListener("scroll", this.isPageScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.isPageScroll)
  },
  methods: {
    isPartScrollToTop() {
      const container = this.$refs.bigBoxRefs
      container.scrollTop = 0
    },
    isPageScroll() {
      let pageY = document.documentElement.scrollTop
      if (pageY <= 0) {
        this.orderTabsFixed = true
      } else {
        this.orderTabsFixed = false
      }
    },
    orderTabsChange() {
      this.isPartScrollToTop()
    },
    // 关闭详情返回首页
    Getback() {
      this.details = false;
    },
    // 点击详情
    Getdetails(order_status_desc, sn) {
      this.order_status_desc = order_status_desc;
      this.sn = sn;
      this.details = true;
    },
    // 获取订单列表
    async Getorderlists() {
      let params = { order_status: this.activeName == "all" ? "" : this.activeName };
      let data = await Getorderlists(params)
      this.all_data = data.data;
    },
    // 获取订单状态按钮组
    getOrderStatusButtons(buttonStatus) {
      let buttons = []
      // 删除
      // buttonStatus.delete_btn && buttons.push(this.ButtonsMap['delete'])
      // 取消订单
      buttonStatus.cancel_btn == 1 && buttons.push(this.ButtonsMap['close'])
      // 查看内容
      buttonStatus.content_btn == 1 && buttons.push(this.ButtonsMap['content'])
      // 查看物流
      buttonStatus.delivery_btn == 1 && buttons.push(this.ButtonsMap['express'])
      // 评论
      buttonStatus.comment_btn == 1 && buttons.push(this.ButtonsMap['evaluate'])
      // 确认收货
      buttonStatus.confirm_btn == 1 && buttons.push(this.ButtonsMap['succeed'])
      // 提货码
      buttonStatus.pickup_btn == 1 && buttons.push(this.ButtonsMap['pickup'])
      // 立即付款
      buttonStatus.pay_btn == 1 && buttons.push(this.ButtonsMap['payment'])
      // 再次购买
      buttonStatus.again_btn == 1 && buttons.push(this.ButtonsMap['again'])
      // 电子发票
      buttonStatus.invoice_btn == 1 && buttons.push(this.ButtonsMap['invoice'])
      return buttons
    },
    // 点击订单按钮映射处理方法
    onOrderButtons(event, orderID, invoicePath) {
      switch (event) {
        // 立即付款(跳转付款页面，传sn)
        case 'payment':
          return this.handlePayment(orderID)
        // 确认收货(/order/confirm)
        case 'succeed':
          return this.handleSucceed(orderID)
        // 评论(无操作)
        case 'evaluate':
          return this.handleEvaluate(orderID)
        // 查看物流(跳转弹窗掉接口/order/orderTraces)
        case 'express':
          return this.handleExpress(orderID)
        // 取消订单(/order/cancel)
        case 'close':
          return this.handleClose(orderID)
        // 提货码(无操作)
        case 'pickup':
          return this.handlePickup(orderID)
        // 查看内容(无操作)
        case 'content':
          return this.handleContent(orderID)
        // 电子发票
        case 'invoice':
          return this.handleInvoice(orderID, invoicePath)
        // 再次购买(掉接口/order/buyAgain, 跳转购物车页面) 
        case 'again':
          return this.handleBuyAgain(orderID)
      }
    },

    // 立即付款
    handlePayment(id) {
      this.$router.push({ path: "payment", query: { order_sn: id } })
    },
    // 确认收货
    async handleSucceed(id) {
      Getorderconfirm({ sn: id }).then((res) => {
        console.log(res, '------确认收货api---success')
        this.Getorderlists()
      }).catch((err) => {
        console.log(err, '------确认收货api---error')
        this.$message({
          type: 'error',
          offset: this.$store.state.config.outer_height,
          message: err
        })
      })
    },
    // 查看物流
    async handleExpress(id) {
      let params = { sn: id }
      let res = await GetorderorderTraces(params);

      let data = res.data.traces
      if (data.length > 0) {
        for (var i in data) {
          if (i == 0) {
            data[i]['size'] = 'large'
            data[i]['type'] = 'primary'
          }
        }

        this.logisticsInfo = data
        this.logisticsPopup = true
      } else {
        this.$message({
          type: 'warning',
          offset: this.$store.state.config.outer_height,
          message: '抱歉，当前暂无物流信息'
        })
      }
    },
    // 取消订单
    async handleClose(id) {
      let params = { sn: id }
      let data = await Getordercancel(params);
      this.Getorderlists()
    },
    // 电子发票
    handleInvoice(id, path) {
      if (path) {
        axios(path, {//pdf的位置
          responseType: 'blob', //重要代码
        }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = "先大拼药药电子发票.pdf" //保存到本地的文件名称
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
      } else {
        this.$message({
          type: 'warning',
          offset: this.$store.state.config.outer_height,
          message: '抱歉，当前暂无电子发票信息'
        })
      }
    },
    // 再次购买
    async handleBuyAgain(id) {
      let params = { sn: id }
      let data = await GetorderbuyAgain(params);
      this.$store.dispatch('getShopCart')
      this.$isRouterParams('ShoppingCart')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-tabs__item.is-active {
  color: #ff334c;
  border-bottom: 1px solid #ff334c !important;
}

/deep/.el-tabs__item:hover {
  color: #ff334c;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  /* color: #000000; */
  font-size: 18px;
  border-left: none;
}

/deep/.el-tabs__nav {
  background-color: #ffffff;
  padding-left: 30px;
  width: 980px;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
  border-bottom: 1px solid #e5e6eb;
}

.big_box {
  width: 980px !important;
  height: calc(100vh - 185px - 140px);
  overflow-y: auto;
  overflow-x: hidden;

  .Ordering_box {
    margin: 50px auto;

    .Ordering_title {
      width: 100%;
      height: 40px;
      background: #f2f3f5;
      border: 1px solid #c9cdd4;

      .text {
        font-size: 14px;
        color: #1d2129;
        margin-left: 10px;
      }

      .pob {
        margin-right: 10px;
        font-size: 14px;
        color: #ff334c;
        border: 1px dashed #ff334c;
        padding: 3px 5px;
        border-radius: 10px;
      }
    }
  }

  .Products {
    border: 1px solid #c9cdd4;
    width: 100%;

    .Cold {
      font-size: 14px;
      color: #1d2129;
      font-weight: 600;
    }

    .rai {
      font-size: 14px;
      color: #4e5969;
    }

    .som {
      font-size: 14;
      font-weight: 400;
      color: #1d2129;
    }

    .sup {
      font-size: 14;
      color: #ff334c;
    }
  }
}

.goods_info {
  width: 200px;
  flex-wrap: wrap;
}

.som_sup {
  margin: 0px 0px 0px 50px;
}

.som_sups {
  margin: 0px 0px 0px 20px;
}

.num_box {
  font-weight: 600;
  margin: 0 10px 0 30px;
}

.Product_amount {
  height: 100%;
  border-right: 1px solid #c9cdd4;
}

.Product {
  margin: 0px 0px 0px 30px;
  font-size: 14px;
  color: #4e5969;
}

.amount {
  font-size: 14px;
  color: #ff334c;
}

.to_money {
  margin-top: 10px;
}

.Detail {
  margin: 10px 0px 0px 0px;
  color: #1d2129;
  font-size: 14px;
}

.border_right {
  padding: 20px 0px 20px 0px;
  border-right: 1px solid #c9cdd4;
}
</style>