import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'

import ElementUI from 'element-ui'
import './theme/index.css'
import '@/assets/styles/common.css'
import VueWechatTitle from 'vue-wechat-title' //动态修改title
import { VueMasonryPlugin } from 'vue-masonry' // 瀑布流插件

//三级联动组件
import Rightlist from './components/Rightlist'
import Loading from '@/components/Loading/Loading'
import Carsousel from '@/components/Carsousel'
import NoData from '@/components/NoData'
import commodity from '@/components/commodity/commodity'
import voucher from '@/components/voucher/voucher'
import recommand from '@/components/recommand/recommand'

import { applyUpdateCheck, findGoodname, indexJumpClick, isRouterParams } from '@/utils/common'
import { getImageUri } from '@/utils/tools'

import 'swiper/css/swiper.css'
import '@/components/index'

// 屏蔽console
(function shieldConsole() {
  if (process.env.NODE_ENV == "production") {
    Reflect.ownKeys(console).map(key => {
      if (typeof console[key] == "function") {
        console[key] = () => { }
      }
    })
  }
  return
})()

Vue.prototype.$bus = new Vue()
// 查找商品购物车数量
Vue.prototype.$findGoodname = findGoodname
// 首页跳转
Vue.prototype.$indexJumpClick = indexJumpClick
// 检验图片完整路径
Vue.prototype.$getImageUri = getImageUri
// 路由跳转传递参数
Vue.prototype.$isRouterParams = isRouterParams
// 路由跳转接收参数
let params = window.localStorage.getItem('router_params')
if (params) {
  params = JSON.parse(params)
} else {
  store.state.config.router_params
}
Vue.prototype.$params = params
// 应用版本检测
Vue.prototype.$version = applyUpdateCheck

Vue.component(ElementUI)
Vue.use(ElementUI)
Vue.use(VueWechatTitle);
Vue.use(VueMasonryPlugin);

Vue.component(Loading.name, Loading)
Vue.component(Rightlist.name, Rightlist)
Vue.component(Carsousel.name, Carsousel)
Vue.component(NoData.name, NoData)
Vue.component(commodity.name, commodity)
Vue.component(voucher.name, voucher)
Vue.component(recommand.name, recommand)

/*
import {reqCategoryList} from '@/api';
reqCategoryList();
Vue.config.productionTip = false
*/
//引入统一接口
//统一接口api文件夹里面全部请求函数
import * as API from '@/api'

new Vue({
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
    Vue.prototype.$API = API
  },
  router,
  //注册仓库：组件实例的身上会多一个属性
  store
}).$mount('#app')
