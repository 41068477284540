// 配置类vuex文件
import store from '@/store'

const state = {
  // 应用版本号
  applyVersion: window.localStorage.getItem('apply_version') || '2.2.3',
  // 安卓&苹果系统APP下载地址
  downloadQRCode: {
    // androidUrl: 'https://newpyy-app.oss-cn-qingdao.aliyuncs.com/update/%E5%85%88%E5%A4%A7%E6%8B%BC%E8%8D%AF%E8%8D%AF-2.2.3.apk',
    // iosUrl: 'https://apps.apple.com/cn/app/uni-unibae5d3f/id1578247504'
    androidUrl: 'https://xdpyy.sindayy.com/uploadapp/',
    iosUrl: 'https://xdpyy.sindayy.com/uploadapp/'
  },
  // 应用配置项
  config: window.localStorage.getItem('config') || {},
  // 页面提示居中距离
  outer_height: 20,
  // 未读消息通知数量
  not_read_notice: 0,
  // 路由跳转params参数
  router_params: '',
  // 诊所联盟页面参数
  clinicAlliance_params: {}
}

const mutations = {
  setApplyVersion(state, value) {
    state.applyVersion = value
    window.localStorage.setItem('apply_version', value)
  },
  setAppDownloadUrl(state, value) {
    state.downloadQRCode = value
  },
  setConfig(state, value) {
    state.config = value
    window.localStorage.setItem('config', value)
  },
  setOuterHeight(state, value) {
    state.outer_height = value
  },
  setNotReadNumber(state, value) {
    state.not_read_notice = value
  },
  setRouterParams(state, value) {
    window.localStorage.setItem('router_params', JSON.stringify(value))
    state.router_params = value
  },
  setCAparams(state, value) {
    state.clinicAlliance_params = value
  }
}

export default {
  state,
  mutations
}
