// 超值换购
<template>
  <div v-if="(exchangeListObj.status == 1 || exchangeListObj.pc_status == 1) && exchangeListObj.list.length > 0"
    class="exchange" @click="toExchangePage">
    <div class="exchange_top"
      :style="{ background: 'repeating-linear-gradient(to bottom,' + this.startColor + ',' + this.endColor + ')' }">
      <div class="exchange_left" :style="{ color: this.titleColor }">
        <div class="text_one">{{ title }}</div>
        <div class="text_two" :style="{ color: this.subTitleColor }">{{ subTitle }}</div>
        <!-- <div class="text_two">超低价格</div> -->
        <!-- <div class="text_two">超值换购</div> -->
      </div>
      <div class="exchange_right" :style="{ color: this.subTitleColor }">
        <div>查看更多</div>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="exchange_bottom">
      <div class="item_box" v-for="(item, index) in exchangeListObj.list" :key="index">
        <div>
          <div class="box_top">{{ item.activity_desc }}</div>
          <img class="medicine" :src="$getImageUri(item.image)" alt="抱歉图片加载失败" />
        </div>
        <div class="detail">
          <div class="detail_name">{{ item.goods_name }}</div>
          <div class="specifications">{{ item.spec }}</div>
          <div class="detail_price">
            <div class="new_price">￥{{ item.exchange_price }}</div>
            <div class="old_price">￥{{ item.goods_price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "self-index",
  props: {
    exchangeListObj: {
      type: Object,
      default: () => { }
    },
    title: {
      type: String,
      default: () => '超值换购'
    },
    subTitle: {
      type: String,
      default: () => '超低价格 超值换购'
    },
    startColor: {
      type: String,
      default: () => '#8a38f5'
    },
    endColor: {
      type: String,
      default: () => 'rgba(138, 54, 245, 0.5)'
    },
    isFloor: {
      type: Boolean,
      default: () => false
    },
    titleColor: {
      type: String,
      default: () => '#ffffff'
    },
    subTitleColor: {
      type: String,
      default: () => '#ffffff'
    }
  },
  methods: {
    toExchangePage() {
      // this.$isRouterParams('ValueExchange')
      if (this.isFloor) {
        this.$isRouterParams('ValueExchange', { sn: this.exchangeListObj.sn, img: this.exchangeListObj.img })
      } else {
        this.$isRouterParams('ValueExchange')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.medicine {
  width: 100%;
  // height: 173.077px;
}

.exchange_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #fff;
}

.exchange {
  width: 1200px;
  margin: 20px auto 0;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  font-family: PingFang SC;

  .exchange_top {
    height: 82.692px;
    border-radius: 15.385px 15.385px 0px 0px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 15.385px;
    // background: repeating-linear-gradient(to right,
    //     #8a38f5,
    //     rgba(138, 54, 245, 0.5));

    .exchange_left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;


      .text_one {
        font-size: 24px;
      }

      .text_two {
        font-size: 14px;
        margin-left: 20px;
      }
    }
  }

  .exchange_bottom {
    display: flex;
    align-items: flex-start;
    padding: 0 15.385px 20px;

    .item_box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // height: 300px;

      &:not(&:nth-child(5n)) {
        margin-right: calc(2.5%);
      }

      width: 211.538px;
      border-radius: 0px 0px 15.385px 15.385px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      margin-top: 20px;

      .box_top {
        width: 211.538px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 30.769px;
        background: #ff334c;
        font-size: 19.231px;
        color: #ffffff;
        padding: 6px;
        box-sizing: border-box;
      }

      .detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 5px 0 15.385px 0;
        box-sizing: border-box;

        .detail_name {
          font-size: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .specifications {
          color: #86909c;
          font-size: 14px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .detail_price {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .new_price {
            color: #ff334c;
            font-size: 16px;
          }

          .old_price {
            color: #86909c;
            font-size: 14px;
            text-decoration: line-through;
            margin-left: 10.462px;
          }
        }
      }
    }
  }
}
</style>