// 超值换购
<template>
    <div v-if="floorListObj.pc_status == 1 && floorListObj.list.length > 0" class="floor">
        <div v-for="(item, index) in floorListObj.list" :key="index">
            <!-- 限时秒杀 -->
            <Seckill v-if="item.content.keywords == 'seckill'" :seckillListObj="converClass(item, 1)" :title="item.title"
                isFloor :startColor="item.color" :endColor="item.color_end" :titleColor="titleColor"
                :subTitleColor="subTitleColor" />
            <!-- 特价 -->
            <Special v-else-if="item.content.keywords == 'special'" :seckillListObj="converClass(item, 2)" isFloor
                :startColor="item.color" :endColor="item.color_end" :titleColor="titleColor"
                :subTitleColor="subTitleColor" />
            <!-- 超值换购 -->
            <Self v-else-if="item.content.keywords == 'exchange'" :exchangeListObj="converClass(item, 3)"
                :title="item.title" isFloor :startColor="item.color" :endColor="item.color_end" :subTitle="item.subTitle"
                :titleColor="titleColor" :subTitleColor="subTitleColor" />
            <!-- 标签 -->
            <GoodsCard v-else-if="item.content.keywords == 'goods_label'" :goodsListObj="converClass(item, 5)"
                :titleColor="titleColor" :subTitleColor="subTitleColor" />
            <!-- 品牌 -->
            <GoodsCard v-else-if="item.content.keywords == 'goods_brand'" :goodsListObj="converClass(item, 6)"
                :titleColor="titleColor" :subTitleColor="subTitleColor" />
            <!-- 药企专区 -->
            <Brand2 v-else-if="item.content.keywords == 'brand'" :goodsListObj="converClass(item, 6)"
                :titleColor="titleColor" :subTitleColor="subTitleColor">
            </Brand2>
        </div>
    </div>
</template>

<script>
import Self from "@/pages/Home/Self";
import Seckill from "@/pages/Home/Seckill";
import Special from "@/pages/Home/Special";
import GoodsCard from "@/pages/Home/GoodsCard";
import Brand2 from "@/pages/Home/Brand2";

export default {
    name: "special-index",
    data() {
        return {
            // 标题颜色
            titleColor: '#353535',
            // 副标题颜色
            subTitleColor: '#4E5969',
        }
    },
    components: {
        Self,
        Seckill,
        Special,
        GoodsCard,
        Brand2
    },
    props: {
        floorListObj: {
            type: Object,
            default: () => { }
        }
    },
    methods: {
        converClass(obj, type) {
            // 秒杀 - 1
            // 特价 - 2
            // 换购 - 3
            // 赠品 - 4
            // 标签 - 5
            // 品牌专区 - 6
            var result = {};
            if (type == 1) {
                result = {
                    desc: obj.title,
                    status: 1,
                    list: obj.content.goodsList,
                    sn: obj.content.code,
                    img: obj.img
                }
            }
            else if (type == 2) {
                result = {
                    desc: obj.title,
                    status: 1,
                    list: obj.content.goodsList,
                    sn: obj.content.code,
                    img: obj.img
                }
            }
            else if (type == 3) {
                result = {
                    desc: obj.title,
                    status: 1,
                    list: obj.content.goodsList,
                    sn: obj.content.code,
                    img: obj.img
                }
            } else if (type == 5) {
                // result = {
                //     desc: obj.title,
                //     subheading: obj.subheading,
                //     color: obj.color,
                //     color_end: obj.color_end,
                //     status: 1,
                //     list: obj.content.goodsList,
                //     mark: type,
                //     activity_sn: obj.content.code,
                //     keywords: obj.content.keywords,
                // }
                obj.status = 1;
                result = obj;
            } else if (type == 6) {
                obj.status = 1;
                result = obj;
                // result = {
                //     desc: obj.title,
                //     subheading: obj.subheading,
                //     color: obj.color,
                //     color_end: obj.color_end,
                //     status: 1,
                //     list: obj.content.goodsList
                // }
            }
            // console.log(type, '-----type')
            // console.log(obj, '-----obj')
            // console.log(result, '-----result')
            return result;
        }
    }
}
</script>

<style lang="less" scoped>
.floor {
    width: 1200px;
    margin: 20px auto 0;
    border-radius: 10px;
}
</style>