<template>
  <div class="bigbox d-flex flex-column justify-content-center align-items-center">
    <div class="d-flex">
      <img src="./images/smc.png" alt="图片已丢失">
    </div>
    <div class="d-flex justify-content-between center_box" style="margin-top: 10px;">
      <div class="d-flex" style="background-color: #FFFFFF;">
        <el-tabs v-model="tabsMark" :tab-position="tabPosition" style="height: 764px;width: 206px;">
          <el-tab-pane label="商城公告" name="0"></el-tab-pane>
          <el-tab-pane label="消息通知" name="1"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 商城公告 -->
      <div v-if="tabsMark == 0" class="d-flex flex-grow-1 flex-column background_box">
        <div class="d-flex justify-content-between for_box" v-for="item in list" :key="item.id">
          <div class="d-flex flex-column justify-content-between">
            <div class="d-flex flex-column">
              <div class="name">{{ item.name }}</div>
              <div class="synopsis">{{ item.synopsis }}</div>
            </div>
            <div class="publish_time">发布时间：{{ item.publish_time }}</div>
          </div>
          <div v-if="item.image" class="d-flex align-items-center notice_poster">
            <el-tooltip class="item" effect="dark" content="点击图片可预览大图" placement="left-start">
              <el-image style="width: 204px; height: 162px" :src="$getImageUri(item.image)" :preview-src-list="[item.image]">
              </el-image>
            </el-tooltip>
          </div>
        </div>
        <div v-if="list.length > 0" class="notice_page_info">
          <el-pagination :current-page="noticePageInfo.index" :page-size="noticePageInfo.size" @current-change="noticePageChange" layout="total, prev, pager, next, jumper" :total="noticePageInfo.total">
          </el-pagination>
        </div>
        <NoData v-else></NoData>
      </div>
      <!-- 消息通知 -->
      <div v-if="tabsMark == 1" class="background_box">
        <div class="d-flex flex-column justify-content-center for_popup" v-for="item in noticelists" :key="item.id">
          <div class="d-flex justify-content-between align-items-center Internal">
            <div class="d-flex align-items-center">
              <div class="circular">
              </div>
              <div class="item_title">
                {{ item.title }}
              </div>
            </div>
            <div class="create_time">
              {{ item.create_time }}
            </div>
          </div>
          <div class="d-flex align-items-center item_content">
            {{ item.content }}
          </div>
        </div>
        <div v-if="noticelists.length > 0" class="notice_page_info">
          <el-pagination :current-page="noticePageInfo.index" :page-size="noticePageInfo.size" @current-change="noticePageChange" layout="total, prev, pager, next, jumper" :total="noticePageInfo.total">
          </el-pagination>
        </div>
        <NoData v-else></NoData>
      </div>
    </div>
  </div>
</template>

<script>
import { Getshop_noticelists, Getnoticelists, getNotReadNotice } from "@/api";
export default {
  name: 'smc-index',
  data() {
    return {
      list: [],
      tabPosition: 'left',
      // tabs导航标识 0-商城公告 1-消息通知
      tabsMark: 0,
      // 消息通知列表
      noticelists: [],
      // 消息通知分页信息
      noticePageInfo: {
        index: 1,
        size: 3,
        total: 0
      }
    }
  },
  mounted() {
    this.$bus.$on('select_notice_tabs', () => {
      this.tabsMark = '1'
    })
  },
  beforeDestroy() {
    this.$bus.$off('select_notice_tabs');
  },
  watch: {
    tabsMark: {
      handler(newVal) {
        this.noticePageInfo.index = 1
        if (newVal == 1) {
          this.noticePageInfo.size = 10
          this.Getnoticelists()
        } else {
          this.noticePageInfo.size = 3
          this.Getshop_noticelists()
        }
      }
    }
  },
  methods: {
    // 商城咨询
    async Getshop_noticelists() {
      let datalist = await Getshop_noticelists();
      if (datalist.code == 1) {
        this.noticelists = datalist.data.lists;
        this.noticePageInfo.total = datalist.data.count
        this.list = datalist.data.lists;
      }
    },
    // 消息中心列表
    async Getnoticelists() {
      let noticeParams = {
        type: 'system',
        page_no: this.noticePageInfo.index,
        page_size: this.noticePageInfo.size
      }
      let datalist = await Getnoticelists(noticeParams);
      if (datalist.code == 1) {
        this.noticelists = datalist.data.lists;
        this.noticePageInfo.total = datalist.data.count
        this.getNotRead()
      }
    },
    // 获取未读消息通知
    getNotRead() {
      getNotReadNotice().then((res) => {
        let number = res.data.find(i => i.type == 'system').syetem_count
        if (number) this.$store.commit('setNotReadNumber', number)
      })
    },
    // 消息通知分页change事件
    noticePageChange(page) {
      this.noticePageInfo.index = page
      if (this.tabsMark == 0) {
        this.Getshop_noticelists()
      } else {
        this.Getnoticelists()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.background_box {
  width: 100%;
  margin-left: 10px;
  background-color: #ffffff;
  padding: 10px 10px;
}

.bigbox {
  z-index: 1;
  position: relative;
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.center_box {
  width: 100%;
}

.for_box {
  width: 100%;
  border-bottom: 1px solid #e5e6eb;
  padding: 10px 10px;
}

.name {
  font-size: 16px;
  color: #1d2129;
  font-weight: normal;
  margin-bottom: 20px;
}

.synopsis {
  font-size: 14px;
  color: #4e5969;
}

.publish_time {
  font-size: 14px;
  color: #86909c;
}

.notice_poster {
  cursor: pointer;
  margin-right: 10px;
}

.for_popup {
  margin: 10px 10px 0px 10px;
  border-bottom: 1px dashed #e5e6eb;
  padding-bottom: 10px;
}

.circular {
  height: 10px;
  width: 10px;
  background-color: #00b578;
  border-radius: 50%;
}

.item_title {
  color: #1d2129;
  font-size: 16px;
  margin-left: 5px;
}

.create_time {
  color: #86909c;
  font-size: 2px !important;
}

.item_content {
  margin-top: 10px;
  margin-left: 15px;
  font-size: 12px;
  color: #4e5969;
}

.notice_page_info {
  margin: 50px 10px;
}
</style>