// 首页商品列表
<template>
  <div class="self">
    <div v-show="isShowGoodsList && type_list.length > 0" class="d-flex flex-column" ref="goodsTabs">
      <div class="d-flex Drug_category justify-content-between align-items-center" :class="{ 'targetFixed': ifFixed }"
        style="{ overflowX: type_list.length >= 10 ? 'scroll' : 'hidden' }">
        <div @click=" selectedDivIndex = item.id" :tabindex="item.id" v-for="item in type_list" :key="item.id"
          :class="{ 'selected': selectedDivIndex == item.id }">
          {{ item.name }}
        </div>
      </div>
      <commodity v-if="tyoe_list.length > 0" :tyoe_list="tyoe_list" :isGoodsAdList="isGoodsAdList"
        :isShowAdImgindex="isShowAdImgindex" :pageInfo="pageInfo" @pageindex="goodsListPageChange($event)"></commodity>
      <NoData v-else></NoData>
    </div>
  </div>
</template>

<script>
import { GetClassificationList } from "@/api";
import { GetGoodslists } from "@/api";
export default {
  name: 'list-index',
  props: {
    isShowGoodsList: {
      type: [Number, String],
      default: 0
    },
    // 首页商品列表广告图
    isGoodsAdList: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      pageInfo: {
        index: 1,
        size: 20
      },
      selectedDivIndex: '', // 选中索引
      list: [],
      // 是否吸顶
      ifFixed: false,
      // 目标元素与整个文档顶部间的距离
      targetTop: 0,
      type_list: [],
      activeName: 'Cold_cough_relief',
      num: 1, // 计数器
      tyoe_list: [],
      // 商品分类tabs距离顶部距离
      scrollTop: 0,
      isDomScrollTop: 0,
      // 首页商品列表展示广告图索引值
      isShowAdImgindex: 0
    }
  },
  watch: {
    selectedDivIndex() {
      if (this.ifFixed) {
        let getRefs = this.$refs.goodsTabs;
        window.scrollTo({
          top: getRefs.offsetTop + 58,
          behavior: 'smooth'
        })
      }

      this.pageInfo.index = 1
      this.GetGoodslists()
    },
    scrollTop: {
      handler(newVal) {
        if (newVal <= 130) {
          this.ifFixed = true;
        } else {
          this.ifFixed = false;
        }
      }
    }
  },
  mounted() {
    this.GetClassificationList();
    this.GetGoodslists();

    window.addEventListener("scroll", this.scrolling)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
  },
  methods: {
    many_more() {
      this.$isRouterParams('ValueExchange')
    },
    scrolling() {
      let getRefs = this.$refs.goodsTabs;
      let nodeInfo = getRefs.getBoundingClientRect();
      this.scrollTop = nodeInfo.top;
    },
    async GetClassificationList() {
      let datalist = await GetClassificationList();
      this.type_list = datalist.data;
      this.type_list.unshift({
        id: '',
        name: '全部分类'
      })
    },
    async GetGoodslists() {
      let params = {
        category_id: this.selectedDivIndex,
        page: this.pageInfo.index,
        page_size: this.pageInfo.size
      }
      let lists = await GetGoodslists(params);
      this.tyoe_list = lists.data.data;
      this.pageInfo.index = lists.data.page_no;
      this.pageInfo.size = lists.data.page_size;
      this.pageInfo.total = lists.data.count;
    },
    goodsListPageChange(page) {
      if (this.ifFixed) {
        let getRefs = this.$refs.goodsTabs;
        window.scrollTo({
          top: getRefs.offsetTop + 58,
          behavior: 'smooth'
        })
      }

      this.pageInfo.index = page
      this.GetGoodslists()
    }
  }
}
</script>

<style lang="less" scoped>
/* element  ui*/
/deep/.el-input-number {
  width: 90px;
}

/deep/.el-tabs__item.is-active {
  color: #1d2129;
}

/deep/.el-tabs__item:hover {
  color: #1d2129;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
  font-size: 18px;
  border-left: none;
}

/deep/.el-tabs__nav {
  /* background-color: #FFFFFF; */
  padding: 0px 0px 0px 30px;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: none;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: linear-gradient(90deg,
      #ea5049 0%,
      rgba(216, 216, 216, 0) 100%);
}

/* 普通样式 */
.self {
  margin-top: 20px !important;
  width: 1200px;
  margin: 0 auto;

  .Value_Exchange {
    height: 50px;
    width: 100%;
  }

  .big_box {
    width: 100%;
    margin-top: 20px;
  }

  .for_box {
    margin: 0px 0px 0px 10px;
    width: 221px;
    height: 434px;
    background-color: #ffffff;
    border-radius: 5px;

    .goods_name {
      font-family: PingFangSC-Medium;
      font-weight: normal;
      margin-left: 10px;
      color: #1d2129;
      font-size: 16px;
      word-wrap: break-word;
      word-break: normal;
    }
  }
}

.company {
  margin: 5px 0px 0px 10px;
  color: #4e5969;
  font-size: 14px;
}

.spec {
  margin: 5px 0px 0px 10px;
  font-size: 14px;
  color: #4e5969;
}

.exchange_price {
  margin: 5px 0px 0px 10px;
  font-size: 14px;
  color: #1d2129;
}

.sup_div {
  margin: 5px 0px 0px 10px;
}

.Supplyprice {
  color: #4e5969;
  font-size: 12px;
}

.inpackage {
  border: 1px solid #ff334c;
  width: 41px;
  height: 16px;
  border-radius: 5px;
  background-color: #ffeeef;
  font-size: 10px;
  color: #ff334c;
}

.Package {
  color: #1d2129;
  font-size: 10px;
}

.period_of_validity {
  margin: 5px 0px 0px 10px;
}

.Post_coupon_price {
  margin: 10px 0px 0px 10px;
  font-size: 12px;

  .coupon_price {
    color: #ff334c;
  }
}

.Ultra_Low_Swap {
  margin-left: 15px;
  color: #1d2129;
  font-size: 24px;
}

.Ultra_low_price {
  color: #4e5969;
  font-size: 14px;
  margin-left: 20px;
}

.Exchange {
  color: #4e5969;
  font-size: 14px;
  margin-left: 10px;
}

.specialoffer_title {
  width: 100%;
  margin-left: 5px;

  .specialoffer {
    border: 1px solid #ff334c;
    width: 34px;
    height: 20px;
    border-radius: 5px;
    background-color: #ffeeef;
    font-size: 12px;
    color: #ff334c;
  }
}

.Drug_category,
.targetFixed {
  width: 1200px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px 10px 10px;
  z-index: 99;
  white-space: nowrap;

  div {
    cursor: pointer;
    font-size: 18px;
    color: #86909c;
    margin-right: 50px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.Drug_category {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
}

.targetFixed {
  position: fixed;
  top: 93px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
}

.first_box {
  background-color: #ffffff;
}

.type_box {
  margin-top: 20px;
  background-color: #ffffff;
}

.selected {
  color: #1d2129 !important;
  border-bottom: 2px solid #ff334c;
  padding: 10px 0;
}

.many_more {
  color: #4e5969;
  font-size: 14px;
  cursor: pointer;
}
</style>