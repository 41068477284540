<!-- 我的收藏 -->
<template>
  <div class="big_box bg-white d-flex flex-column">
    <div class="d-flex MyCollection">
      商品收藏列表
    </div>
    <div v-if="list.length > 0" class="item_box">
      <div class="for_box d-flex align-items-center justify-content-between" v-for="(item, index) in list" :key="index">
        <div class="d-flex" style="margin-left: 20px;">
          <div>
            <img style="width: 75px;height: 75px;" :src="$getImageUri(item.image)" alt="抱歉图片加载失败" />
          </div>
          <div class="d-flex flex-column justify-content-center">
            <div class="capsule">{{ item.name }}</div>
            <div class="first">¥{{ item.min_price }}</div>
          </div>
        </div>
        <div class="d-flex">
          <div @click="toGoodsDetail(item)" class="Go_buy d-flex align-items-center justify-content-center">商品详情</div>
          <div @click="cancellation(item)" class="Cancel_Favorite d-flex align-items-center justify-content-center">取消收藏
          </div>
        </div>
      </div>
    </div>
    <NoData v-else></NoData>
    <div class="notice_page_info">
      <el-pagination :current-page="pageInfo.index" :page-size="pageInfo.size" @current-change="pageInfoChange" layout="total, prev, pager, next, jumper" :total="pageInfo.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Getcollectlists, GetcollecthandleCollectgoods } from "@/api";
export default {
  name: 'MyCollection',
  data() {
    return {
      list: [],
      pageInfo: {
        index: 1,
        size: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.Getcollectlists();
  },
  methods: {
    pageInfoChange(page) {
      this.pageInfo.index = page
      this.Getcollectlists();
    },
    // 商品收藏列表
    async Getcollectlists() {
      let reqObj = {
        page_no: this.pageInfo.index,
        page_size: this.pageInfo.size
      }
      let data = await Getcollectlists(reqObj);
      this.list = data.data.lists;
      this.pageInfo.total = data.data.count
    },
    // 取消收藏操作
    async cancellation(item) {
      let params = {
        is_collect: 0,
        code: item.code
      }
      let datalist = await GetcollecthandleCollectgoods(params);
      this.Getcollectlists()
    },
    // 去购买跳转详情页
    toGoodsDetail(item) {
      this.$isRouterParams('ProductDetails', { data: JSON.stringify(item) })
    }
  }
}
</script>
<style lang="less" scoped>
.big_box {
  width: 980px !important;
  height: calc(100vh - 185px - 140px);
  overflow-y: auto;
  overflow-x: hidden;

  .item_box {
    height: 100vh;
    overflow-y: auto;
  }

  .MyCollection {
    width: 95%;
    margin: 0 auto;
    padding: 10px 0px 0px 0px;
    height: 30px;
    border-bottom: 1px solid #e5e6eb;
    color: #1d2129;
    font-size: 16px;
  }

  .for_box {
    padding: 20px 0px 20px 0px;
    width: 95%;
    margin: 0 auto;
    border-bottom: 1px dashed #e5e6eb;
    .capsule {
      margin-left: 10px;
      color: #1d2129;
      font-size: 14px;
    }

    .first {
      margin: 10px 0px 0px 10px;
      font-size: 14;
      font-weight: 400;
      color: #ff334c;
    }

    .Go_buy {
      cursor: pointer;
      width: 100px;
      height: 30px;
      border: 1px solid #ff334c;
      font-size: 14px;
      color: #ff334c;
    }

    .Cancel_Favorite {
      cursor: pointer;
      margin: 0px 20px 0px 10px;
      width: 100px;
      height: 30px;
      border: 1px solid #c9cdd4;
      font-size: 14px;
      color: #1d2129;
    }
  }
}

.notice_page_info {
  margin: 20px 40px;
}
</style>