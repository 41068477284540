<template>
  <div class="d-flex flex-column box">
    <div class="dre_box d-flex align-items-center justify-content-between">
      <div class="dre">药品推荐</div>
      <div class="Change_batch" @click="Getgoodsrecommand">
        <i class="el-icon-refresh-right"></i>
        换一批
      </div>
    </div>
    <commodity :tyoe_list="datalist"></commodity>
    <NoData v-if="!datalist.length"></NoData>
  </div>
</template>

<script>
import NoData from '@/components/NoData/index';
import { Getgoodsrecommand } from "@/api";
export default {
  components: {
    NoData
  },
  name: 'recommand',
  data() {
    return {
      datalist: [],
    }
  },
  mounted() {
    this.Getgoodsrecommand();
  },
  methods: {
    // 商品推荐列表
    async Getgoodsrecommand() {
      let data = await Getgoodsrecommand();
      if (data.code == 1) {
        this.datalist = data.data;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  background-color: #ffffff;
}

.dre {
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  color: #1d2129;
  font-weight: bold;
}

.Change_batch {
  cursor: pointer;
  margin-right: 10px;
  color: #4e5969;
  font-size: 12px;
}

.dre_box {
  margin: 20px 0px 0px 0px;
}
</style>