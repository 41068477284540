<!-- 我的优惠券 -->
<template>
  <div class="big_box bg-white d-flex flex-column">
    <div class="d-flex">
      <el-tabs v-model="couponStatus" type="card">
        <el-tab-pane label="未使用" name="0"></el-tab-pane>
        <el-tab-pane label="已使用" name="1"></el-tab-pane>
        <el-tab-pane label="已过期" name="2"></el-tab-pane>
        <!-- <el-tab-pane label="已作废" name="3"></el-tab-pane> -->
      </el-tabs>
    </div>
    <div v-if="list && list.length > 0" class="is_coupon">
      <voucher @ToRefresh="ToRefresh" :list="list" :type="type" :isDisabled="isDisabled"></voucher>
    </div>
    <NoData v-else></NoData>
  </div>
</template>

<script>
import { Getmycoupon } from "@/api";
import NoData from '@/components/NoData/index';
export default {
  name: 'mycoupon',
  components: {
    NoData
  },
  data() {
    return {
      list: [],
      type: '',
      couponStatus: 0,
      isDisabled: false
    }
  },
  mounted() {
    this.isGetMyCouponList()
  },
  watch: {
    couponStatus(newVal) {
      if (newVal == 1 || newVal == 2) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
      this.isGetMyCouponList()
    }
  },
  methods: {
    ToRefresh() {
      this.isGetMyCouponList()
    },
    isGetMyCouponList() {
      Getmycoupon({ status: this.couponStatus }).then((res) => {
        this.list = res.data.lists
      }).catch((err) => {
        console.log(err, '------获取我的优惠券列表api---error')
        this.$message({
          type: 'warning',
          offset: this.$store.state.config.outer_height,
          message: err
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.el-tabs__item.is-active {
  color: #ff334c;
  border-bottom: 1px solid #ff334c !important;
}

/deep/.el-tabs__item:hover {
  color: #ff334c;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  /* color: #000000; */
  font-size: 18px;
  border-left: none;
}

/deep/.el-tabs__nav {
  background-color: #ffffff;
  padding: 0px 0px 0px 30px;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
  border-bottom: 1px solid #e5e6eb;
}

.big_box {
  width: 980px !important;
  height: calc(100vh - 185px - 140px);
  overflow-y: auto;
  overflow-x: hidden;
}

.is_coupon {
  margin-left: 20px;
}
</style>