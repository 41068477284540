//登录注册的模块
import { reqGetCode, reqUserLogin, reqLogout } from '@/api'
import CryptoJS from "crypto-js"
import router from '@/router'

const state = {
  code: '',
  token: window.localStorage.getItem('login_token') || '',
  userInfo: {},
  company_data: {}
}
const mutations = {
  GETCODE(state, code) {
    state.code = code
  },
  // 登录信息和token一块存
  USERLOGIN(state, list) {
    state.token = list.token
    state.userInfo = list

    let tokenEnp = CryptoJS.AES.encrypt(JSON.stringify(list.token), 'tokenKey').toString()
    window.localStorage.setItem('login_token', tokenEnp)

    let cipherText = CryptoJS.AES.encrypt(JSON.stringify(list), 'loginlistKey').toString()
    window.localStorage.setItem('loginlist', cipherText)
  },
  // 店铺信息
  company(state, object) {
    state.company_data = object

    let companyEnp = CryptoJS.AES.encrypt(JSON.stringify(object), 'companyKey').toString()
    window.localStorage.setItem('company_data', companyEnp)
  },
  //清除本地数据
  CLEAR(state) {
    state.token = ''
    state.userInfo = {}
    state.company_data = {}

    localStorage.removeItem('login_token')
    localStorage.removeItem('loginlist')
    localStorage.removeItem('company_data')
    localStorage.removeItem('check_time')

    router.push('./')
  }
}

const actions = {
  //获取验证码
  async getCode({ commit }, phone) {
    let result = await reqGetCode(phone)
    if (result.code == 1) {
      // commit("GETCODE", result.data);
      return new Promise((resolve) => {
        resolve(result)
      })
    } else {
      return Promise.reject(new Error())
    }
  },

  //登录业务
  async userLogin({ commit }, data) {
    let result = await reqUserLogin(data)
    if (result.code == 1) {
      let objet = {}
      if (result.data.company.company_sn != "") {
        commit('USERLOGIN', result.data)
      }
      return new Promise((resolve) => {
        resolve(result)
      })
    } else {
      return Promise.reject(new Error(result.msg))
    }
  },
  //退出登录
  async userLogout({ commit }) {
    let result = await reqLogout()
    if (result.code == 1) {
      commit('CLEAR')
      return new Promise((resolve) => {
        resolve(result)
      })
    } else {
      return Promise.reject(new Error('退出登录失败！'))
    }
  }
}

const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}
