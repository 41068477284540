import { Editcartchange, Getcartlist } from '@/api'
export default {
  data() {
    return {
      // true-加 false-减
      is_add: true,
      // 购物车列表
      cartlist: [],
      // 步进器key标识符
      inputNumberKey: 0,
      // 消息提示距离顶部位置
      outerHeight: 20,
      // 当前商品信息对象
      goodsInfoObj: {},
      // 步进器上一步操作值
      inputOldValue: 0,
      // 最新活动sn
      isNewActivitySn: '',
      // 手动输入标识
      isStepperFocusMark: false
    }
  },
  computed: {
    priceName(activitySn) {
      return function (activitySn) {
        if (activitySn && activitySn != -1) {
          return '活动价：'
        } else {
          return '券后价：'
        }
      }
    },
    isCheckShowQuota(item) {
      return function (item) {
        let arrLengthCheck = item.step_price && item.step_price.length > 0
        let firstCheck = !item.activity_sn && arrLengthCheck
        let secondCheck = item.activity_sn == -1 && arrLengthCheck
        return firstCheck || secondCheck
      }
    }
  },
  methods: {
    // 中包or件装切换
    middleOrLargePackage(mark, item) {
      if (mark == 0) {
        item.large_package_status = false
        item.middle_package_status = !item.middle_package_status
        if (item.middle_package_status) {
          item.step_number = item.middle_package_number
        } else {
          item.step_number = item.mini_buy_number
        }
      } else if (mark == 1) {
        item.middle_package_status = false
        item.large_package_status = !item.large_package_status
        if (item.large_package_status) {
          item.step_number = item.large_package_number
        } else {
          item.step_number = item.mini_buy_number
        }
      } else {
        item.step_number = item.mini_buy_number
        item.middle_package_status = false
        item.large_package_status = false
      }

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    handleInput(value) {
      if (value > this.prevCount) {
        // 加
        this.is_add = true
      } else if (value < this.prevCount) {
        // 减
        this.is_add = false
      }
      this.prevCount = value // 更新 prevCount 值
    },
    // 步进器输入框获焦
    stepperFocus() {
      this.isStepperFocusMark = true
    },
    // 商品加入购物车验证
    changenum(currentValue, oldValue, data) {
      this.$forceUpdate()
      console.log(currentValue, oldValue, data, '------商品加入购物车信息')

      this.goodsInfoObj = data
      this.inputOldValue = oldValue

      // 值<=0停止操作
      if (oldValue <= 0 && currentValue <= 0) {
        this.$nextTick(() => {
          data.goods_num = 0
          this.inputNumberKey++
          this.$forceUpdate()
        })

        return false
      }

      // 判断最小购买数
      if (
        currentValue > 0 &&
        currentValue < data.mini_buy_number &&
        this.is_add
      ) {
        data.step_number = currentValue = data.mini_buy_number
        this.$nextTick(() => {
          this.goodsInfoObj.goods_num = data.mini_buy_number
          this.inputNumberKey++
          this.$forceUpdate()
        })

        this.$message({
          type: 'warning',
          offset: this.$store.state.config.outer_height,
          message: `当前商品最小购买数为${data.mini_buy_number}`
        })
      }

      // 判断是否为步进值的倍数
      let rd = currentValue % data.step_number
      if (rd != 0 && this.isStepperFocusMark) {
        this.updateInputNumber()

        return this.$message({
          type: 'warning',
          offset: this.$store.state.config.outer_height,
          message: `输入值必须是${data.step_number}的倍数`
        })
      }

      // 判断活动库存
      if (data.activity_sn && data.activity_stock) {
        if (currentValue > data.activity_stock) {
          this.updateInputNumber()

          return this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: `抱歉, 当前活动库存不足, 总库存：${data.activity_stock}`
          })
        }
        // 判断普通库存
      } else if (!data.activity_sn && data.stock) {
        if (currentValue > data.stock) {
          this.updateInputNumber()

          return this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: `抱歉, 当前库存不足, 总库存：${data.stock}`
          })
        }
      } else {
        // 默认判断普通库存
        if (currentValue > data.stock) {
          this.updateInputNumber()

          return this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: `抱歉, 当前库存不足, 总库存：${data.stock}`
          })
        }
      }

      // 判断是否限购
      if (data.activity_sn && data.activity_sn != -1 && data.activity_list) {
        this.is_check_quota(currentValue, data, 1)
        // 普通商品限购
      } else if ((!data.activity_sn || data.activity_sn == -1) && data.quota) {
        this.is_check_quota(currentValue, data, 0)
      } else {
        this.requestadd_card(currentValue, data)
      }

      // 判断阶梯价
      if (data.activity_sn == '' || data.activity_sn == -1) {
        if (data.step_price && data.step_price.length > 0) {
          let notInScopeMark = 0
          data.step_price.forEach((item) => {
            if (
              currentValue >= Number(item.start_num) &&
              currentValue <= Number(item.end_num)
            ) {
              data.sell_price = item.price
            } else {
              notInScopeMark++
              if (notInScopeMark == data.step_price.length) {
                data.sell_price = data.goods_price
              }
            }
          })
        }
      }
    },
    // mark为1调用接口，为0不操作
    is_check_quota(currentValue, data, mark) {
      let quota = {}
      let findQuota = {}

      if (data.activity_list) {
        if (data.activity_list.quota) {
          quota = data.activity_list.quota
        } else if (data.activity_list[data.activity_sn]) {
          if (data.activity_list[data.activity_sn].quota)
            quota = data.activity_list[data.activity_sn].quota
        } else if (data.activity_list.length > 0) {
          findQuota = data.activity_list.find((i) => i.sn == data.activity_sn)
          if (findQuota && findQuota.quota) quota = findQuota.quota
        }
      } else {
        quota = data.quota
      }

      if (currentValue > quota.quota_number) {
        if (quota.quota_type == 3) {
          // 获取今天的时间
          let time = new Date(new Date()).getTime()
          let start_time = new Date(quota.quota_start_time).getTime()
          let End_time = new Date(quota.quota_end_time).getTime()
          if (time >= start_time && time <= End_time) {
            this.updateInputNumber()

            this.$message({
              type: 'warning',
              offset: this.$store.state.config.outer_height,
              message: `当前商品在${quota.quota_start_time}-${quota.quota_end_time}时间段限购数量为: ${quota.quota_number}`
            })

            if (mark == 1 && data.activity_sn) return this.Getconfim(data)
          }
        } else {
          this.updateInputNumber()
          this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: `当前商品限购数量为:${quota.quota_number}`
          })

          if (mark == 1 && data.activity_sn) return this.Getconfim(data)
        }
      } else {
        this.requestadd_card(currentValue, data)
      }
    },
    // 限购确认模态框
    Getconfim(data) {
      this.$confirm(
        `当前商品已限购, 点击确定将以原价再次购买此商品`,
        '温馨提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          Getcartlist().then((res) => {
            let findGoods = res.data.find(
              (i) =>
                i.goods_code == data.code &&
                (!i.activity_sn || i.activity_sn == -1)
            )

            if (findGoods) {
              return this.$message({
                type: 'warning',
                offset: this.$store.state.config.outer_height,
                message: '购物车已有此商品, 请前往购物车查看'
              })
            } else {
              this.isNewActivitySn = -1
              if (data.step_number < data.mini_buy_number) {
                this.requestadd_card(data.mini_buy_number, data)
              } else {
                this.requestadd_card(data.step_number, data)
              }
            }
          })
        })
        .catch(() => {
          console.log('无操作')
        })
    },
    /**
     * 更新步进器输入框内值
     */
    updateInputNumber() {
      this.$nextTick(() => {
        this.goodsInfoObj.goods_num = this.inputOldValue
        if (this.cartNumber) this.cartNumber = this.inputOldValue
        this.inputNumberKey++
        this.$forceUpdate()
      })
    },
    // 修改购物车api
    requestadd_card(currentValue, data) {
      this.isNewActivitySn = this.isNewActivitySn
        ? this.isNewActivitySn
        : data.activity_sn

      let params = {
        goods_code: data.code,
        goods_num: currentValue,
        activity_sn: this.isNewActivitySn,
        is_activity_goods: data.is_activity_goods
      }
      console.log('加入购物车参数', params)

      Editcartchange(params)
        .then((res) => {
          console.log(res, '------修改购物车数量api---success')

          if (data.goods_num <= 0) {
            data.goods_num = 0
            data.sell_price = data.goods_price
          }
          // 更新购物车角标
          this.$store.dispatch('getShopCart')
          this.$emit('Getupdate')

          this.isNewActivitySn = ''
        })
        .catch((err) => {
          console.log(err, '------修改购物车数量api---error')
        })
    },
    // 活动商品加入购物车
    async Edit_shop(data) {
      let params = {
        goods_code: data.code, // 优惠券的sn
        goods_num: 1, // 活动商品默认1
        activity_sn: data.activity_sn, // 不参加任何活动时，传入-1；优惠券时，传入活动sn
        is_activity_goods: data.is_activity_goods
      }
      let data_ = await Editcartchange(params)
      this.$emit('close')
    },
    Getpickcolor(data) {
      switch (data) {
        case '热销':
          return 'border: 1px solid #ff334c;color: #ff334c;'
        case '凑单':
          return 'border: 1px solid #ff8f1f;color: #ff8f1f;'
        case '赠品':
          return 'border: 1px solid #0082dc;color: #0082dc;'
        case '新品':
          return 'border: 1px solid #00b578;color: #00b578;'
        case '秒杀':
          return 'color: #fff;background: linear-gradient(90deg, #ff334c 0%, #ff8f1f 100%);'
        case '换购':
          return 'color: #8a38f5;border: 1px solid #8a38f5;'
        default:
          break
      }
    }
  }
}
