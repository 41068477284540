<!--页面最顶部组件-->
<template>
  <div class="header d-flex flex-column">
    <div class="d-flex flex-grow-1">
      <div class="flex-grow-1 d-flex login_" v-if="$route.path == '/Login'" style="border: 1px solid red;">
        <img src="./images/logintop.png">
      </div>
      <div class="flex-grow-1 d-flex flex-column" :class="isScrollBarDfTop >= 30 ? 'navigation_fixed' : 'navigation_'"
        v-else>
        <!-- 顶部logo&搜索框 -->
        <div v-if="isScrollBarDfTop >= 30" class="d-flex justify-content-between Ecommerce">
          <div title="点击回到首页" @click="toHome">
            <img src="./images/logintop.png" alt="抱歉图片加载失败">
          </div>
          <div>
            <div class="d-flex">
              <el-input v-model="searchInput" class="search_input" placeholder="请输入要搜索的商品名称"
                @keyup.enter.native="Search_click" @input="searchInputChange" clearable></el-input>
              <el-button class="search_butoon d-flex justify-content-center" type="primary"
                @click="Search_click">搜索</el-button>
            </div>
          </div>
        </div>
        <!-- 顶部操作栏 -->
        <div class="d-flex Ecommerce">
          <div class="d-flex flex-grow-1 align-items-center scroll_notice">
            <img v-if="isScrollBarDfTop >= 30" src="./images/notice_dark.png" alt="抱歉图片加载失败">
            <img v-else src="./images/notice_light.png" alt="抱歉图片加载失败">
            <ul class="marquee_list" :class="{ marquee_top: animate }" @click="$router.push({ path: './smc' })">
              <li v-for="(item, index) in marqueeList" :key="index">{{ item.name }}</li>
            </ul>
          </div>
          <div class="d-flex justify-content-between align-items-center flex-grow-1 small_box">
            <label>您好，欢迎来到 先大拼药药！</label>
            <el-select @change="companyChange($event)" size="mini" v-model="shop_sn" placeholder="暂无店铺列表"
              style="width: 300px;">
              <el-option v-for="(item, index) in shoplist" :key="index" :label="item.company" :value="item.sn">
              </el-option>
            </el-select>
            <div @click="MyOrder">我的订单</div>
            <div @click="PersonalCenter">个人中心</div>
            <div class="container">
              <div class="content" @click="toNoticePage">
                消息通知
                <span v-if="$store.state.config.not_read_notice">(<span class="not_read_number">{{
                  $store.state.config.not_read_notice
                }}</span>)</span>
              </div>
            </div>
            <!-- 扫码下载 -->
            <div @click="Mobileterminal = !Mobileterminal"> 手机端下载
              <i :class="Mobileterminal ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
          </div>
          <div id="RCode" v-show="Mobileterminal"
            :class="isScrollBarDfTop >= 30 ? 'download_code_fixed' : 'download_code'">
            <div class="create_code d-flex justify-content-between align-items-center">
              <!-- 安卓系统下载 -->
              <div id="qrcode-android" class="android">
                <div class="download_tips">安卓系统</div>
              </div>
              <!-- 苹果系统下载 -->
              <div id="qrcode-ios" class="ios">
                <div class="download_tips">苹果系统</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/pages/Search/js/eventBus.js'
import { GetindexappIndex, GetusershopList, GetusershopSwitch, getConfig } from "@/api";
import store from '@/store'
import QRCode from 'qrcodejs2';
import CryptoJS from "crypto-js"
export default {
  name: "header-index",
  data() {
    return {
      shop_sn: '',
      displayPopup: false,
      Mobileterminal: false,
      shoplist: [],
      alldata: {},
      animate: false,
      marqueeList: [],
      isSystem: 0,
      isCompanyName: '',
      // 滚动条距离顶部距离
      isScrollBarDfTop: 0,
      searchInput: ''
    }
  },
  mounted() {
    window.addEventListener('scroll', this.isPageScroll)
    // 获取首页公告列表
    this.getIndexInfo()
    // 生成二维码
    this.Generate_QR_code()
    // 顶部店铺列表
    this.GetusershopList()

    if (this.$params.input) this.searchInput = this.$params.input
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.isPageScroll)
  },
  watch: {
    '$store.state.config.router_params': {
      handler(newVal) {
        this.searchInput = newVal.input
      },
      deep: true
    }
  },
  computed: {
    login_() {
      return this.$store.state.user.userInfo.name;
    }
  },
  methods: {
    isPageScroll() {
      this.isScrollBarDfTop = document.documentElement.scrollTop
    },
    toHome() {
      this.$isRouterParams('Home', { SecectIndex: 'Home' }, true)
    },
    searchInputChange() {
      this.$store.commit('setRouterParams', { input: this.searchInput })
    },
    Search_click() {
      if (!this.searchInput && this.$router.currentRoute.name != 'Search') return false

      eventBus.$emit('Search_input', this.searchInput);

      if (this.$router.currentRoute.name == 'Search') {
        this.$store.commit('setRouterParams', { input: this.searchInput })
        this.$router.push({ name: 'Search' })
      } else {
        this.$isRouterParams('Search', { input: this.searchInput })
      }
    },
    // 获取首页公告列表
    getIndexInfo() {
      GetindexappIndex().then(res => {
        if (res.data.shop_notice && res.data.shop_notice.list.length > 0) {
          this.marqueeList = res.data.shop_notice.list
          setInterval(this.showMarquee, 3000)
        }
      }).catch((err) => {
        console.log(err, '------首页公告列表api---error')
      })
    },
    showMarquee() {
      this.animate = true;
      setTimeout(() => {
        if (this.marqueeList[0]) this.marqueeList.push(this.marqueeList[0]);
        this.marqueeList.shift();
        this.animate = false;
      }, 500);
    },
    showPopup() {
      this.displayPopup = true;
    },
    hidePopup() {
      this.displayPopup = false;
    },
    Generate_QR_code() {
      let androidUrl = this.$store.state.config.downloadQRCode.androidUrl
      let iosUrl = this.$store.state.config.downloadQRCode.iosUrl

      new QRCode(document.getElementById('qrcode-android'), {
        text: androidUrl,
        width: 90,
        height: 90
      });
      new QRCode(document.getElementById('qrcode-ios'), {
        text: iosUrl,
        width: 90,
        height: 90
      });
    },
    // 店铺列表
    async GetusershopList() {
      GetusershopList().then((res) => {
        this.shoplist = res.data.shop
        let getNewCompany = window.localStorage.getItem('company_data')

        if (getNewCompany) {
          let listBytes = CryptoJS.AES.decrypt(getNewCompany, 'companyKey')
          getNewCompany = JSON.parse(listBytes.toString(CryptoJS.enc.Utf8))
          this.shop_sn = getNewCompany.sn
        } else {
          let select_data = res.data.shop.filter((item) => { return item.sn == this.$store.state.user.userInfo.company.company_sn })
          store.commit('company', select_data[0]);
          this.shop_sn = this.$store.state.user.company_data.sn
        }
      }).catch((err) => {
        console.log(err, '------获取店铺列表api---error')
        this.$message({
          type: 'error',
          offset: this.$store.state.config.outer_height,
          message: err
        })
      })
    },
    // 店铺切换
    async companyChange(companySn) {
      let findCompany = this.shoplist.find(i => i.sn == companySn)
      store.commit('company', findCompany)

      GetusershopSwitch({ shop_sn: this.shop_sn }).then((res) => {
        getConfig().then((res) => {
          console.log(res, '------获取应用配置api---success')
          this.$store.commit('setConfig', res.data)

        }).then(() => {
          this.$message({
            type: 'success',
            offset: 20,
            message: '切换店铺成功'
          })

          this.$router.go(0)
        })
          .catch((err) => {
            console.log(err, '------获取应用配置api---error')
            this.$message({
              message: '抱歉，暂时无法获取应用配置，请稍候重试',
              offset: 20,
              type: 'warning'
            })
          })
      }).catch((err) => {
        console.log(err, '------店铺切换api---error')
        this.$message({
          type: 'error',
          offset: 20,
          message: err
        })
      })
    },
    // 跳转消息通知页面
    toNoticePage() {
      this.$router.push({ path: "./smc" })
      this.$nextTick(() => {
        this.$bus.$emit('select_notice_tabs');
      })
    },

    // 跳转我的订单
    MyOrder() {
      this.$router.push({ path: "./PersonalCenter" })
      this.$nextTick(() => {
        this.$bus.$emit('select_tabs', '1-1');
      })
    },

    // 跳转个人中心
    PersonalCenter() {
      this.$router.push({ path: "./PersonalCenter" })
      this.$nextTick(() => {
        this.$bus.$emit('select_tabs', '3-4');
      })
    }
  },
}
</script>

<style lang="less" scoped>
.marquee_list {
  position: absolute;
  top: 0;
  left: 22px;
  width: 60%;
}

.marquee_top {
  transition: all 0.5s;
  margin-top: -30px;
}

.marquee_list li {
  height: 30px;
  line-height: 30px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marquee_list li span {
  padding: 0 2px;
}

.header {
  z-index: 1000;
  margin: auto;
  position: fixed;
  cursor: pointer;
  top: 0;
  height: 30px;
  width: 100%;
}

.search_butoon {
  border: 1px solid #ff334c;
  background-color: #ff334c;
  height: 40px;
  width: 60px;
}

.search_input {
  width: 400px;
}

.Shopping_btn {
  position: relative;
  z-index: -1;
  border: 1px solid #ff334c;
  color: #ff334c;
  background-color: #ffeeef;
  height: 40px;
  width: 80px;
}

.login_ {
  margin-left: 80px;
  align-items: center;
}

.navigation_,
.navigation_fixed {
  font-size: 12px;
  align-items: center;
  padding: 5px 0;
  overflow: hidden;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.navigation_ {
  color: #ffffff;
  background-color: #222222;
}

.navigation_fixed {
  color: #222222;
  background-color: #ffffff;
}

.Ecommerce {
  width: 1200px;
  margin: 0 auto;

  .scroll_notice {
    position: relative;
    height: 30px;
    overflow: hidden;

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.small_box {
  position: relative;
  cursor: pointer;
}

.download_code,
.download_code_fixed {
  z-index: 99;
  position: absolute;
  right: 200px;
  color: #4e5969;
  padding: 10px 20px 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.1);

  .create_code {
    text-align: center;

    .android {
      margin-right: 50px;
    }

    .download_tips {
      margin: 5px 0;
    }
  }
}

.download_code {
  top: 40px;
}

.download_code_fixed {
  top: 100px;
}

.home_Title {
  margin-top: 20px;
}

.container {
  position: relative;
}

.content {
  width: 100%;
  height: 100%;
}

.not_read_number {
  color: #ff334c;
  margin: 0 2px;
}
</style>