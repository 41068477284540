<template>
  <div v-if="posterList.pc_status == 1 && posterList.list.length > 0" class="activity">
    <div v-for="(item, index) in posterList.list" :key="index" @click="$indexJumpClick(item)">
      <img class="activity_image" :src="$getImageUri(item.pc_img)" alt="抱歉图片加载失败" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'poster-index',
  props: {
    posterList: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="less">
.activity {
  width: 1200px;
  height: 240px;
  margin: 20px auto 0;

  .activity_image {
    width: 100%;
    height: 240px;
    border-radius: 10px;
  }
}
</style>