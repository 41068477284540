// 商品分类&轮播图
<template>
  <div class="list-container d-flex align-items-center flex-column">
    <div class="tab_btm flex-grow-1 d-flex">
      <!-- 首页商品分类列表 -->
      <div v-if="columnListObj && columnListObj.pc_status == 1 && columnListObj.list && columnListObj.list.length > 0"
        class="d-flex tab_left">
        <el-menu mode="vertical" default-active="2" class="el-menu-vertical-demo">
          <el-submenu active-text-color="#ffffff" v-for="(item, index) in columnListObj.list" :key="item.id"
            :index="index + item.name">
            <template slot="title">
              <div @click="Getclassification(item.id)"
                class="d-flex justify-content-center align-items-center justify-content-between">
                <i v-if="index == 0" style="color: #4E5969;" class="el-icon-menu"></i>
                <img v-else :src="$getImageUri(item.image)" alt="抱歉图片加载失败" style="height: 20px; width: 20px;" />
                <span style="color: #4E5969;">{{ item.name }}</span>
                <i style="color: #4E5969;" class="el-icon-arrow-right"></i>
              </div>
            </template>
          </el-submenu>
        </el-menu>
      </div>
      <!-- 首页轮播图 -->
      <div v-if="swiperListObj && swiperListObj.pc_status == 1 && swiperListObj.list && swiperListObj.list.length > 0"
        class="d-flex carousel flex-grow-1" ref="carousel">
        <el-carousel :interval="3000" arrow="always" :height="carouselHeight">
          <el-carousel-item v-for="item in swiperListObj.list" :key="item.name">
            <img :src="$getImageUri(item.pc_img)" :title="item.title" alt="抱歉图片加载失败" style="height:100%; width:100%;"
              mode="scaleToFill" @click="$indexJumpClick(item)" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "listContainer-index",
  props: {
    // 分类菜单列表
    columnListObj: {
      type: Object,
      default: () => { }
    },
    // 轮播图列表
    swiperListObj: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      // 轮播图高度
      carouselHeight: null
    }
  },
  updated() {
    // 获取div的ref=“carousel”的高度
    let carousel_height = window.getComputedStyle(this.$refs.carousel).height;
    // 赋值给 el-carousel中height动态绑定的carouselHeight
    this.carouselHeight = carousel_height
  },
  methods: {
    Getclassification(id) {
      this.$isRouterParams('gategory', { id: id })
    }
  }
}
</script>

<style lang="less" scoped>
/* element组件样式 */
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  border-radius: 10px;
}

/deep/ .el-icon-arrow-down:before {
  content: none !important;
}

/deep/.el-breadcrumb__inner {
  color: #000000;
}

/deep/.el-tabs__nav {
  background-color: #ff334c;
  padding: 0px 0px 0px 30px;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: none;
}

/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: red;
}

/deep/.el-tabs__item.is-active {
  color: #ff334c;
}

/deep/.el-tabs__item:hover {
  color: #ff334c;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
  /* color: #000000; */
  font-size: 18px;
  border-left: none;
}

/deep/.el-menu-item.is-active {
  background-color: #ff334c !important;
}

.list-container {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  cursor: pointer;
}

.top_index {
  font-size: 18px;
  padding: 0px 0px 0px 100px;
}

.tab_index {
  height: 40px;
  width: 100%;
}

.tab_btm {
  width: 100%;
}

.tab_btn {
  font-size: 18px;
  border: none;
  background-color: #ff334c;
  height: 40px;
  width: 200px;
}

.tab_left {
  width: 200px;
}

.el-carousel {
  width: 100%;
  border-radius: 10px;
}
</style>