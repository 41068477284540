<!-- 我的钱包 -->
<template>
  <div class="big_box bg-white d-flex flex-column">
    <div class="d-flex mywallet">
      我的钱包
    </div>
    <div class="d-flex wallet_card align-items-center">
      <div class="d-flex flex-column">
        <div class="available_label">可用余额(元)</div>
        <div class="available_money">¥{{all_data.user_money}}</div>
      </div>
      <div class="d-flex flex-column">
        <div class="available_label">可用代金券</div>
        <div class="available_money">¥{{all_data.user_promotion_money}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Getuserwallet } from "@/api";
export default {
  name: 'mywallet',
  data() {
    return {
      all_data: {},
    }
  },
  mounted() {
    this.Getuserwallet();
  },
  computed: {
  },
  methods: {
    handleClick(tab, event) {
    },
    // 超值换购列表
    async Getuserwallet() {
      let datalist = await Getuserwallet();
      this.all_data = datalist.data;
      console.log('123132132132', datalist)
    },
  }
}
</script>

<style lang="less" scoped>
.big_box {
  width: 980px !important;
  height: calc(100vh - 185px - 140px);
  overflow-y: auto;
  overflow-x: hidden;

  .mywallet {
    width: 95%;
    margin: 0 auto;
    padding: 10px 0px 0px 0px;
    height: 30px;
    border-bottom: 1px solid #e5e6eb;
    color: #1d2129;
    font-size: 16px;
  }

  .wallet_card {
    height: 110px;
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    background: linear-gradient(90deg, #ea4b43 0%, #f1a444 100%);
    border-radius: 5px;
    .available_label {
      margin: 0px 0px 0px 80px;
      font-size: 14px;
      color: #ffffff;
    }
    .available_money {
      margin: 20px 0px 0px 80px;
      font-size: 24px;
      color: #ffffff;
    }
  }
}
</style>