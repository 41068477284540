<template>
  <!-- 右侧边缘列表 -->
  <div class="d-flex justify-content-center container">
    <div class="RightEdge d-flex flex-column align-items-center justify-content-center"
      :style="{ top: isTop, right: '0px' }">
      <div class="d-flex align-items-center justify-content-center imglist " v-for="item in imglist" :key="item.id">
        <div v-if="item.id == '6'" class="hh" @click="rightclick(item.id)">
          <img :src="isTopStatus ? item.img : item.selImg" alt="图片已丢失">
        </div>
        <div v-else class="hh" @click="rightclick(item.id)">
          <img :src="item.img" alt="图片已丢失">
        </div>
        <div v-if="item.id == '1' && data_num > 0" class="subscript d-flex align-items-center justify-content-center">
          {{ Number(data_num) > 99 ? 99 + '+' : data_num }}</div>
      </div>
    </div>
    <!-- 联系客服弹窗 -->
    <div v-if="contact_giveservice"
      class="d-flex justify-content-center align-items-center flex-column contact_giveservice bg-white"
      :style="{ top: isTop, right: '0px' }">
      <div class=" d-flex justify-content-center align-items-center">
        <div class="square d-flex align-items-center justify-content-center">
          <img src="./images/phone.png" alt="图片已丢失" />
        </div>
        <div>联系电话：</div>
      </div>
      <div class="d-flex flex-column justify-content-center a">
        <div class="giveservice_text d-flex justify-content-center align-items-center">
          公司电话：{{ servicedata.company_mobile == null ? '暂无联系方式' : servicedata.company_mobile }}
        </div>
        <div class="giveservice_text d-flex justify-content-center align-items-center">
          业务员电话：{{ servicedata.seller_mobile == null ? '暂无联系方式' : servicedata.seller_mobile }}
        </div>
        <!-- <div style="width: 90%;margin: 0 auto;padding-top: 20px;"
          class="square_time d-flex flex-column justify-content-center align-items-center">
          <p>周一至周五</p>
          <p>9:00-19:00</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { GetsellerList } from "@/api"
import Vue from 'vue'
import store from '@/store'
export default {
  name: "Rightlist",
  data() {
    return {
      servicedata: {},
      contact_giveservice: false,
      imglist: [{
        img: require("./images/ShoppingCart.png"),
        id: '1'
      }, {
        img: require("./images/signIn.png"),
        id: '2'
      }, {
        img: require("./images/CouponCenter.png"),
        id: '3'
      }, {
        img: require("./images/MyCollection.png"),
        id: '4'
      }, {
        img: require("./images/customerService.png"),
        id: '5'
      }, {
        img: require("./images/backTop.png"),
        selImg: require("./images/backTopSelected.png"),
        id: '6'
      }],
      isTop: '400px',
      right: '250px',
      isTopStatus: true,
      isLeftStaus: true
    }
  },
  //组件挂载完毕，可以向服务器发送请求
  mounted() {
    this.isTop = window.outerHeight / 2 - 240 + 'px'
    if (window.innerWidth === screen.availWidth) {
      this.right = '250px';
    } else {
      this.right = '0px';
    }
    window.addEventListener('resize', () => {
      this.isTop = window.outerHeight / 2 - 240 + 'px'
      if (window.innerWidth === screen.availWidth) {
        this.right = '250px';
      } else {
        this.right = '0px';
      }
    })
    window.addEventListener('scroll', () => {
      this.isTopStatus = window.scrollY == 0
      this.isLeftStaus = window.scrollX == 0
    })
    //获取数据
    this.$store.dispatch('getShopCart');
    this.GetsellerList()
  },
  computed: {
    data_num() {
      return store.state.shopcart.shopCartInfo.length
    },
    rig() {
      if (this.right == '250px') {
        return '25px';
      } else {
        return '75px';
      }
    }
  },
  methods: {
    async GetsellerList() {
      let data = await GetsellerList()
      this.servicedata = data.data;
    },
    rightclick(type) {
      switch (type) {
        // 购物车
        case '1':
          this.$router.push({ path: "./ShoppingCart" })
          break;
        // 签到
        case '2':
          this.$router.push({ path: "./PersonalCenter" })
          this.$nextTick(() => {
            this.$bus.$emit('select_tabs', '2-3');
          })
          break;
        // 领券中心
        case '3':
          // 领劵中心
          this.$emit('getType', 'Securities');
          this.$isRouterParams('Couponcenter', { SecectIndex: 'Couponcenter' }, false)
          // this.$isRouterParams('Couponcenter')
          break;
        // 我的收藏
        case '4':
          this.$router.push({ path: "./PersonalCenter" })
          this.$nextTick(() => {
            this.$bus.$emit('select_tabs', '3-1');
          })
          break;
        // 联系客服
        case '5':
          this.contact_giveservice = !this.contact_giveservice;
          break;
        // 返回顶部
        case '6':
          // 将页面滚动到顶部
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          break;
      }
    },
  }
}
</script>

<style scoped lang="less">
.container {
  position: relative;
}

.RightEdge {
  z-index: 100;
  position: fixed;
  right: 250px;
  width: 70px;
  height: 440px;
  color: #4e5969;
  // background-color: #dfe0e5;
  background-color: #ffffff;
  border-radius: 10px;
}

.imglist {
  position: relative;
  cursor: pointer;
  width: 70px;
  height: 70px;

  .subscript {
    position: absolute;
    right: 10px;
    top: -6px;
    padding: 0 5px;
    font-size: 16px;
    color: #ffffff;
    border-radius: 50%;
    background-color: red;
  }
}

.contact_giveservice {
  position: fixed;
  // right: 75px;
  right: 20px;
  z-index: 100;
  border: 1px solid #dcdfe6;
  // padding: 10px 0px 0px 0px;
  padding: 10px 8px;

  .square {
    background: #f2f3f5;
    height: 25px;
    width: 25px;
    border-radius: 50%;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .square_time {
    margin-top: 10px;
    font-size: 12px;
    color: #1d2129;
    font-weight: 400;
  }

  .giveservice_text {
    margin-top: 10px;
    color: #4e5969;
    font-size: 16px;
  }
}
</style>