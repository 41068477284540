<template>
  <div class="big_box d-flex flex-column">
    <!-- 骨架屏组件 -->
    <el-skeleton v-for="item in 10" :key="item" :loading="isShowLoading" animated :throttle="500" />

    <!-- 商品分类&轮播图 -->
    <ListContainer :columnListObj="columnListObj" :swiperListObj="swiperListObj" />

    <!-- 专区广场 -->
    <Rank :isIncludesBigImg="isIncludesBigImg" :prefectureListObj="prefectureListObj" style="margin-top: 20px;" />

    <!-- 广告图 -->
    <Poster :posterList="posterList"></Poster>

    <!-- 超值换购 -->
    <Self :exchangeListObj="exchangeListObj" />

    <!-- 限时秒杀 -->
    <Seckill :seckillListObj="seckillListObj" />

    <!-- 品牌甄选 -->
    <Brand :brandSelectListObj="brandSelectListObj" />

    <!-- 动态楼层 -->
    <Floor :floorListObj="floorListObj" />

    <!-- 商品列表 -->
    <List :isShowGoodsList="isShowGoodsList" :isGoodsAdList="goodsAdList"></List>

    <!-- 首页广告弹窗 -->
    <el-dialog v-if="posterContent && posterContent.status == 1 && isShowPoster" :width="'600px'" center
      :modal-append-to-body="false" :show-close="false">
      <div class="poster_popup">
        <el-carousel indicator-position="none" height="600px">
          <el-carousel-item v-for="item in posterContent.list" :key="item.name">
            <img :src="$getImageUri(item.pc_img)" :title="item.title" alt="抱歉图片加载失败" @click="$indexJumpClick(item)" />
          </el-carousel-item>
        </el-carousel>
        <div class="today_checkbox">
          <input id="today-checked" :value="todayChecked" type="checkbox" @change="todayCheckboxChange" />
          <label for="today-checked">今日不再显示</label>
        </div>
        <div class="close_poster" @click="isShowPoster = false">
          <i class="el-icon-circle-close"></i>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dateFormat } from '@/utils/tools'
import { GetindexappIndex, apiVisit } from "@/api";
import ListContainer from '@/pages/Home/ListContainer'
import Rank from "@/pages/Home/Rank";
import Poster from "@/pages/Home/Poster";
import Self from "@/pages/Home/Self";
import Seckill from "@/pages/Home/Seckill";
import Brand from "@/pages/Home/Brand";
import List from "@/pages/Home/List";
import Floor from "@/pages/Home/Floor";
export default {
  name: 'home-index',
  components: {
    Self,
    Rank,
    Poster,
    ListContainer,
    Seckill,
    Brand,
    List,
    Floor
  },
  data() {
    return {
      // 是否显示骨架屏 
      isShowLoading: false,
      // 分类菜单列表
      columnListObj: {},
      // 轮播图列表 
      swiperListObj: {},
      // 专区是否包含大图
      isIncludesBigImg: false,
      // 专区广场
      prefectureListObj: {},
      // 广告图
      posterList: {},
      // 超值换购
      exchangeListObj: {},
      // 限时秒杀
      seckillListObj: {},
      // 品牌甄选
      brandSelectListObj: {},
      // 楼层
      floorListObj: {},
      // 是否显示商品列表
      isShowGoodsList: 0,
      // 商品列表广告图
      goodsAdList: {},
      // 首页广告弹窗
      posterContent: {},
      // 是否显示首页广告弹窗
      isShowPoster: false,
      // 今日不再显示复选框
      todayChecked: false
    }
  },
  created() {
    this.$version()
    apiVisit({ terminal: 4 }).catch((err) => {
      console.log(err, '------apiVisit---error')
    })

    let nowTime = window.localStorage.getItem('check_time')
    this.checkNowDate(nowTime)
  },
  mounted() {
    this.isShowLoading = true
    this.GetallData()
  },
  methods: {
    GetallData() {
      GetindexappIndex({ page_size: '5',is_pc:'1' }).then(res => {
        console.log(res, '------首页数据api---success')

        if (res.code == -1) {
          this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: res.msg
          })
          return this.$store.dispatch('userLogout');
        }

        // 分类菜单列表
        this.columnListObj = res.data.column
        this.columnListObj.list.unshift({
          id: '',
          name: '全部商品分类',
          image: './images/type_btn.png'
        })

        console.log(this.columnListObj, '-------columnListObj')
        // 轮播图列表
        this.swiperListObj = res.data.banner;
        console.log(this.swiperListObj, '-------swiperListObj')


        // 专区广场
        let isList = [...res.data.prefecture.list]
        if (isList && isList.length > 0) {
          let findRes = isList.find(i => i.is_show == 1)
          if (findRes) this.isIncludesBigImg = true
        }
        this.prefectureListObj = res.data.prefecture;

        // 广告图
        this.posterList = res.data.prefecture_ad

        // 超值换购
        this.exchangeListObj = res.data.exchange;

        // 限时秒杀
        this.seckillListObj = res.data.seckill;

        // 品牌甄选
        this.brandSelectListObj = res.data.brand_select

        // 是否显示商品列表
        this.isShowGoodsList = res.data.all_category.pc_status

        // 商品列表广告图
        this.goodsAdList = res.data.shop_ad

        // 首页广告弹窗
        this.posterContent = res.data.popup

        //动态楼层
        this.floorListObj = res.data.floor

        console.log(this.floorListObj, '-----this.floorListObj')

      }).catch(err => {
        console.log(err, '------首页数据api---error')
        this.isShowLoading = false
      }).finally(() => {
        this.$nextTick(() => {
          this.isShowLoading = false
        })
      })
    },
    // 今日不再显示时间验证
    checkNowDate(dateMs) {
      let nowTimeMs = new Date(dateFormat(new Date())[5]).getTime()
      if (dateMs == nowTimeMs) {
        this.isShowPoster = false
      } else {
        this.isShowPoster = true
      }
    },
    // 广告弹窗复选框change事件
    todayCheckboxChange() {
      this.todayChecked = !this.todayChecked
      if (this.todayChecked) {
        let nowTimeMs = new Date(dateFormat(new Date())[5]).getTime()
        window.localStorage.setItem('check_time', nowTimeMs)
      } else {
        window.localStorage.removeItem('check_time')
      }
    }
  }
}
</script>

<style scoped lang="less">
.big_box {
  z-index: 1;
  position: relative;
}

/deep/ .el-dialog {
  position: relative;
  height: 800px !important;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}

.poster_popup {
  color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .today_checkbox {
    display: flex;
    align-items: center;

    label {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .close_poster {
    position: absolute;
    bottom: 0;
    left: 46%;
    font-size: 32px;
  }
}
</style>