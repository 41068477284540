<!-- 浏览记录 -->
<template>
  <div class="big_box bg-white d-flex flex-column">
    <div v-if="pageindex.total" class="d-flex justify-content-end clear_history">
      <div @click="clearHistory">清空所有浏览历史</div>
    </div>
    <div v-if="pageindex.total" class="box_top">
      <div class="d-flex flex-column" v-for="(item, time, indexs) in list" :key="indexs + 1">
        <div class="d-flex justify-content-start BrowsingHistory">
          <div>{{ time }}</div>
        </div>
        <div class="for_box d-flex align-items-center justify-content-between" v-for="(items, index) in item" :key="index">
          <div class="d-flex" style="margin-left: 20px;">
            <div>
              <img style="width: 75px;height: 75px;" :src="$getImageUri(items.goods_image)" alt="抱歉图片加载失败" />
            </div>
            <div class="d-flex flex-column justify-content-center">
              <div class="capsule">{{ items.goods_name }}</div>
              <div class="first">¥{{ items.min_price }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoData v-else></NoData>
    <div class="block d-flex" v-if="pageindex.total">
      <el-pagination :current-page="pageindex.currentPage" :page-size="pageindex.pagesize" :page-sizes="[4]" @current-change="handlePageChange" layout="total, sizes, prev, pager, next, jumper" :total="pageindex.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Getfootprintlists, apiFootprintDel } from "@/api";
export default {
  name: 'BrowsingHistory',
  data() {
    return {
      pageindex: {
        total: 0,  // 总条数
        pagesize: 10, // 每页多少条
        currentPage: 1, // 当前页码
      },
      list: {},
    }
  },
  mounted() {
    this.Getfootprintlists();
  },
  computed: {
  },
  watch: {
    "pageindex.currentPage"(newval, oldval) {
      this.Getfootprintlists()
    }
  },
  methods: {
    handlePageChange(page) {
      this.pageindex.currentPage = page;
    },
    // 超值换购列表
    async Getfootprintlists() {
      let params = {
        page_no: this.pageindex.currentPage,
        page_size: this.pageindex.pagesize
      }
      let datalist = await Getfootprintlists(params);
      this.list = datalist.data.lists;
      this.pageindex.total = datalist.data.count;
    },
    // 清空历史浏览记录
    clearHistory() {
      if (!this.list) return false

      apiFootprintDel().then(() => {
        this.$message({
          type: 'success',
          offset: this.$store.state.config.outer_height,
          message: '清除浏览历史成功'
        })

        this.Getfootprintlists()
      }).catch((err) => {
        console.log(err, '-----清空浏览历史api---error')
        this.$message({
          type: 'error',
          offset: this.$store.state.config.outer_height,
          message: '抱歉, 清除失败请稍候重试'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.big_box {
  width: 980px !important;
  height: calc(100vh - 185px - 140px);
  overflow-y: auto;
  overflow-x: hidden;

  .box_top {
    height: 100vh;
    overflow-y: auto;
  }

  .clear_history {
    margin: 20px 20px;

    div {
      padding: 5px 8px;
      color: #ff334c;
      border: 1px dashed #ff334c;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .BrowsingHistory {
    width: 95%;
    margin: 0 auto;
    padding-top: 10px;
    height: 30px;
    border-bottom: 1px solid #e5e6eb;
    color: #1d2129;
    font-size: 16px;
  }

  .for_box {
    padding: 20px 0px 20px 0px;
    width: 95%;
    margin: 0 auto;
    border-bottom: 1px dashed #e5e6eb;
    .capsule {
      margin-left: 10px;
      color: #1d2129;
      font-size: 14px;
    }

    .first {
      margin: 10px 0px 0px 10px;
      font-size: 14;
      font-weight: 400;
      color: #ff334c;
    }

    .Go_buy {
      margin: 0px 20px 0px 10px;
      width: 100px;
      height: 30px;
      border: 1px solid #ff334c;
      font-size: 14px;
      color: #ff334c;
    }

    .Cancel_Favorite {
      margin: 0px 20px 0px 10px;
      width: 100px;
      height: 30px;
      border: 1px solid #c9cdd4;
      font-size: 14px;
      color: #1d2129;
    }
  }

  .block {
    margin: 20px 40px;
  }
}
</style>