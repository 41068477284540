//购物车小仓库
import { Getcartlist } from '@/api'
//规范:利用vuex存储数据
// import { SET_USERID } from '@/utils/USER_ID';
let state = {
    //vuex仓库存储用户临时身份,vuex存储数据确实非持久化的，SET_USERID执行返回结果,可是本地存储获取的！！
    // USER_ID: SET_USERID(),
    shopCartInfo: []
};
let mutations = {
    GETSHOPCART(state, payload) {
        state.shopCartInfo = payload;
    }
};
let actions = {
    // 获取用户购物车数量
    async getShopCart({ commit}) {
        let result = await Getcartlist();
        // console.log('result', result)
        if (result.code == 1) {
            commit('GETSHOPCART', result.data);
        }
    },
};
let getters = {
    //计算数组里面第一个元素：对象
    CartInfo(state) {
        return state.shopCartInfo[0] || {};
    },
};

//对外暴露
export default {
    state,
    mutations,
    actions,
    getters
}
