<!-- 新建页面模版 -->
<template>
  <div class="big_box d-flex">
    <div class="d-flex menu_box">
      <!-- default-openeds 默认展开菜单数组-->
      <!-- default-active 当前激活菜单-->
      <el-menu @select="Getselect" :default-openeds="select_openedslist" :default-active="select_opened" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
        <el-submenu index="1">
          <template slot="title">
            <span slot="title">订单中心</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="1-1">订单列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="2">
          <template slot="title">
            <span slot="title">我的资产</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="2-1">我的优惠券</el-menu-item>
            <el-menu-item index="2-2">我的钱包</el-menu-item>
            <el-menu-item index="2-3">签到代金券</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="3">
          <template slot="title">
            <span slot="title">我的服务</span>
          </template>
          <el-menu-item index="3-1">我的收藏</el-menu-item>
          <el-menu-item index="3-2">浏览记录</el-menu-item>
          <el-menu-item index="3-3">诊所联盟</el-menu-item>
          <el-menu-item index="3-4">个人资料</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="right_content d-flex flex-grow-1 bg-white">
      <!-- 订单列表 -->
      <orderList v-if="this.select_opened == '1-1'"></orderList>
      <!-- 我的优惠券 -->
      <mycoupon v-if="this.select_opened == '2-1'"></mycoupon>
      <!-- 我的钱包 -->
      <mywallet v-if="this.select_opened == '2-2'"></mywallet>
      <!-- 签到代金券 -->
      <SignVoucher v-if="this.select_opened == '2-3'"></SignVoucher>
      <!-- 我的收藏 -->
      <MyCollection v-if="this.select_opened == '3-1'"></MyCollection>
      <!-- 浏览记录 -->
      <BrowsingHistory v-if="this.select_opened == '3-2'"></BrowsingHistory>
      <!-- 诊所联盟 -->
      <ClinicAlliance v-if="this.select_opened == '3-3'"></ClinicAlliance>
      <!-- 个人资料 -->
      <personaldata v-if="this.select_opened == '3-4'"></personaldata>
    </div>
  </div>
</template>

<script>
import orderList from "./orderList/index";   // 订单列表
import mycoupon from "./mycoupon/index";   // 我的优惠券
import mywallet from "./mywallet/index";    // 我的钱包
import SignVoucher from "./SignVoucher/index";   //签到代金券
import MyCollection from "./MyCollection/index";    // 我的收藏
import BrowsingHistory from "./BrowsingHistory/index";   // 浏览记录
import ClinicAlliance from "./ClinicAlliance/index";  // 诊所联盟
import personaldata from "./personaldata/index";  // 个人资料
export default {
  name: 'ProductDetails',
  components: {
    orderList,
    mycoupon,
    mywallet,
    SignVoucher,
    MyCollection,
    BrowsingHistory,
    ClinicAlliance,
    personaldata
  },
  data() {
    return {
      select_openedslist: ['1', '2', '3'],
      select_opened: '1-1',
    }
  },
  mounted() {
    this.$bus.$on('select_tabs', this.getselect_tab);
  },
  methods: {
    getselect_tab(tab) {
      if (tab) this.select_opened = tab;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    Getselect(index) {
      this.select_opened = index;
    }
  },
  beforeDestroy() {
    this.$bus.$off('select_tabs', this.getselect_tab)
  }
}
</script>

<style lang="less" scoped>
.big_box {
  height: 100vh;
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;

  .menu_box {
    width: 206px;
  }

  .right_content {
    margin: 0px 0px 0px 10px;
  }
}
</style>