<template>
  <div id="app">
    <Rightlist v-if="$route.path != '/'" @getType="getType"></Rightlist>

    <Header v-if="$route.path != '/'" />

    <home_Title ref="child" class="home_Title" v-if="$route.path != '/'" />

    <RouterView :key="$route.fullPath" v-wechat-title="$route.meta.title"></RouterView>

    <Footer v-if="$route.path != '/'" />
  </div>
</template>

<script>
import home_Title from '@/pages/Home/Title/home_Title'
import Header from './components/Header'
import Footer from './components/Footer'
import { getConfig } from "@/api";
export default {
  name: 'App',
  data() {
    return {
      name: '',
      isLoading: true
    }
  },
  components: {
    Header, Footer, home_Title
  },
  created() {
    console.log(this.$route, '----route')
    this.$store.commit('setOuterHeight', window.outerHeight / 2 - 100)
    window.addEventListener('resize', () => {
      this.$store.commit('setOuterHeight', window.outerHeight / 2 - 100)
    })

    getConfig().then((res) => {
      console.log(res, '------获取应用配置api---success Appvue')
      this.$store.commit('setConfig', res.data)

    })
  },
  methods: {
    getType(name) {
      this.$refs.child.sing(name);
    }
  }
}
</script>

<style>
/* css input 复选框 */
input[type="checkbox"] {
  cursor: pointer;
  position: relative;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

input[type="checkbox"]::after {
  position: absolute;
  top: 0;
  background-color: #fff;
  color: #fff;
  width: 18px;
  height: 18px;
  display: inline-block;
  visibility: visible;
  padding-left: 0px;
  text-align: center;
  content: " ";
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #999;
}

input[type="checkbox"]:checked::after {
  content: "";
  background-color: #ff334c;
  border-color: #ff334c;
  background-color: #ff334c;
}

input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 1;
}
</style>

<style lang="less" scoped>
.home_Title {
  margin-top: 30px;
}
</style>
