import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'
import store from '@/store'
import CryptoJS from "crypto-js"

//使用插件
Vue.use(VueRouter)

//先把VueRouter原型对象的push，先保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
//重写push|replace
//第一个参数：告诉原来push方法，你往哪里跳转（传递哪些参数）
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(
      this,
      location,
      () => { },
      () => { }
    )
  }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    console.log(location, '----location')
    console.log(resolve, '----resolve')
    console.log(reject, '----reject')
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(
      this,
      location,
      () => { },
      () => { }
    )
  }
}
let router = new VueRouter({
  mode: 'hash',
  //配置路由
  routes,
  //设置滚动条的位置
  scrollBehavior() {
    //滚动行为这个函数,需要有返回值,返回值为一个对象。
    //经常可以设置滚动条x|y位置 [x|y数值的设置一般最小是零]
    return { y: 0 }
  }
})

//全局前置守卫
router.beforeEach(async (to, from, next) => {
  let hasToken = window.localStorage.getItem('login_token')
  if (hasToken) {
    let tokenBytes = CryptoJS.AES.decrypt(hasToken, 'tokenKey')
    hasToken = tokenBytes.toString(CryptoJS.enc.Utf8)
  }

  let loginList = window.localStorage.getItem('loginlist')
  if (loginList) {
    let listBytes = CryptoJS.AES.decrypt(loginList, 'loginlistKey')
    loginList = listBytes.toString(CryptoJS.enc.Utf8)
  }

  let companyData = window.localStorage.getItem('company_data')
  if (companyData) {
    let companyBytes = CryptoJS.AES.decrypt(companyData, 'companyKey')
    companyData = companyBytes.toString(CryptoJS.enc.Utf8)
  }

  // 防止刷新丢掉缓存
  if (loginList) {
    store.commit('USERLOGIN', JSON.parse(loginList))
  }

  if (companyData) {
    store.commit('company', JSON.parse(companyData))
  }

  if (to.path == '/' && hasToken) {
    return next('/home')
  } else if (to.path != '/' && !hasToken) {
    return next('/')
  } else {
    next()
  }
})

export default router
