<template>
	<div class="footer">
		<div class="footer-container">
			<div class="footerList d-flex justify-content-between">
				<div class="d-flex justify-content-center align-items-center" v-for="item in footerList"
					:key="item.name">
					<!-- <img  class="box_img" src="./images/yuan.png" alt="图片丢失" /> -->
					<div class="circle d-flex justify-content-center align-items-center">
						<img class="footerList_img" :src="item.src" alt="图片丢失" />
					</div>
					<div>{{ item.name }}</div>
				</div>
			</div>
			<div class="d-flex align-items-center justify-content-center" style="margin-top: 10px;">
				<img :src="require('./images/ba.png')" />
				<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001572" target="_blank">
					鲁公网安备37011202001572号
				</a>
				<a href="https://beian.miit.gov.cn/#/Integrated/index" style="margin-left: 30px;" target="_blank">
					鲁ICP备16016081号-1
				</a>
			</div>
			<div class="copyright d-flex align-items-center justify-content-center" title="点击查看经营许可信息"
				@click="licensekey ? isShowQualifications = true : isShowQualifications = false">
				<span>经营许可证号：</span>
				<div>
					{{ licensekey ? licensekey : '暂无经营许可证号' }}
				</div>
			</div>
			
		</div>

		<!-- 资质信息弹窗 -->
		<el-dialog title="资质信息" :visible.sync="isShowQualifications" center :modal-append-to-body="false">
			<div v-if="imageList.length > 0">
				<div v-for="(item, index) in imageList" :key="index">
					<el-image style="width: 100%; height: auto" :src="$getImageUri(item)" :preview-src-list="imageList"
						lazy>
						<div slot="placeholder" class="image-slot">
							加载中请稍候...
						</div>
					</el-image>
				</div>
			</div>
			<NoData v-else></NoData>
		</el-dialog>
	</div>
</template>

<script>
	import {
		apiCopyright
	} from '@/api/index'
	export default {
		name: "footer-index",
		data() {
			return {
				footerList: [{
					src: require('./images/zysc.png'),
					name: '自营商城'
				}, {
					src: require('./images/jsps.png'),
					name: '极速配送'
				}, {
					src: require('./images/zskf.png'),
					name: '专属客服'
				}, {
					src: require('./images/shwy.png'),
					name: '售后无忧'
				}, {
					src: require('./images/pzbz.png'),
					name: '品质保障'
				}],
				// 经营许可证号
				licensekey: '',
				isShowQualifications: false,
				imageList: []
			}
		},
		computed: {
			login_() {
				return this.$store.state.user.userInfo.name;
			}
		},
		mounted() {
			this.getCopyrightFunc()
		},
		methods: {
			// 获取经营许可证号
			getCopyrightFunc() {
				apiCopyright().then(res => {
					this.licensekey = res.data.medicine_seller_number
					this.imageList = res.data.other_qualifications ? JSON.parse(res.data.other_qualifications) : []
					this.imageList.unshift(res.data.business_license)
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.footer {
		// margin-top: 320px;
		margin-top: 110px;
		// background-color: #252525;

		.footer-container {
			width: 1200px;
			padding: 0 15px;
			margin: 0 auto;

			.footerList {
				padding: 20px;
				border-bottom: 1px solid #4f4f4f;
				/* border-top: 1px solid #f9fcfc; */
				overflow: hidden;
				padding-left: 40px;
				color: #4f4f4f;
				font-size: 18px;

				.footerItem {
					width: 16.6666667%;
					float: left;
					color: white;

					h4 {
						font-size: 14px;
					}

					.footerItemCon {
						li {
							line-height: 18px;
						}
					}

					&:last-child img {
						width: 121px;
					}
				}
			}

			.copyright {
				margin: 10px 0px 30px;
				color: #4f4f4f;
				font-size: 12px;
				font-weight: 600;
				cursor: pointer;

				.helpLink {
					text-align: center;

					li {
						display: inline;

						.space {
							border-left: 1px solid rgba(246, 247, 248, 0.99);
							width: 1px;
							height: 13px;
							background: #f5f1f1;
							margin: 8px 10px;
						}
					}
				}

				p {
					margin: 10px 0;
					text-align: center;
				}
			}
		}
	}

	.circle {
		margin-right: 20px;
		border: 1px solid #4f4f4f;
		height: 40px;
		width: 40px;
		border-radius: 20px;
	}

	.image-slot {
		font-size: 16px;
		text-align: center;
		line-height: 20px;
	}
</style>