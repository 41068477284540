import axios from 'axios'
//引入进度条
import nprogress from 'nprogress'
import store from '@/store'
import 'nprogress/nprogress.css'

const BASE_URL = {
  // 开发环境
  development: 'https://xpyy.sindayy.com',
  // development: 'http://127.0.0.1:8003',
  // development: 'http://appcs.sindayy.com',
  // 生产环境
  production: 'https://xpyy.sindayy.com'
  // production: 'http://appcs.sindayy.c1m'
}

const requests = axios.create({
  //配置对象
  baseURL: process.env.NODE_ENV == 'development' ? BASE_URL.development + '/shopapi/' : BASE_URL.production + '/shopapi/',
  timeout: 10000,
  // 设置默认的请求头
  headers: {
    // "Access-Control-Allow-Origin":"*",
    // "Access-Control-Request-Headers": 'Content-Type: application/x-www-form-urlencoded','version: 1.9'
    //'Content-Type': 'application/x-www-form-urlencoded',
  }
})

var applyVersion = store.state.config.applyVersion

requests.interceptors.request.use((config) => {
  nprogress.start()

  config.headers.version = applyVersion

  if (store.state.user.token) config.headers.token = store.state.user.token

  return config
})
//响应拦截器
requests.interceptors.response.use(
  (res) => {
    nprogress.done()
    return res.data
  },
  (error) => {
    console.log('报错信息', error)
    nprogress.done()
    //响应失败的回调函数
    return Promise.reject(new Error('出错啦'))
  }
)

//对外暴露
export default requests
