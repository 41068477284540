<template>
  <div class="big_box d-flex flex-column">
    <!-- 图片 -->
    <div class="d-flex flex-column">
      <div class="image_text">
        <img :src="img" alt="抱歉活动图片加载失败" />
      </div>
      <commodity :tyoe_list="tyoe_list" :pageInfo="pageInfo"></commodity>
      <recommand></recommand>
    </div>
  </div>
</template>

<script>
import { GetExchangeactivityLists } from "@/api";
export default {
  name: 'ValueExchange',
  data() {
    return {
      pageInfo: {},
      activeName: 'user',
      tyoe_list: [],
      sn: '',
      img: require('./images/exchange.jpg')
    }
  },
  beforeMount() {
    if (this.$params) {
      if (this.$params.sn) {
        this.sn = this.$params.sn
      }
      if (this.$params.img) {
        this.img = this.$params.img
      }
    }
  },
  mounted() {
    this.GetExchangeactivityLists();
  },
  computed: {
  },
  methods: {
    handleClick(tab, event) {
    },
    // 超值换购列表
    async GetExchangeactivityLists() {
      let data = {
        activity_type: 3,
        sn: this.sn
      };
      let datalist = await GetExchangeactivityLists(data);
      if (datalist.code == 1) {
        this.tyoe_list = datalist.data.data;
        this.pageInfo.index = datalist.data.page_no;
        this.pageInfo.size = datalist.data.page_size;
        this.pageInfo.total = datalist.data.count;
        // console.log('this.pageInfo',this.pageInfo)
      }
    },
  }
}
</script>

<style lang="less" scoped>
/deep/[data-v-4298b343] .el-tabs--card>.el-tabs__header .el-tabs__item {
  font-size: 16px;
}

/deep/.el-tabs--card>.el-tabs__header {
  border: 0;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: none;
}

/deep/.el-tabs__nav {
  background-color: none;
  padding: 10px 0px 0px 30px;
}

/deep/.el-tabs__item.is-active {
  color: #ff334c;
}

/deep/.el-tabs__item:hover {
  color: #ff334c;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
  /* color: #000000; */
  font-size: 18px;
  border-left: none;
}

.big_box {
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
}

.image_text {
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: #fff;

  img {
    width: 100%;
    height: 200px;
  }
}

.sort {
  margin: 10px 0px 0px 0px;
  color: #1d2129 !important;
  font-size: 16px;
  height: 50px;
  background-color: #ffffff;
}

.type_box {
  margin-top: 20px;
  background-color: #ffffff;
}

.for_box {
  margin: 0px 0px 0px 10px;
  width: 221px;
  height: 434px;
  background-color: #ffffff;
  border-radius: 5px;

  .goods_name {
    font-family: PingFangSC-Medium;
    font-weight: normal;
    margin-left: 10px;
    color: #1d2129;
    font-size: 16px;
    word-wrap: break-word;
    word-break: normal;
  }
}

.for_image {
  margin: 15px 0px 10px 10px;
  width: 191px;
  height: 191px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
}

.specialoffer_title {
  width: 100%;
  margin-left: 5px;

  .specialoffer {
    border: 1px solid #ff334c;
    width: 34px;
    height: 20px;
    border-radius: 5px;
    background-color: #ffeeef;
    font-size: 12px;
    color: #ff334c;
  }
}

.spec {
  margin: 5px 0px 0px 10px;
  font-size: 14px;
  color: #4e5969;
}

.period_of_validity {
  margin: 5px 0px 0px 10px;
}

.company {
  margin: 5px 0px 0px 10px;
  color: #4e5969;
  font-size: 14px;
}

.exchange_price {
  margin: 5px 0px 0px 10px;
  font-size: 14px;
  color: #1d2129;
}

.sup_div {
  margin: 5px 0px 0px 10px;
}

.Supplyprice {
  color: #4e5969;
  font-size: 12px;
}

.inpackage {
  border: 1px solid #ff334c;
  width: 41px;
  height: 16px;
  border-radius: 5px;
  background-color: #ffeeef;
  font-size: 10px;
  color: #ff334c;
}

.Package {
  color: #1d2129;
  font-size: 10px;
}

.Post_coupon_price {
  margin: 10px 0px 0px 10px;
  font-size: 12px;

  .coupon_price {
    color: #ff334c;
  }
}
</style>