// 限时秒杀
<template>
	<div v-if="(seckillListObj.status == 1 || seckillListObj.pc_status == 1) && seckillListObj.list.length > 0"
		class="seckill" @click="toSeckillPage">
		<div class="seckill_top"
			:style="{ background: 'repeating-linear-gradient(to bottom,' + this.startColor + ',' + this.endColor + ')' }">
			<div class="seckill_left" :style="{ color: this.titleColor }">
				<div class="limit">{{ title }}</div>
			</div>
			<div v-if="isShowCountDown" class="seckill_countdown">
				<el-statistic @finish="hilarity" format="DD:HH:mm:ss"
					:value="new Date(seckillListObj.list[0].end_time).getTime()" time-indices
					title="秒杀倒计时⚡"></el-statistic>
			</div>
			<div class="seckill_right" :style="{ color: this.subTitleColor }">
				<div class="see_more">查看更多</div>
				<i class="el-icon-arrow-right"></i>
			</div>
		</div>
		<div class="seckill_bottom">
			<div class="seckill_item" v-for=" item  in  seckillListObj.list " :key="item.goods_id">
				<img class="medicine" :src="$getImageUri(item.image)" alt="抱歉图片加载失败" />
				<div class="detail">
					<div class="detail_name">{{ item.goods_name }}</div>
					<div class="specifications-second">{{ item.spec }}</div>
					<div class="seckill_box">
						<div class="box_left">秒杀</div>
						<div class="box_right">多选1</div>
					</div>
					<div class="detail_price">
						<div class="new_price">￥{{ item.seckill_price }}</div>
						<div class="old_price">￥{{ item.goods_price }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'seckill-index',
		props: {
			seckillListObj: {
				type: Object,
				default: () => {}
			},
			title: {
				type: String,
				default: () => '限时秒杀'
			},
			isFloor: {
				type: Boolean,
				default: () => false
			},
			startColor: {
				type: String,
				default: () => '#ff334c'
			},
			endColor: {
				type: String,
				default: () => 'rgba(255, 51, 76, 0.5)'
			},
			titleColor: {
				type: String,
				default: () => '#ffffff'
			},
			subTitleColor: {
				type: String,
				default: () => '#ffffff'
			}
		},
		data() {
			return {
				isShowCountDown: true,
			}
		},
		methods: {
			hilarity() {
				this.isShowCountDown = false
			},
			toSeckillPage() {
				if (this.isFloor) {
					this.$isRouterParams('Ltfk', {
						sn: this.seckillListObj.sn,
						img: this.seckillListObj.img,
						showHomeTitle: this.isFloor ? '0' : '1'
					})
				} else {
					this.$isRouterParams('Ltfk')
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.medicine {
		width: 100%;
		// height: 173.077px;
	}

	.seckill {
		width: 1200px;
		margin: 20px auto 0;
		border-radius: 10px;
		background-color: #fff;
		cursor: pointer;
		font-family: PingFang SC;

		.seckill_top {
			height: 82.692px;
			border-radius: 15.385px 15.385px 0px 0px;
			color: #ffffff;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 0 15.385px;
			// background: repeating-linear-gradient(to right,
			//     #ff334c,
			//     rgba(255, 51, 76, 0.5));

			.seckill_left {
				.limit {
					font-size: 24px;
				}
			}

			.seckill_countdown {
				padding: 3px 5px;
				border-radius: 10px;
				background-color: rgba(255, 255, 255, 0.5);
			}

			.seckill_right {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
			}
		}

		.seckill_bottom {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			// border: 1px solid red;
			// box-sizing: border-box;
			// padding: 20px 15.385px;

			.seckill_item {
				width: calc(20% - 20px);
				margin: 20px 10px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
				border-radius: 0px 0px 15.385px 15.385px;

				.detail {
					width: 211.538px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					box-sizing: border-box;

					.detail_name {
						font-size: 18px;
						color: #1d2129;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					.specifications-second {
						font-size: 14px;
						color: #86909c;
						margin: 5px 0;
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.seckill_box {
						height: 20px;
						border: 1px solid #ff334c;
						font-size: 12px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-radius: 3.846px;
						overflow: hidden;

						.box_left,
						.box_right {
							line-height: 30px;
							text-align: center;
						}

						.box_left {
							padding: 2px;
							background-color: #ff334c;
							color: #ffffff;
						}

						.box_right {
							padding: 2px 10px;
							color: #ff334c;
						}
					}

					.detail_price {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin-top: 19.231px;

						.new_price {
							color: #ff334c;
							font-size: 16px;
						}

						.old_price {
							color: #86909c;
							text-decoration: line-through;
							font-size: 14px;
							margin-left: 13.462px;
						}
					}
				}
			}

			
		}
	}
</style>