// 专区页面
<template>
  <div class="big_box d-flex flex-column">
    <div class="d-flex flex-column">
      <!-- 活动描述图文 -->
      <div v-if="img" class="image_text">
        <img :src="img" alt="活动图片加载失败1" />
      </div>
      <div v-else-if="pageTitle || imageText.pc_show_img || imageText.show_remark" class="image_text">
        <div v-if="pageTitle && !imageText.pc_show_img" class="page_title">{{ pageTitle }}</div>
        <div v-else-if="(imageText.pc_show_img || imageText.show_remark)">
          <img v-if="imageText.pc_show_img" :src="imageText.pc_show_img" alt="活动图片加载失败2" />
          <!-- <div v-if="imageText.show_remark">{{ imageText.show_remark }}</div> -->
        </div>
      </div>
      <!-- 商品列表 -->
      <div v-if="pageMark == 5 || pageMark == 6">
        <commodity v-if="isGoodsList.length > 0" :tyoe_list="isGoodsList" :pageInfo="pageInfo"
          @pageindex="getGoodslist($event)"></commodity>
        <NoData v-else></NoData>
      </div>
      <!-- 活动商品列表 -->
      <div v-else-if="isActivityList.length > 0">
        <commodity v-if="isActivityList.length > 0" :tyoe_list="isActivityList" :pageInfo="pageInfo"
          @pageindex="goodsListPageChange($event)"></commodity>
        <NoData v-else></NoData>
      </div>
      <recommand></recommand>
    </div>
  </div>
</template>

<script>
import { apiJumpLink, GetGoodslists } from '@/api/index'
export default {
  name: 'zone-index',
  data() {
    return {
      // pageMark: 秒杀-1 特价-2 换购-3 赠品-4 标签-5 品牌专区-6 默认0-商品列表
      pageMark: 0,
      // 活动sn 
      isActivitySn: '',
      // 活动关键字
      isKeywords: '',
      // 活动描述图文
      imageText: {},
      // 商品列表
      isGoodsList: [],
      // 列表分页
      pageInfo: {
        index: 1,
        size: 15,
        total: 0
      },
      // 活动商品列表
      isActivityList: [],
      // 标签id
      labelId: '',
      // 品牌id
      brandId: '',
      // 页面标题
      pageTitle: '',
      // 置顶图片
      img: '',
    }
  },
  beforeMount() {
    if (this.$params) {
      this.pageMark = this.$params.mark
      this.isKeywords = this.$params.keywords
      this.pageTitle = this.$params.title
      if (this.$params.img) {
        this.img = this.$getImageUri(this.$params.img)
      }
      if (this.$params.data) {
        this.imageText = JSON.parse(this.$params.data)
      } else {
        this.isNotData()
      }
    } else {
      this.isNotData()
    }
    console.log(this.$params, '------首页跳转页面参数')

    if (this.pageMark == 1 || this.pageMark == 2 || this.pageMark == 3 || this.pageMark == 4) {
      if (this.$params.activity_sn) this.isActivitySn = this.$params.activity_sn
      this.getActivityList()
    } else if (this.pageMark == 5) {
      this.labelId = this.$params.label_id
      this.getGoodslist()
    } else if (this.pageMark == 6) {
      this.brandId = this.$params.brand_id
      this.getGoodslist()
    } else {
      this.getGoodslist()
    }
  },
  methods: {
    /**
     * 无参数返回上一页
     */
    isNotData() {
      this.$message({
        type: 'warning',
        offset: this.$store.state.config.outer_height,
        message: '抱歉，当前页面不可用请稍候重试'
      })
      this.$router.go(-1)
    },
    /**
     * 获取 秒杀 || 特价 || 换购 || 赠品 活动商品列表
     */
    getActivityList() {
      let reqObj = {
        code: this.isActivitySn,
        keywords: this.isKeywords
      }

      console.log(reqObj, '----reqobj')

      apiJumpLink(reqObj).then((res) => {
        console.log(res, '------获取活动商品列表---success')
        this.isActivityList = res.data.goods
      }).catch((err) => {
        console.log((err), '------获取活动商品列表---error')
      })
    },
    getGoodslist(pageindex) {
      let reqObj = {
        page: pageindex ? pageindex : this.pageInfo.index,
        page_size: this.pageInfo.size
      }

      if (this.pageMark == 5) {
        reqObj['label_id'] = this.labelId
        reqObj['keywords'] = this.isKeywords
      } else if (this.pageMark == 6) {
        reqObj['brand_id'] = this.brandId
        reqObj['keywords'] = this.isKeywords
      }
      // console.log(reqObj, '------专区商品列表---参数')

      GetGoodslists(reqObj).then(({
        data,
      }) => {
        console.log(data, '------获取商品列表---success')

        this.isGoodsList = data.data
        this.pageInfo.total = data.count
      }).catch((err) => {
        console.log(err, '------获取商品列表---error')
      })
    }
  }
}
</script>

<style scoped lang="less">
.big_box {
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;

  .image_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 10px;
    box-sizing: border-box;
    background-color: #fff;

    img {
      width: 100%;
      height: 200px;
    }


    .page_title {
      font-size: 24px;
      font-weight: 600;
    }

    div {
      color: #333;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>