<!-- 新建页面模版 -->
<template>
  <div class="big_box d-flex flex-column bg-white">
    <div class="d-flex consignee">选择收货人</div>
    <div class="carousel d-flex justify-content-center align-items-center">
      <div class="slider" :style="{ overflowX: StoreList.length > 4 ? 'scroll' : 'hidden' }">
        <div v-for="(item, index) in StoreList" :key="index" class="address_item d-flex flex-column" :class="{ active: item.is_default == 1 }" @click="defaultAddressChange(item)">
          <div class="shop_name">{{ companyName ? companyName : '' }}</div>
          <div class="d-flex text_number">
            <div>{{ item.realname }}</div>
            <div style="margin-left: 10px;">{{ item.mobile }}</div>
          </div>
          <div class="address">{{ item.address }}</div>
        </div>
      </div>
    </div>
    <div v-if="alldata.promotion && alldata.promotion.length > 0" class="d-flex flex-column justify-content-center Scrape_together">
      <div class="for__box d-flex justify-content-between" v-for="(item, index) in alldata.promotion" :key="index">
        <div class="d-flex justify-content-center align-items-center">
          <div class="Full_amount d-flex justify-content-center align-items-center">
            {{ item.label }}
          </div>
          <div class="Poor_money">
            {{ item.text }}
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center stab">
          <!-- 跳转弹窗 -->
          <div @click="getcartaddCartOther(item)">{{ item.result_text }}&gt;</div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center shop_box">
      <div class="shop_message">
        商品信息
      </div>
      <div class="d-flex">
        <button style="margin-right: 10px;" class="button_" :class="{ active: isActive === 'button1' }" @click="toggleButton('button1')">
          购物模式
        </button>
        <button class="button_" :class="{ active: isActive === 'button2' }" @click="toggleButton('button2')">
          清单模式
        </button>
      </div>
    </div>
    <!-- 购物模式 -->
    <div v-if="isActive == 'button1'" class="header_form">
      <div class="common_flex">
        <div class="d-flex model_color" @click="toggleSelectAll">
          <input v-model="selectAll" class="model_input" type="checkbox" />
          <span>全选</span>
        </div>
        <div class="name_shop goods_info_title">商品信息</div>
        <div class="item_shop goods_info_title">
          <div>零售价</div>
          <div>毛利率</div>
          <div>供货价</div>
          <div>优惠价</div>
          <div>中包/件装</div>
          <div>数量</div>
          <div>小计</div>
          <div>操作</div>
        </div>
      </div>
      <div class="common_flex bddb" v-for="(item, index) in alldata.lists" :key="index">
        <input :disabled="item.activity_type == 4" class="model_input" type="checkbox" :value="item.cart_id" v-model="item.selected" @change="goodsSelected(item)" />
        <div class="d-flex" style="width: 280px;">
          <div class="d-flex" @click="toGoodsDetail(item)">
            <img style="width: 100;height: 100px;" :src="$getImageUri(item.image)" alt="抱歉图片加载失败" />
          </div>
          <div class="d-flex flex-column justify-content-between goods_info">
            <div class="specialoffer_title">
              <span v-if="item.activity_type_text" :style="Getpickcolor(item.activity_type_text)" class="specialoffer">
                {{ item.activity_type_text }}活动</span>
              <span class="goods_name">{{ item.name }}</span>
            </div>
            <div class="specifications">{{ item.spec }}</div>
            <div v-if="item.expired_date" class="d-flex">
              <div class="period_validity">效期优于</div>
              <div class="date">{{ item.expired_date }}</div>
            </div>
            <div class="date">{{ item.supplier_name }}</div>
          </div>
        </div>
        <div class="item_shop">
          <div>¥{{ item.cost_price }}</div>
        </div>
        <div class="item_shop">
          <div>{{ item.rate }}%</div>
        </div>
        <div class="item_shop">
          <div>¥{{ item.lineation_price }}</div>
        </div>
        <div class="item_shop">
          <div>¥{{ item.sell_price }}</div>
        </div>
        <div class="d-flex item_shop middle_large">
          <div v-if="item.middle_package_number > 0" @click="middleOrLargePackage(0, item)" class="inpackage" :class="{ select_Pack: item.middle_package_status == true }">{{ item.middle_package_number ?
                            item.middle_package_number : 0 }}</div>
          <div v-if="item.large_package_number > 0" @click="middleOrLargePackage(1, item)" class="Package" :class="{ select_Pack: item.large_package_status == true }">{{ item.large_package_number }}</div>
        </div>
        <div class="item_shop">
          <div v-if="isCheckShowQuota(item)">
            <el-tooltip :value="true" effect="light" placement="bottom-start">
              <div slot="content" v-for="(collapse_item, collapse_index) in item.step_price" :key="collapse_index">
                购买{{ collapse_item.start_num }}-{{ collapse_item.end_num }}盒享{{ priceName(item.activity_sn)}}<span style="color: #FF334C"><span style="font-size: 10px;">￥</span>{{ collapse_item.price}}</span>
              </div>
              <el-input-number v-model="item.goods_num" @input="handleInput" :disabled="item.is_activity_goods == 1" @change="(currentValue, oldValue) => changenum(currentValue, oldValue, item)" :step="item.step_number" :key="inputNumberKey" size="mini" :min="0" label="描述文字" @focus="stepperFocus" @blur="isStepperFocusMark = false"></el-input-number>
            </el-tooltip>
          </div>
          <div v-else>
            <el-input-number v-model="item.goods_num" @input="handleInput" :disabled="item.is_activity_goods == 1" @change="(currentValue, oldValue) => changenum(currentValue, oldValue, item)" :step="item.step_number" :key="inputNumberKey" size="mini" :min="0" label="描述文字" @focus="stepperFocus" @blur="isStepperFocusMark = false"></el-input-number>
          </div>
        </div>
        <div class="Price_Style item_shop">
          <div>{{ item.sub_price }}</div>
        </div>
        <div class="item_shop" title="点击从购物车删除该商品">
          <div><i :class="{ 'disabled-icon': item.activity_type == 4 }" @click="deleteSelected(item, index)" class="el-icon-delete"></i></div>
        </div>
      </div>
      <NoData v-if="alldata.lists && alldata.lists.length == 0"></NoData>
      <div class="d-flex justify-content-between align-items-center gauge_outfit_Footer">
        <div class="d-flex justify-content-between align-items-center" style="width: 400px;">
          <div class="d-flex align-items-center model_color" @click="toggleSelectAll">
            <input v-model="selectAll" class="model_input" type="checkbox" />
            <span>全选</span>
          </div>
          <div class="selectedItems_lenth">已选({{ selectcartid_list.length }})</div>
          <div @click="Batch_Delete" class="Delete_product">删除选中商品</div>
          <div v-if="isSelectedCouponList && isSelectedCouponList.length > 0" @click="isShowSelectedCoupon = true" style="color: #ff334c">查看已选优惠券</div>
        </div>
        <div class="d-flex align-items-center Total_price">
          <div class="Merchandise_Subtotal">商品总价：</div>
          <div class="RMB"><span style="font-size: 14px;">¥</span>{{ alldata.total_amount }}</div>
        </div>
      </div>
    </div>
    <!-- 清单模式 -->
    <div class="d-flex flex-column" v-if="isActive == 'button2'">
      <div class="d-flex justify-content-between gauge_titile">
        <div>商品名称</div>
        <div>规格</div>
        <div>单价</div>
        <div>数量</div>
        <div>金额(元)</div>
      </div>
      <div v-for="(items, indexs) in alldata.category_list" :key="indexs" class="d-flex flex-column align-items-center justify-content-center">
        <div v-for="(items_, indexs_) in items.index" :key="indexs_" class="d-flex align-items-center justify-content-between gauge_titile_">
          <div>{{ items_.name }}</div>
          <div>{{ items_.spec }}</div>
          <div>¥{{ items_.price }}</div>
          <div style="font-weight: 550;">x{{ items_.number }}</div>
          <div>¥{{ items_.amount }}</div>
        </div>
        <div class="d-flex align-items-center justify-content-between gauge_titile">
          <div class="d-flex align-items-center subtotal_categories">
            <div class="ccrd">{{ items.name }}</div>
            <div class="Number_types">合计{{ items.goods_id_number }}种</div>
            <div class="Number_types">数量{{ items.goods_number }}</div>
          </div>
          <div class="d-flex">
            <div class="Merchandise_Subtotal">商品小计：</div>
            <div class="RMB"><span style="font-size: 14px;">¥</span>{{ items.amount }}</div>
          </div>
        </div>
      </div>
      <NoData v-if="alldata.category_list && alldata.category_list.length == 0"></NoData>
      <div class="d-flex justify-content-end align-items-center Total_price" style="margin: 30px 20px 0 0">
        <div class="Merchandise_Subtotal">总价：</div>
        <div class="RMB"><span style="font-size: 14px;">¥</span>{{ alldata.total_amount }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-end" style="padding: 10px 10px 0px 0px;">
      <div @click="To_settle" class="d-flex justify-content-center align-items-center To_settle">
        去结算</div>
    </div>

    <!-- 活动商品弹窗 -->
    <el-dialog :title="PopupText + '活动'" :visible.sync="isShowMakeUpPopUp" center append-to-body :modal-append-to-body="false">
      <div class="tow_box d-flex flex-column bg-white align-items-center">
        <div class="d-flex flex-column Conditionalzone justify-content-center">
          <div class="d-flex align-items-center">
            <div>
              排序：
            </div>
            <div @click="Getselect(item, 'sort')" :class="{ select_sort: sort == item.id }" class="d-flex px_margin" v-for="(item) in alert_list.sort" :key="item.id">
              {{ item.name }}
            </div>
          </div>
          <div class="d-flex align-items-center" style="margin: 20px 0px 0px 0px;">
            <div>
              价格：
            </div>
            <div @click="Getselect(item, 'price')" :class="{ select_price: price.price_name == item.name }" class="d-flex px_margin" v-for="(item) in alert_list.price" :key="item.name">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- 换购条件 -->
        <div v-if="Product_data.addon_number == 0" class="d-flex Swap_Text align-items-center">
          {{ Product_data.message }}
        </div>
        <!-- 中间数据 -->
        <commodity @Getupdate="GetcartdoCart" @pageindex="getpageindex" :tyoe_list="Product_data.data" :pageInfo="pageInfo"></commodity>
        <!-- 底部按钮 -->
        <div v-if="activity_sn != ''" class="d-flex justify-content-between  align-items-center box_end">
          <div class="Scrape" v-if="Product_data.addon_number == 0">未满足换购门槛，快去凑单吧</div>
          <div class="d-flex lookshoping">
            <el-button @click="GetNewday(true)" style="width:138px;height: 40px;" type="danger">查看活动商品</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 选择赠品或优惠券弹窗 -->
    <el-dialog title="选择赠品或优惠券" :visible.sync="Internal_pop" center append-to-body :modal-append-to-body="false">
      <el-tabs v-model="isSelectGoodsOrCoupon" stretch>
        <!-- 选择赠品 -->
        <el-tab-pane label="选择赠品" name="0">
          <commodity @close="close" :is_disabled="is_disabled" :addon_number="true" :tyoe_list="Internal_popdata" />
          <NoData v-if="Internal_popdata && Internal_popdata.length == 0"></NoData>
        </el-tab-pane>
        <!-- 选择优惠券 -->
        <el-tab-pane label="选择优惠券" name="1">
          <voucher @GetselectedOptions="GetselectedOptions" :list="selectCouponList" input_state is_radio>
          </voucher>
          <NoData v-if="selectCouponList && selectCouponList.length == 0"></NoData>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 活动已选优惠券弹窗 -->
    <el-dialog title="活动已选优惠券" :visible.sync="isShowSelectedCoupon" center :modal-append-to-body="false">
      <voucher :list="isSelectedCouponList" input_state is_disabled_checkbox></voucher>
    </el-dialog>
  </div>
</template>

<script>
import addCartCheck from '@/mixins/addCartCheck'
import { GetcartdoCart, GetuseraddressList, Editcartselected, Editcartbatch_del, EditusersetDefault, getcartaddCartOther } from "@/api"
import { MessageBox } from 'element-ui'
import CryptoJS from "crypto-js"
export default {
  name: 'ShoppingCart',
  mixins: [addCartCheck],
  components: {
    NoData: () => import('@/components/NoData/index')
  },
  data() {
    return {
      is_disabled: "",
      sort: 0,
      page_no: 1,  // 当前页数
      price: {
        price_name: "全部", // 展示文本
        price_min: 0,
        price_max: 99999
      },
      pageInfo: {},
      PopupText: "",
      alert_list: {
        sort: [{
          name: '智能排序',
          id: 0
        }, {
          name: '价格从低到高',
          id: 1
        }, {
          name: '价格从高到低',
          id: 3
        }, {
          name: '销量从高到低',
          id: 2
        }],
        price: [{
          name: '全部',
        }, {
          name: '0-20元',
        }, {
          name: '20-40元',
        }, {
          name: '40-60元',
        }, {
          name: '60-80元',
        }, {
          name: '80-100元',
        }, {
          name: '100元以上',   // 传最小金额100元
        }
        ]
      },
      Product_data: {},
      Internal_popdata: {},
      isShowMakeUpPopUp: false,  // 换购弹窗状态
      Internal_pop: false,  // 活动商品弹窗
      address_id: "",   // 选中用户地址id
      selectcartid_list: [],   // 全选idlist
      StoreList: [],   // 店铺列表
      alldata: {},
      selectAll: true,  // 默认全选
      isActive: 'button1', // 记录当前选中的按钮
      currentIndex: 0,
      selectedIndex: -1,
      activity_sn: "",  // 当前商品弹窗的sn
      is_add: true,   // 是家还是减
      // 是否为购物车页面
      isCartPage: true,
      // 当前店铺名称 
      companyName: '',
      // 选择赠品或优惠券tabs标识
      isSelectGoodsOrCoupon: 0,
      // 优惠券列表
      selectCouponList: [],
      // 正在请求购物车api标识
      isLoding: false,
      // 活动已选优惠券列表
      isSelectedCouponList: [],
      // 是否显示已选优惠券弹窗
      isShowSelectedCoupon: false,
      // 取消选择赠品标识 0-默认选择 1-可以取消选中赠品
      isCancelGift: 0,
      // 购物车所有商品cart_id
      allCartIdArr: []
    }
  },
  mounted() {
    this.$store.dispatch('getShopCart')
    this.GetcartdoCart();
    this.GetuseraddressList();
  },
  beforeDestroy() {
    this.isCartPage = false
  },
  watch: {
    sort() {
      this.GetNewday();
    },
    "price.price_min":
      function () {
        this.GetNewday()
      },
    "price.price_max":
      function () {
        this.GetNewday()
      },
    selectcartid_list(newVal) {
      if (newVal.length == this.alldata.lists.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    '$store.state.shopcart.shopCartInfo':
      function () {
        this.GetcartdoCart();
      }
  },
  methods: {
    getpageindex(data) {
      this.page_no = data;
      this.GetNewday();
    },
    // 活动弹窗选择后关闭操作
    close() {
      this.isShowMakeUpPopUp = false;
      this.Internal_pop = false;
      this.GetcartdoCart()
    },
    // 查询接口
    Getselect(data, type) {
      if (type == "sort") {
        this.sort = data.id
      } else {
        this.price.price_name = data.name;
        if (data.name.indexOf("以上") > -1) {
          this.price.price_min = 100;
          this.price.price_max = 99999;
        } else if (data.name.indexOf("元") > -1) {
          this.price.price_min = Number(data.name.split('-')[0]);
          this.price.price_max = Number(data.name.split('-')[1].replace('元', ""));
        } else {
          this.price.price_min = 0;
          this.price.price_max = 99999;
        }
      }
    },
    // 弹窗去换购商品
    getcartaddCartOther(item) {
      this.PopupText = item.label;
      this.activity_sn = item.activity_sn;
      this.GetNewday();
    },
    async GetNewday(state) {
      // 换购或凑单商品
      let is_activity_goods;
      // 活动商品
      if (state) {
        is_activity_goods = 1;
      } else {
        is_activity_goods = 0;
      }
      let params = {
        activity_sn: this.activity_sn,
        is_activity_goods: is_activity_goods,
        price_min: this.price.price_min,
        price_max: this.price.price_max,
        page: this.page_no,
        page_size: 4,
        sort: this.sort
      }
      // console.log('添加参数', params);

      let data = await getcartaddCartOther(params);
      if (state) {
        this.Internal_pop = true;

        if (data.data.data && data.data.data.length > 0) {
          let goods = data.data.data
          for (var a in goods) {
            goods[a]['is_selected'] = false
            let findGoods = this.alldata.lists.find(g => g.code == goods[a].code && g.activity_sn == goods[a].activity_sn)
            if (findGoods) goods[a]['is_selected'] = true
            this.$forceUpdate()
          }
          this.Internal_popdata = goods
        }

        if (data.data.coupon && data.data.coupon.length > 0) {
          let list = data.data.coupon
          for (var i in list) {
            list[i]['is_select'] = false
            let findCoupon = this.alldata.coupon_list.find(f => f.id == list[i].id && f.sn == list[i].sn)
            if (findCoupon) list[i]['is_select'] = true
            this.$forceUpdate()
          }
          this.selectCouponList = list
        }
      } else {
        this.isShowMakeUpPopUp = true;
        this.Product_data = data.data;
        this.is_disabled = this.Product_data.addon_number;
        this.pageInfo.index = this.Product_data.page_no;
        this.pageInfo.size = this.Product_data.page_size;
        this.pageInfo.total = this.Product_data.count;
        // console.log('弹窗数据', this.Product_data)
      }
    },
    // 优惠券选择
    GetselectedOptions(val) {
      this.$confirm(
        '您确认要选择该优惠券吗？',
        '温馨提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          let getCouponObj = {
            code: val.sn,
            activity_sn: this.activity_sn,
            is_activity_goods: 2
          }

          this.requestadd_card(1, getCouponObj)
          this.isShowMakeUpPopUp = this.Internal_pop = false
        })
        .catch(() => {
          let findCoupon = this.selectCouponList.find(i => i.id == val.id && i.sn == val.sn)
          if (findCoupon) findCoupon.is_select = false
        })
    },
    // 获取凑单列表顶部状态信息
    async getMakeUpMessage() {
      let params = {
        activity_sn: this.activity_sn,
        is_activity_goods: 0,
        price_min: this.price.price_min,
        price_max: this.price.price_max,
        page: this.page_no,
        page_size: 4,
        sort: this.sort
      }

      let data = await getcartaddCartOther(params);
      this.Product_data.addon_number = data.data.addon_number
      this.Product_data.message = data.data.message
    },
    toggleButton(button) {
      this.isActive = button;
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.items.length - 1;
      }
      this.selectedIndex = -1; // 点击左箭头时取消选中状态
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
      this.selectedIndex = -1; // 点击右箭头时取消选中状态
    },
    async defaultAddressChange(item) {
      this.address_id = item.id;
      await EditusersetDefault({ id: this.address_id });
    },
    async toggleSelectAll() {
      let is_select = 0
      if (this.selectAll) {
        is_select = 0
      } else {
        is_select = 1
      }

      let params = {
        cart_id: this.allCartIdArr,
        selected: is_select
      };
      let selectdata = await Editcartselected(params);
      if (selectdata.code == 1) this.GetcartdoCart();
    },
    toGoodsDetail(item) {
      this.$isRouterParams('ProductDetails', { data: JSON.stringify(item) })
    },
    goodsSelected(item) {
      if (item.is_activity_goods == 1 && item.selected == false) return this.$confirm(
        `您确认要取消选择赠品吗`,
        '温馨提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.isCancelGift = 1
          this.isGoodsSelectedChange(item)
        })
        .catch(() => {
          return item.selected = true
        })

      this.isGoodsSelectedChange(item)
    },
    // 商品左侧复选框改变
    async isGoodsSelectedChange(item) {
      let params = {
        cart_id: item.cart_id,
        selected: item.selected ? 1 : 0
      };
      let selectdata = await Editcartselected(params);
      if (selectdata.code == 1) this.GetcartdoCart();
    },
    async deleteSelected(item, index) {
      // 删除当前行
      let params = {
        cart_id_list: item.cart_id,
      };
      let selectdata = await Editcartbatch_del(params);
      if (selectdata.code == 1) {
        this.GetcartdoCart();
        this.$store.dispatch('getShopCart');
      }
    },
    // 删除所有选中行
    async Batch_Delete() {
      let params = {
        cart_id_list: this.selectcartid_list,
      };
      let selectdata = await Editcartbatch_del(params);
      if (selectdata.code == 1) {
        this.GetcartdoCart();
        this.$store.dispatch('getShopCart');
      }
    },

    // 购物车列表
    async GetcartdoCart() {
      if (this.isLoding) return false
      this.isLoding = true

      await GetcartdoCart({ page: this.isCancelGift }).then((res) => {
        console.log(res, '------购物车页面api---success')

        if (res.code == 1) {
          this.alldata = res.data;
          // 赋值选中的cart_id的list
          this.selectcartid_list = [];
          this.alldata.lists.forEach((item) => {
            this.allCartIdArr.push(item.cart_id)
            if (item.selected) {
              this.selectcartid_list.push(item.cart_id);
            }
          })

          this.isSelectedCouponList = this.alldata.coupon_list

          if (this.isShowMakeUpPopUp) this.getMakeUpMessage()

          this.isLoding = false
        } else {
          this.isLoding = false
        }
      }).catch((err) => {
        console.log(err, '------购物车页面api---error')
        this.isLoding = false
      })
    },

    // 店铺列表
    async GetuseraddressList() {

      let datalist = await GetuseraddressList();
      if (datalist.code == 1) {
        this.StoreList = datalist.data.account

        if (!this.StoreList[0].id) {
          this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: '抱歉, 请检查收货地址'
          })
        }
        this.address_id = this.$store.state.user.company_data.user_id
        this.companyName = this.$store.state.user.company_data.company
      }
    },
    // 去结算
    To_settle() {
      if (this.selectcartid_list.length == 0) return false
      this.$router.push({ path: './OrderSettlement', query: { address_id: this.address_id } })
    }
  }
}
</script>

<style lang="less" scoped>
.goods_info {
  text-align: left;
  margin-left: 10px;

  .specialoffer_title {
    .specialoffer {
      border: 1px solid #ff334c;
      border-radius: 5px;
      font-size: 12px;
      color: #ff334c;
      margin-right: 5px;
      padding: 0 3px;
    }

    .goods_name {
      align-items: flex-start;
      width: 120px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.common_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
  text-align: center;

  .name_shop {
    display: flex;
    flex: 5;
    justify-content: center;
  }

  .item_shop {
    flex: 18;
    display: flex;
    align-items: center;

    div {
      flex: 20;
    }
  }

  .goods_info_title {
    font-weight: 600;
  }
}

.header_form {
  box-sizing: border-box;
  margin-bottom: 16.923px;
  padding: 0 20px;
  cursor: pointer;

  .middle_large {
    div {
      &:nth-child(1) {
        margin-left: 20px;
      }

      &:nth-child(2) {
        margin-left: -10px;
      }
    }
  }
}

.select_Pack {
  background-color: #ffeeef;
  border: 1px solid #ff334c;
  color: #ff334c;
  border-radius: 5px;
  padding: 2px 2px;
}

.inpackage,
.Package {
  color: #1d2129;
  font-size: 10px;
  font-weight: 600;
}

.inpackage {
  margin-right: 5px;
}

/* 选中价格 */
.select_price {
  color: #ff334c;
}

/* 选中排序 */
.select_sort {
  color: #ff334c;
}

.lookshoping {
  margin-left: auto;
  padding: 0px 20px 0px 0px;
  /* margin: 0px 20px 0px 0px; */
}

.Scrape {
  margin: 0px 0px 0px 20px;
  color: #1d2129;
  font-size: 18px;
}

.box_end {
  padding: 0px 5px 0px 5px;
  width: 1000px;
  height: 60px;
  margin-top: auto;
}

.px_margin {
  cursor: pointer;
  margin: 0px 0px 0px 20px;
}

.Swap_Text {
  margin: 20px 0px 20px 0px;
  width: 950px;
  height: 40px;
  background: #ffeeef;
  color: #ff334c;
  padding: 0px 0px 0px 20px;
}

.Conditionalzone {
  margin-top: 10px;
  padding: 0px 0px 0px 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  width: 940px;
  height: 100px;
  color: #1d2129;
  font-size: 16px;
}

.PopupText {
  color: #1d2129;
  font-size: 24px;
  font-weight: 400;
}

.colsesize {
  margin: 10px;
  margin-left: auto;
  font-size: 20px;
}

.payment_modal {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  .tow_box {
    height: 900px;
    width: 1000px;
    overflow-x: auto;
  }
}

.Internal_modal {
  position: fixed;
  z-index: 10001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  .three_box {
    height: 600px;
    width: 1000px;
  }
}

.big_box {
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.carousel {
  position: relative;
  padding: 20px 20px 0px 20px;
}

.arrow {
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  width: 40px;
  height: 138px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 30px;
  line-height: 50px;
  cursor: pointer;
}

.left {
  left: 20px;
}

.right {
  right: 20px;
}

.slider {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.address_item {
  position: relative;
  padding: 10px 20px 20px;
  flex: 0 0 200px;
  width: 278px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #c9cdd4;

  &:before {
    width: 6px;
    height: 10px;
    border: 1px solid #fff;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
    display: block;
    content: "";
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 999;
  }

  &:after {
    display: block;
    border: 15px solid #ff334c;
    border-left: 15px solid transparent;
    border-top: 15px solid transparent;
    content: "";
    position: absolute;
    width: 0;
    right: 0;
    bottom: 0;
  }
}

.active {
  border-color: #ff334c;
}

.selected {
  border-color: #ff334c;
}

.shop_name {
  color: #1d2129;
  font-size: 14px;
  font-weight: 600;
}

.consignee {
  margin: 10px 0px 0px 20px;
  color: #1d2129;
  font-size: 18px;
  font-weight: 600;
}

.text_number {
  margin: 5px 0px 0px 0px;
  color: #1d2129;
  font-size: 14px;
}

.address {
  color: #1d2129;
  font-size: 14px;
  margin: 10px 0px 0px 0px;
}

.Scrape_together {
  padding: 10px 0px 20px 20px;
  margin: auto;
  width: 95%;
  border: 1px dashed #c9cdd4;
}

.Full_amount {
  color: #ffffff;
  font-size: 14px;
  border-radius: 5px;
  background: #ff334c;
  width: 100px;
  height: 30px;
}

.Poor_money {
  margin-left: 30px;
  color: #1d2129;
  font-size: 14px;
}

.for__box {
  margin: 10px 0px 0px 0px;
}

.stab {
  cursor: pointer;
  margin-right: 20px;
  color: #ff334c;
  font-size: 14px;
}

.shop_message {
  color: #1d2129;
  font-size: 18px;
  font-weight: 600;
}

.button_ {
  width: 88px;
  height: 30px;
  border: 1px solid #c9cdd4;
  background: #f2f3f5;
  box-sizing: border-box;
  border-radius: 5px;
}

.button_.active {
  border-color: #ff334c;
  background: #ffeeef;
  /* 选中状态下的背景颜色 */
}

.shop_box {
  padding: 10px 0px 0px 0px;
  width: 1160px;
  margin: auto;
}

.model_box {
  padding: 20px 0px 0px 0px;
  width: 1160px;
  margin: auto;
}

.model_input {
  width: 16px;
  height: 16px;
}

.model_color {
  color: #ff334c;
  cursor: pointer;

  span {
    margin-left: 5px;
  }
}

.gauge_outfit {
  height: 30px;
  border-bottom: 1px solid #e5e6eb;
  font-size: 14px;
  color: #1d2129;
}

.gauge_outfit_Footer {
  padding-top: 20px;
  font-size: 14px;
  color: #1d2129;
  cursor: pointer;
}

.gkc {
  color: #1d2129;
  font-size: 14px;
  font-weight: 600;
}

.specifications {
  font-size: 14px;
  color: #4e5969;
}

.period_validity {
  font-size: 14px;
  font-weight: 400;
  color: #ff334c;
}

.date {
  font-size: 14px;
  font-weight: 400;
  color: #4e5969;
}

.Price_Style {
  color: #1d2129;
  font-size: 14px;
}

.selectedItems_lenth {
  color: #86909c;
  font-size: 14px;
}

.Delete_product {
  color: #ff334c;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ff334c;
  padding: 2px 5px;
  border-radius: 5px;
}

.tablecollapse {
  border-collapse: collapse;
}

th,
td {
  color: #1d2129;
  font-size: 16px;
  text-align: center;
  width: 100px;
  height: 40px;
  border: 1px solid #ccc;
  padding: 8px;
}

.bddb {
  border-bottom: 1px dashed #ccc;
}

.gauge_titile {
  width: 1160px;
  margin: auto;
  margin-top: 20px;
  font-size: 14px;
  color: #4e5969;
  padding-bottom: 10px;
  font-weight: 600;
}

.gauge_titile_ {
  border: 1px solid #e5e6eb;
  width: 1160px;
  padding: 10px 0;
  margin: 20px auto 0;
  font-size: 14px;
  box-sizing: border-box;
  color: #4e5969;
}

.subtotal_categories {
  div {
    margin-right: 10px;
  }
}

.Merchandise_Subtotal {
  color: #86909c;
}

.RMB {
  font-size: 18;
  font-weight: 400;
  color: #ff334c;
}

.Total_price {
  height: 50px;
  padding-bottom: 20px;
  font-size: 18px;
}

.ccrd {
  font-size: 14px;
  font-weight: 600;
  color: #1d2129;
}

.Number_types {
  font-size: 12px;
}

.To_settle {
  border-radius: 5px;
  width: 155px;
  height: 45px;
  background: #ff334c;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
}

/deep/ .el-icon-delete {
  cursor: pointer;
}

/* 禁用i标签 */
/deep/.disabled-icon {
  color: #ccc;
  /* 设置为禁用状态下的颜色 */
  pointer-events: none;
  /* 禁止鼠标事件 */
}
</style>