import store from '@/store'

/**
 * @description 图片完整域名
 * @param uri { string } 图片链接
 * @return { string }
 */
export const getImageUri = (uri = '') => {
  const oss_domain = store.state.config.config.oss_domain || ''
  const re = /http/
  if (re.test(uri)) return uri
  // console.log(oss_domain, '----oss_domain2')
  // console.log(uri,'----uri')
  if (uri.substr(0, 1) == '/' && oss_domain.endsWith('/', 1)) {
    return oss_domain + uri.substr(1)
  } else if (uri.substr(0, 1) != '/' && oss_domain.endsWith('/', 1)) {
    return oss_domain + uri
  } else if (uri.substr(0, 1) == '/' && !oss_domain.endsWith('/', 1)) {
    return oss_domain + uri
  } else if (uri.substr(0, 1) != '/' && !oss_domain.endsWith('/', 1)) {
    return oss_domain + '/' + uri
  } else {
    return uri
  }
}

// 格式化年月日时分秒
export function dateFormat(time) {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let getDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  let day = date.getDay()
  let weekDay = ["周天", "周一", "周二", "周三", "周四", "周五", "周六"]

  var a = year + "." + month + "." + getDate + " " + hours + ":" + minutes + ":" + seconds
  var b = month + "." + getDate + " " + hours + ":" + minutes
  var c = month + '.' + getDate
  var d = hours + ":" + minutes
  var e = weekDay[day]
  var f = year + "." + month + "." + getDate

  return [a, b, c, d, e, f]
}
