import { getImageUri } from '@/utils/tools'
import { apiUpdateApp } from '@/api/index'

/**
 * 应用版本检验
 */
export function applyUpdateCheck() {
  let req = {
    appid: 'HBuilder',
    version: this.$store.state.config.applyVersion,
    name: 'Android',
    t: new Date().getTime()
  }
  // console.log(req, '------应用更新验证api---请求参数')

  apiUpdateApp(req).then(res => {
    // console.log(res.data, '------应用更新验证api---success')

    if (res.data.status == 1) {
      this.$store.commit('setApplyVersion', res.data.versions)
      this.$store.commit('setAppDownloadUrl', {
        androidUrl: res.data.url,
        iosUrl: res.data.ios_url
      })
    }
  }).catch(err => {
    console.log(err, '------应用更新验证api---error')
  })
}

/**
 * 路由跳转打开新标签页传参方法
 * @param { String } path 跳转页面name
 * @param { any } params 跳转参数
 */
export function isRouterParams(path, params, newPage = true) {
  let query = {
    showHomeTitle: '111',
  }
  let routeUrl = this.$router.resolve({ name: path, query: query })
  // debugger;
  this.$store.commit('setRouterParams', params ? params : '')
  if (!newPage) {
    window.location.href = routeUrl.href;
  } else {
    window.open(routeUrl.href, '_blank')
  }
}


/**
 * 查找商品购物车数量
 * @param { Object } item 商品信息对象
 * @param { Array } cartlist  购物车列表数据
 * @returns
 */
export async function findGoodname(item, cartlist) {
  item['activity_sn'] = item.activity_sn ? item.activity_sn : -1
  item['middle_package_status'] = false
  item['large_package_status'] = false

  let obj = {}
  if (cartlist) {
    // 从购物车列表查找是否包含当前商品
    obj = cartlist.data.find(
      (i) => i.goods_code == item.code && i.activity_sn == item.activity_sn
    )
    // 活动商品不显示阶梯价优惠
    let isNotActivityGoods = !item.activity_sn || item.activity_sn == -1

    if (obj) {
      if (item.step_price && item.step_price.length > 0 && isNotActivityGoods) {
        let arr = []
        let max = 1
        if (isNaN(obj.goods_num)) obj.goods_num = Number(obj.goods_num)

        for (var p in item.step_price) {
          arr.push(Number(item.step_price[p].end_num))
          max = Math.max(...arr)
          if (
            obj.goods_num >= Number(item.step_price[p].start_num) &&
            obj.goods_num <= Number(item.step_price[p].end_num)
          ) {
            item.sell_price = item.step_price[p].price
          } else if (obj.goods_num > max) {
            item.sell_price = item.goods_price
          }
        }
      }

      return obj.goods_num
    } else {
      return 0
    }
  }
}

/**
 * 首页跳转
 * @param { Object } e 跳转参数
 * @returns
 */
export function indexJumpClick(e, floor = false) {
  console.log(e, '------首页跳转参数')

  // mark: 秒杀-1 特价-2 换购-3 赠品-4 标签-5 品牌专区-6
  if (e.content.mark != 0) {
    if (e.content.path != '') {
      let showImg = ''
      let showRemark = ''
      if (e.show_img) showImg = getImageUri(e.pc_show_img)
      if (e.show_remark) showRemark = e.show_remark

      let data = {
        show_img: showImg,
        show_remark: showRemark
      }
      let showObj = stringIfyParams(data)

      switch (e.content.keywords) {
        // 秒杀
        case 'seckill':
          this.$isRouterParams('Zone', {
            mark: 1,
            activity_sn: e.content.code,
            keywords: e.content.keywords,
            data: showObj,
            title: e.title,
            img: floor ? e.img : e.pc_show_img
          })
          break

        // 特价
        case 'special':
          this.$isRouterParams('Zone', {
            mark: 2,
            activity_sn: e.content.code,
            keywords: e.content.keywords,
            data: showObj,
            title: e.title,
            img: floor ? e.img : e.pc_show_img
          })
          break

        // 换购
        case 'exchange':
          this.$isRouterParams('Zone', {
            mark: 3,
            activity_sn: e.content.code,
            keywords: e.content.keywords,
            data: showObj,
            title: e.title,
            img: floor ? e.img : e.pc_show_img
          })
          break

        // 赠品
        case 'giveaway':
          this.$isRouterParams('Zone', {
            mark: 4,
            activity_sn: e.content.code,
            keywords: e.content.keywords,
            data: showObj,
            title: e.title,
            img: floor ? e.img : e.pc_show_img
          })
          break

        // 标签
        case 'goods_label':
          this.$isRouterParams('Zone', {
            mark: 5,
            label_id: e.content.code,
            data: showObj,
            title: e.title,
            img: floor ? e.img : e.pc_show_img,
            showHomeTitle: floor ? '0' : '1'
          })
          break

        // 品牌专区
        case 'goods_brand':
          this.$isRouterParams('Zone', {
            mark: 6,
            brand_id: e.content.code,
            data: showObj,
            title: e.title,
            img: floor ? e.img : e.pc_show_img,
            showHomeTitle: floor ? '0' : '1'
          })
          break
		  // 品牌专区
		  case 'brand':
		    this.$isRouterParams('gategory')
		    break

        // 商品详情
        case 'goods_detail':
          this.$isRouterParams('ProductDetails', { data: JSON.stringify(e.content) })
          break

        // 购物车页面
        case 'cart':
          this.$isRouterParams('ShoppingCart')
          break

        // 我的页面
        case 'user_info':
          this.$isRouterParams('PersonalCenter')
          break

        // 直播间页面
        case 'live_room':
          this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: '抱歉, 请使用APP进入直播间'
          })
          break

        // 首页
        case 'index':
          if (this.$route.path == '/home') {
            this.$router.go(0)
          } else {
            this.$router.push({ path: './home' })
          }
          break

        // 领券中心
        case 'coupon':
          this.$isRouterParams('Couponcenter')
          break

        // 自定义链接
        case 'custom_links':
          window.open(e.content.code, '_blank')
          break

        // 默认直接跳转
        default:
          this.$router.push({ path: './home' })
          break
      }
    } else {
      this.$message({
        type: 'warning',
        offset: this.$store.state.config.outer_height,
        message: '抱歉, 暂无跳转路径请稍候重试'
      })
      return false
    }
  } else {
    this.$message({
      type: 'warning',
      offset: this.$store.state.config.outer_height,
      message: '抱歉, 暂无数据请稍候重试'
    })
    return false
  }
}

/**
 * 跳转路径参数过长处理
 * @param { any } data 路径参数
 * @returns
 */
export function stringIfyParams(data) {
  let result = JSON.stringify(data)
  if (result.indexOf('%') > -1) {
    return result.replace(/%/g, '%25')
  } else {
    return result
  }
}
