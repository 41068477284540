// import Detail from "@/pages/Detail/index";
import Home from '@/pages/Home/index'
import Login from '@/pages/Login/index'
import gategory from '@/pages/gategory/gategory/index'
import ProductDetails from '@/pages/gategory/ProductDetails/index'

import ValueExchange from '@/pages/ValueExchange/index'
import Ltfk from '@/pages/Ltfk/index'
import Couponcenter from '@/pages/Couponcenter/index'
import smc from '@/pages/smc/index'
import messagecenter from '@/pages/messagecenter/index'
import ShoppingCart from '@/pages/ShoppingCart/index'
import OrderSettlement from '@/pages/ShoppingCart/OrderSettlement/index'
import payment from '@/pages/ShoppingCart/payment/index'
import Search from '@/pages/Search/index'
import PersonalCenter from '@/pages/PersonalCenter/index'
import Zone from '@/pages/Zone/index'

export default [
  {
    path: '/',
    component: Login,
    meta: { show: true, title: '拼药药登录', showHomeTitle: false },
    name: '/',
  },
  // 首页
  {
    path: '/home',
    component: Home,
    meta: { show: true, title: '拼药药首页', showHomeTitle: true },
    name: 'home',
  },
  // // 首页商品分类
  {
    path: '/gategory',
    component: gategory,
    name: 'gategory',
    meta: { title: '商品分类', showHomeTitle: true },
  },
  // 商品详情
  {
    path: '/ProductDetails',
    component: ProductDetails,
    name: 'ProductDetails',
    meta: { title: '商品详情', showHomeTitle: true },
  },

  // 超值换购
  {
    path: '/ValueExchange',
    component: ValueExchange,
    meta: { show: false, title: '超值换购', showHomeTitle: true },
    name: 'ValueExchange',
  },
  // 限时秒杀
  {
    path: '/Ltfk',
    component: Ltfk,
    meta: { show: true, title: '限时秒杀', showHomeTitle: false },
    name: 'Ltfk',
  },
  // 领劵中心
  {
    path: '/Couponcenter',
    component: Couponcenter,
    meta: { show: true, title: '领券中心', showHomeTitle: true },
    name: 'Couponcenter',
  },
  // 商城咨询
  {
    path: '/smc',
    component: smc,
    meta: { show: true, title: '商城公告', showHomeTitle: true },
    name: 'smc',
  },
  // 消息中心
  {
    path: '/messagecenter',
    component: messagecenter,
    meta: { show: true, title: '消息中心', showHomeTitle: true },
    name: 'messagecenter',
  },
  // 购物车
  {
    path: '/ShoppingCart',
    component: ShoppingCart,
    meta: { show: true, title: '购物车', showHomeTitle: true },
    name: 'ShoppingCart',
  },
  // 购物车订单结算
  {
    path: '/OrderSettlement',
    component: OrderSettlement,
    meta: { show: true, title: '订单结算', showHomeTitle: true },
    name: 'OrderSettlement',
  },
  // 支付页
  {
    path: '/payment',
    component: payment,
    meta: { show: true, title: '订单支付', showHomeTitle: true },
    name: 'payment',
  },
  // 搜索页
  {
    path: '/Search',
    component: Search,
    meta: { show: true, title: '商品搜索', showHomeTitle: true },
    name: 'Search',
  },
  // 个人中心
  {
    path: '/PersonalCenter',
    component: PersonalCenter,
    meta: { show: true, title: '个人中心', showHomeTitle: true },
    name: 'PersonalCenter',
  },
  // 专区页面
  {
    path: '/Zone',
    component: Zone,
    meta: { show: true, title: '专区', showHomeTitle: true },
    name: 'Zone',
  }
]
