<template>
  <div class="big_box d-flex flex-column" v-if="alldata">
    <!-- 收货信息 -->
    <div class="d-flex flex-column bg-white carousel" style="height: 394px;">
      <div class="d-flex consignee">收货信息</div>
      <div v-if="alldata.address">
        <div class="item d-flex flex-column">
          <div class="shop_name">{{ companyName ? companyName : '' }}</div>
          <div class="d-flex text_number">
            <div>{{ alldata.address.contact }}</div>
            <div style="margin-left: 10px;">{{ alldata.address.mobile }}</div>
          </div>
          <div class="address">{{ alldata.address.address }}</div>
        </div>
      </div>
      <div v-else class="to_cart_page">
        <router-link to="/ShoppingCart"><span>去选默认收货地址</span></router-link>
      </div>
      <!-- 配送方式及随货信息-->
      <div class="d-flex flex-column">
        <div class="d-flex flex-column">
          <div class="consignee">配送方式</div>
          <div>
            <el-radio v-for="item in Delivery_list" :key="item.id" v-model="Delivery_method" :label="item.id">{{
              item.name }}</el-radio>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="consignee">随货信息</div>
          <div>
            <el-checkbox-group v-model="Accompanying_information">
              <el-checkbox v-for="(item, index) in Accompanying_list" :key="index" :label="item"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <!-- 可用代金券 -->
    <div class="bg-white coupon_list">
      <el-tabs v-model="voucher">
        <el-tab-pane label="可用代金券" name="voucher_true">
          <voucher @GetselectedOptions="GetselectedOptions" :list="can_use" :input_state="true"></voucher>
        </el-tab-pane>
        <el-tab-pane label="不可用代金券" name="voucher_false">
          <voucher input_state is_disabled_checkbox :list="not_can_use"></voucher>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 订单备注 -->
    <div class="d-flex flex-column bg-white order_remark">
      <div class="shop_message">
        订单备注
      </div>
      <div style="margin-top: 10px;">
        <el-input type="textarea" v-model="orderRemark" :rows="3" placeholder="若有所需，您可在此处填写告诉我们" maxlength="100" show-word-limit clearable>
        </el-input>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="bg-white footer_goods_info">
      <div class="d-flex justify-content-between align-items-center shop_box">
        <div class="shop_message">
          商品信息
        </div>
        <div class="d-flex">
          <button style="margin-right: 10px;" class="button_" :class="{ active: isActive === 'button1' }" @click="toggleButton('button1')">
            购物模式
          </button>
          <button class="button_" :class="{ active: isActive === 'button2' }" @click="toggleButton('button2')">
            清单模式
          </button>
        </div>
      </div>
      <!-- 购物模式 -->
      <div v-if="isActive == 'button1'" class="header_form">
        <div class="common_flex gauge_outfit">
          <div class="name_shop">商品信息</div>
          <div class="item_shop">
            <div>零售价</div>
            <div>毛利率</div>
            <div>供货价</div>
            <div>券后价</div>
            <div>中包/件装</div>
            <div>数量</div>
            <div>小计</div>
          </div>
        </div>
        <div class="common_flex" v-for="(item, index) in alldata.goods" :key="index">
          <div class="d-flex" style="width: 280px;">
            <div class="d-flex">
              <img style="width: 100;height: 100px;" :src="$getImageUri(item.image)" alt="抱歉图片加载失败" />
            </div>
            <div class="d-flex flex-column justify-content-between goods_info">
              <div class="specialoffer_title">
                <span v-if="item.activity_type_text" :style="Getpickcolor(item.activity_type_text)" class="specialoffer">
                  {{ item.activity_type_text }}活动</span>
                <span class="goods_name">{{ item.name }}</span>
              </div>
              <div class="specifications">{{ item.spec }}</div>
              <div class="d-flex">
                <div class="period_validity">效期优于</div>
                <div class="date">{{ item.expired_date }}</div>
              </div>
              <div class="date">{{ item.supplier_name }}</div>
            </div>
          </div>
          <div class="item_shop">
            <div>¥{{ item.cost_price }}</div>
          </div>
          <div class="item_shop">
            <div>{{ item.rate }}%</div>
          </div>
          <div class="item_shop">
            <div>¥{{ item.lineation_price }}</div>
          </div>
          <div class="item_shop">
            <div>¥{{ item.sell_price }}</div>
          </div>
          <div class="d-flex item_shop middle_large">
            <div>{{ item.middle_package_number ? item.middle_package_number : 0 }}</div>
            <div>{{ item.large_package_number ? item.large_package_number : 0 }}</div>
          </div>
          <div class="item_shop">
            <div style="font-weight: 700;">x{{ item.goods_num }}</div>
          </div>
          <div class="Price_Style item_shop">
            <div>{{ item.sub_price }}</div>
          </div>
        </div>
        <div v-if="alldata.money" class="price_detail">
          <div></div>
          <div>
            <div class="CalculatePrice">
              <div>供货价：</div>
              <label>+¥{{ alldata.money.goods_origin_price }}</label>
            </div>
            <div class="CalculatePrice discount_reduction">
              <div>代金券：</div>
              <label>-¥{{ alldata.money.discount_sell_amount.replace('-', '') }}</label>
            </div>
            <div class="CalculatePrice">
              <div>券后价：</div>
              <label>+¥{{ alldata.money.goods_sell_price }}</label>
            </div>
            <div class="CalculatePrice discount_reduction">
              <div>活动减免：</div>
              <label>-¥{{ alldata.money.discount_promotion_amount.replace('-', '') }}</label>
            </div>
            <div class="CalculatePrice">
              <div>基础运费：</div>
              <label>+¥{{ alldata.money.express_price }}</label>
            </div>
            <div class="CalculatePrice discount_reduction">
              <div>运费减免：</div>
              <label>-¥{{ alldata.money.discount_express_amount.replace('-', '') }}</label>
            </div>
            <div class="CalculatePrice discount_reduction">
              <div>优惠券减免：</div>
              <label>-¥{{ alldata.money.discount_goods_amount.replace('-', '') }}</label>
            </div>
            <div class="CalculatePrice discount_reduction">
              <div>现金券减免：</div>
              <label>-¥{{ alldata.money.discount_money_amount.replace('-', '') }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- 清单模式 -->
      <div class="d-flex flex-column" v-if="isActive == 'button2'">
        <div class="d-flex justify-content-between gauge_titile" style="font-weight: 550;">
          <div>商品名称</div>
          <div>规格</div>
          <div>单价</div>
          <div>数量</div>
          <div>金额(元)</div>
        </div>
        <div v-for="(items, indexs) in alldata.category_list" :key="indexs" class="d-flex flex-column align-items-center justify-content-center">
          <div v-for="(items_, indexs_) in items.index" :key="indexs_" class="d-flex align-items-center justify-content-between gauge_titile_">
            <div>{{ items_.name }}</div>
            <div>{{ items_.spec }}</div>
            <div>¥{{ items_.price }}</div>
            <div>{{ items_.number }}</div>
            <div>¥{{ items_.amount }}</div>
          </div>
          <div class="d-flex justify-content-between gauge_titile">
            <div class="d-flex subtotal_categories">
              <div class="ccrd">{{ items.name }}</div>
              <div class="Number_types">合计{{ items.goods_id_number }}种</div>
              <div class="Number_types">数量{{ items.goods_number }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="Merchandise_Subtotal">商品小计：</div>
              <div class="RMB"><span style="font-size: 14px;">¥</span>{{ items.amount }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center" style="padding: 50px 20px 20px 20px;">
        <div class="d-flex align-items-center pay_tips">
          <i class="el-icon-warning-outline"></i>
          <span>温馨提示：请先验收再收货，非药品质量问题恕不退换，如发现药品短少请在收到药品两日内解决！</span>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div class="Merchandise_Subtotal">应付金额：</div>
            <div v-if="alldata.money" class="RMB"><span style="font-size: 16px;">¥</span>{{ alldata.money.order_amount }}
            </div>
          </div>
          <div @click="To_settle" class="d-flex justify-content-center align-items-center To_settle">
            提交订单
          </div>
        </div>
      </div>
    </div>

    <Loading v-if="isLoading" :Text="loadingMessage"></Loading>
  </div>
</template>

<script>
import addCartCheck from '@/mixins/addCartCheck'
import { GetorderplaceOrder, getCouponList, GetorderExpressIdList, GetorderOtherInfoList, submitOrder, GetcheckOrderSubmit } from "@/api";
export default {
  name: 'OrderSettlement',
  mixins: [addCartCheck],
  data() {
    return {
      isLoading: false,
      order_sn: "",  // 生成订单号
      can_use: [],   // 可用优惠券
      not_can_use: [],   // 不可用优惠券
      Delivery_list: [],  // 配送方式以及随货信息
      Accompanying_list: [],   // 随货信息
      coupon_list: [],  // 子组件选中的优惠券id列表
      alldata: {},
      address_id: "",
      Delivery_method: 2,   // 配送方式
      Accompanying_information: ['质检'],  // 随货信息
      voucher: 'voucher_true',
      orderRemark: '',  // 订单备注
      isActive: 'button1', // 记录当前选中的按钮
      // 提交订单定时器
      submitOrderTimer: null,
      // 页面提示信息弹窗
      loadingMessage: '正在生成订单中，请稍候..'
    }
  },
  mounted() {
    this.GetorderplaceOrder();
    this.getCouponList();
    this.GetorderExpressIdList();
    this.GetorderOtherInfoList();
  },
  beforeDestroy() {
    this.clearTimer()
    this.$store.dispatch('getShopCart')
  },
  methods: {
    // 提交订单
    async To_settle() {
      let params = {
        address_id: this.address_id, // 用户地址id
        coupon_list: this.coupon_list, // 选择优惠券id数组
        other_info: this.Accompanying_information, // 可选信息 【文字】数组
        remark: this.orderRemark, // 订单备注
        express_id: this.Delivery_method, // 可选信息 其他快递id
      }
      console.log(params, '------提交订单参数')
      this.isLoading = true;

      let data = await submitOrder(params)
      if (data.code == 1) {
        console.log(data, '------提交订单api---success')

        this.isCheckOrderSubmit()
      } else {
        console.log(data, '------提交订单api---error')
        this.isLoading = false
        return this.$message({
          type: 'warning',
          offset: this.$store.state.config.outer_height,
          message: `${data.msg}`
        })
      }
    },
    // 轮询生成订单状态
    isCheckOrderSubmit() {
      this.submitOrderTimer = setInterval(() => {
        GetcheckOrderSubmit().then((res) => {
          console.log(res, '------生成订单状态api---success')

          if (res.code == 1 && res.data.order_sn != '') {
            this.clearTimer()
            this.order_sn = res.data.order_sn;
            this.$router.push({ path: "/payment", query: { order_sn: this.order_sn } })
            this.$store.dispatch('getShopCart')

            this.isLoading = false
          } else if (res.code == 0) {
            this.clearTimer()
            this.loadingMessage = res.msg
            setTimeout(() => {
              this.isLoading = false
            }, 1000)
          } else {
            this.loadingMessage = res.msg
          }
        }).catch((err) => {
          console.log(err, '------生成订单状态api---error')
          this.clearTimer()
          this.$message({
            type: 'warning',
            offset: this.$store.state.config.outer_height,
            message: '抱歉，生成订单失败，请稍候重试'
          })

          this.$router.go(-1)
        })
      }, 1000);
    },
    // 清除生成订单定时器
    clearTimer() {
      clearInterval(this.submitOrderTimer)
      this.submitOrderTimer = null
    },
    toggleButton(button) {
      this.isActive = button;
    },
    // 结算页数据
    async GetorderplaceOrder() {
      this.address_id = this.$route.query.address_id;
      let params = {
        address_id: this.address_id,   // 用户收货id
        coupon_list: this.coupon_list    // 使用优惠券id列表
      };
      let datalist = await GetorderplaceOrder(params);

      if (datalist.code) {
        this.alldata = datalist.data;
        this.companyName = this.$store.state.user.company_data.company
        console.log(this.alldata, '------订单结算信息---success')
      }
    },
    // 获取可用和不可用优惠券
    async getCouponList() {
      let CouponList = await getCouponList({ address_id: this.address_id });
      this.can_use = this.getcoupon(CouponList.data.can_use)
      this.not_can_use = this.getcoupon(CouponList.data.not_can_use)
    },
    // 配送方式
    async GetorderExpressIdList() {
      let datalist = await GetorderExpressIdList();
      this.Delivery_list = datalist.data;
    },
    getcoupon(data) {
      let alist = [];
      for (let a in data) {
        data[a].list.forEach((item, index) => {
          item.is_select = false;
          alist.push(item)
        })
      }
      return alist;
    },
    // 随货信息
    async GetorderOtherInfoList() {
      let datalist = await GetorderOtherInfoList();
      this.Accompanying_list = datalist.data;
    },
    GetselectedOptions(val) {
      this.coupon_list = [];
      this.coupon_list.push(val.id)

      this.GetorderplaceOrder()
    }
  }
}
</script>

<style lang="less" scoped>
.goods_info {
  text-align: left;
  margin-left: 10px;

  .specialoffer_title {
    .specialoffer {
      border: 1px solid #ff334c;
      border-radius: 5px;
      background-color: #ffeeef;
      font-size: 12px;
      color: #ff334c;
      margin-right: 5px;
      padding: 0 3px;
    }

    .goods_name {
      align-items: flex-start;
      width: 120px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.common_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #e5e6eb;

  .name_shop {
    display: flex;
    flex: 5;
    justify-content: center;
  }

  .item_shop {
    flex: 16;
    display: flex;
    align-items: center;

    div {
      flex: 20;
    }
  }
}

.header_form {
  box-sizing: border-box;
  margin-bottom: 16.923px;
}

.big_box {
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto;
}

.item {
  padding: 10px 20px 10px;
  flex: 0 0 200px;
  height: 125px;
  width: 278px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #ff334c;
}

.shop_name {
  color: #1d2129;
  font-size: 14px;
  font-weight: 600;
}

.text_number {
  margin: 5px 0px 0px 0px;
  color: #1d2129;
  font-size: 14px;
}

.address {
  color: #1d2129;
  font-size: 14px;
}

.consignee {
  margin: 10px 0;
  color: #1d2129;
  font-size: 18px;
  font-weight: 600;
}

.coupon_list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-top: 20px;
}

.to_cart_page {
  margin: 10px 0 0 20px;

  span {
    font-weight: 600;
    color: #ff334c;
  }
}

.carousel {
  padding: 20px 0px 0px 20px;
  line-height: 30px;
}

.order_remark {
  margin-top: 20px;
  padding: 10px 20px 20px 20px;
}

.footer_goods_info {
  margin-top: 20px;
}

.price_detail {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  font-size: 14px;
  padding: 20px 20px 0;
}

.shop_box {
  padding: 10px 0px 0px 0px;
  width: 1160px;
  margin: auto;
}

.shop_message {
  color: #1d2129;
  font-size: 18px;
  font-weight: 600;
}

.button_ {
  width: 88px;
  height: 30px;
  border: 1px solid #c9cdd4;
  background: #f2f3f5;
  box-sizing: border-box;
  border-radius: 5px;
}

.button_.active {
  border-color: #ff334c;
  background: #ffeeef;
  /* 选中状态下的背景颜色 */
}

.model_box {
  padding: 20px 0px 0px 0px;
  width: 1160px;
  margin: auto;
}

.gauge_outfit {
  border-bottom: 1px solid #e5e6eb;
  font-size: 14px;
  color: #1d2129;
}

.gauge_titile {
  width: 1160px;
  margin: auto;
  margin-top: 20px;
  font-size: 14px;
  color: #4e5969;
}

.gauge_titile_ {
  border: 1px solid #e5e6eb;
  width: 1160px;
  height: 40px;
  margin: auto;
  margin-top: 20px;
  font-size: 14px;
  color: #4e5969;
}

.subtotal_categories {
  div {
    margin-right: 10px;
  }
}

.model_color {
  color: #ff334c;
}

.model_input {
  width: 16px;
  height: 16px;
}

.selectedItems_lenth {
  color: #86909c;
  font-size: 14px;
}

.Delete_product {
  color: #ff334c;
  font-size: 14px;
}

.pay_tips {
  font-size: 14px;
  color: #86909c;
}

.Merchandise_Subtotal {
  font-size: 18px;
  color: #86909c;
}

.RMB {
  font-size: 24px;
  font-weight: 400;
  color: #ff334c;
}

.To_settle {
  margin: 0px 0px 0px 20px;
  border-radius: 5px;
  width: 155px;
  height: 45px;
  background: #ff334c;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
}

.ccrd {
  font-size: 14px;
  font-weight: 600;
  color: #1d2129;
}

.Number_types {
  font-size: 14px;
}

.CalculatePrice {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 180px;

  label {
    margin: 0px 0px 0px 30px;
  }
}

.discount_reduction {
  label {
    color: #ff334c;
  }
}
</style>