<template>
	<div>
		<div class="py-container d-flex flex-column">
			<div class="d-flex justify-content-center align-items-center">
				<el-card class="box-card">
					<div v-for="(item, rowIndex) in alltype_list" :key="rowIndex" class="d-flex  text">
						<label style="width:auto;">{{ item.name }}</label>
						<div class="d-flex" style="flex-wrap: wrap;flex: 1;">
							<div tabindex="0" v-for="(items, colIndex) in item.data" :key="colIndex"
								class="d-flex data_class" :class="{ 'selected': isSelected(rowIndex, colIndex) }"
								@click="selectItem(item.id, items.id, rowIndex, colIndex, 1)">
								{{ items.name }}
							</div>
						</div>
					</div>
				</el-card>
			</div>
			<NoData v-if="!tyoe_list.length"></NoData>
			<div v-else>
				<commodity @pageindex="getpageindex" :tyoe_list="tyoe_list" :pageInfo="pageInfo"></commodity>
			</div>
		</div>
	</div>
</template>

<script>
	import NoData from '@/components/NoData/index';
	import {
		GetClassificationList,
		GetbrandList,
		GetGoodslists
	} from "@/api";
	export default {
		name: "gategory",
		components: {
			NoData
		},
		data() {
			return {
				pageInfo: {},
				data_params: {
					category_id: '', // 分类id
					brand_id: '', // 品牌id
					stock: 0, // 库存状态
					expired: 0, // 有效期
					sort: 0, // 排序
					page_size: 10,
					page_no: 1
				},
				tyoe_list: [],
				selectedRow: null,
				selectedCol: null,
				selectedItems: [], // 存储选中的行和列
				alltype_list: [{
					name: '分类：',
					id: 'classification',
					data: []
				}, {
					name: '品牌：',
					id: 'brand',
					data: []
				}, {
					name: '库存：',
					id: 'inventory',
					data: [{
						name: '全部',
						id: 0
					}, {
						name: '有库存',
						id: 1
					}]
				}, {
					name: '效期：',
					id: 'Validity_period',
					data: [{
						name: '全部',
						id: 0
					}, {
						name: '临期商品',
						id: 1
					}]
				}, {
					name: '排序：',
					id: 'sort',
					data: [{
						name: '智能排序',
						id: 0
					}, {
						name: '价格从低到高',
						id: 1
					}, {
						name: '销量从高到低',
						id: 2
					}, {
						name: '人气从高到低',
						id: 3
					}]
				}]
			}
		},
		created() {
			// 获取分类列表其余数据
			this.getallList(true);
		},
		computed: {
			class_id() {
				return this.$params.type
			},
			router_id() {
				return this.$params.id
			},
			isSelected() {
				return (rowIndex, colIndex) => {
					return this.selectedItems.some(item => item.row === rowIndex && item.col === colIndex);
				};
			}
		},
		methods: {
			getpageindex(data) {
				// console.log('当前页数', data)
				this.data_params.page_no = data;
				this.GetGoodslists();
			},
			change(index) {
				this.currentIndex = index
			},
			selectItem(classid, id, rowIndex, colIndex, mark) {
				// mark 1-点击重新获取列表 0-查询不获取
				switch (classid) {
					case 'classification':
						this.data_params.category_id = id;
						break;
					case 'brand':
						this.data_params.brand_id = id;
						break;
					case 'inventory':
						this.data_params.stock = id;
						break;
					case 'Validity_period':
						this.data_params.expired = id;
						break;
					case 'sort':
						this.data_params.sort = id;
						break;
					default:
						break;
				}
				const index = this.selectedItems.findIndex(item => item.row === rowIndex);

				if (index > -1) {
					// 该行已经被选中过，更新对应元素的列
					this.selectedItems[index].col = colIndex;
				} else {
					// 该行未被选中过，添加该行和列
					console.log('选中行列', rowIndex, colIndex)
					this.selectedItems.push({
						row: rowIndex,
						col: colIndex
					});
				}

				if (mark == 1) this.GetGoodslists()
			},
			async getallList(init) {
				let Classification_List = await GetClassificationList();
				console.log(Classification_List)
				let brand_List = await GetbrandList();
				console.log(brand_List)
				this.alltype_list.forEach((item, index) => {
					if (item.id == 'classification') {
						this.alltype_list[index].data = Classification_List.data;
						this.alltype_list[index].data.unshift({
							name: '全部分类',
							id: ''
						})
					} else if (item.id == 'brand') {
						this.alltype_list[index].data = brand_List.data;
						this.alltype_list[index].data.unshift({
							name: '全部品牌',
							id: ''
						})
					}
				});
				this.selectAllFirstItem(init)
			},
			async GetGoodslists() {
				console.log(this.data_params, '------this.data_params')

				let Classification_List = await GetGoodslists(this.data_params);
				console.log(Classification_List, '------商品分类列表')

				if (Classification_List.code == 1) {
					this.tyoe_list = Classification_List.data.data;
					this.pageInfo.index = Classification_List.data.page_no;
					this.pageInfo.size = Classification_List.data.page_size;
					this.pageInfo.total = Classification_List.data.count;
				}
			},
			async selectAllFirstItem(init) {
				let handle = new Promise((resolve) => {

					for (let i = 0; i < this.alltype_list.length; i++) {
						if (this.alltype_list[i].id == "classification" && this.router_id && !this.class_id) {
							this.selectItem('', '', i, Number(this.router_id))
						} else {
							this.selectItem('', '', i, 0)
						}

						if (this.class_id && this.router_id) this.selectItem(this.class_id, this.router_id, 1,
							Number(this.router_id))
					}

					resolve()
				})

				handle.then(() => {
					if (init) {
						let row = this.alltype_list.findIndex(item => item.id === "brand");
						let col = this.alltype_list[row].data.findIndex(item => item.id === this.router_id);
						this.selectItem(this.class_id, this.router_id, row, col)
					}
					this.GetGoodslists();
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.py-container {
		z-index: 1;
		position: relative;
		width: 1200px;
		margin: 0 auto;
	}

	.text {
		border-bottom: 1px dashed #e5e6eb;
		font-size: 14px;
		padding: 10px 0px 10px 0px;
	}

	.data_class {
		cursor: pointer;
		margin: 0px 0px 5px 30px;
		// border: 1px solid red;
	}

	.data_class:hover {
		color: #ff334c;
	}

	.box-card {
		width: 100%;
	}

	.active {
		color: red;
	}

	.selected {
		background-color: #ffffff;
		/* 设置选中时的背景颜色 */
		color: #ff334c;
		/* 设置选中时的文字颜色 */
	}
</style>