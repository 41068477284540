<template>
  <div class="big_box bg-white d-flex flex-column">
    <div class="title d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-between">
        <div class="d-flex Jump_home" @click="Jump_home">
          <img src="./images/logintop.png" title="点击回到首页" alt="抱歉图片加载失败">
        </div>
        <div class="d-flex ">
          <div class="d-flex">
            <el-input @keyup.enter.native="Search_click" class="search_input" v-model="input" placeholder="请输入要搜索的商品名称"
              @input="searchInputChange" clearable></el-input>
            <el-button @click="Search_click" class="search_butoon d-flex justify-content-center"
              type="primary">搜索</el-button>
          </div>
          <!-- <el-button ref="Shopping_Cart" id="Shopping_Cart" class="Shopping_btn d-flex justify-content-center"
                        type="primary">购物车({{ Shopping_Cart }})</el-button> -->
        </div>
      </div>
      <div class="d-flex tab_index align-items-end">
        <!-- <el-button @click="isCollapse" class="tab_btn d-flex align-items-center" type="primary">
          <img src="./images/type_btn.png" alt="图片已丢失" />
          <label>全部商品分类</label>
        </el-button> -->
        <el-tabs class="d-flex home_tabs" v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="首页" name="Home"></el-tab-pane>
          <!-- <el-tab-pane label="超值换购" name="configuration"></el-tab-pane>
          <el-tab-pane label="限时秒杀" name="role"></el-tab-pane> -->
          <el-tab-pane label="领券中心" name="Couponcenter"></el-tab-pane>
          <el-tab-pane label="商城资讯" name="smc"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '../../Search/js/eventBus.js'
export default {
  name: "home_Title",
  components: {
  },
  data() {
    return {
      activeName: 'Home',
      input: '',
      showHomeTitle: '1'
    }
  },
  mounted() {
    console.log(this.$route.params, ' this.$route.params')
    console.log(this.$store.state.config.router_params.SecectIndex, ' this.$store.state.config.router_params.SecectIndex');
    if (this.$params.input) this.input = this.$params.input
    if (this.$params.showHomeTitle) this.showHomeTitle = this.$params.showHomeTitle
    if (this.$store.state.config.router_params.SecectIndex) this.activeName = this.$store.state.config.router_params.SecectIndex

  },
  watch: {
    '$store.state.config.router_params': {
      handler(newVal) {
        this.input = newVal.input
        console.log(newVal, '----newVal')
        if (newVal.SecectIndex) {
          this.activeName = newVal.SecectIndex;
        }
      },
      deep: true
    }
  },
  computed: {
    login_() {
      return this.$store.state.user.userInfo.name;
    },
    Shopping_Cart() {
      return this.$store.state.shopcart.shopCartInfo.length
    }
  },
  methods: {
    isCollapse() {
      this.$isRouterParams('gategory', { id: "" })
    },
    Jump_home() {
      // this.handleClick({ index: '0' })
      this.$isRouterParams('Home', { SecectIndex: 'Home' }, true)
      // this.$router.push({ path: "./home" })
    },
    sing(name) {
      // console.log('refname',name)
      this.activeName = name;
    },
    handleClick(tab) {
      console.log(tab, '-----tab')
      switch (Number(tab.index)) {
        case 0:
          // // 首页
          // this.$router.push({ path: '/home' })
          // break;
          this.$isRouterParams('Home', { SecectIndex: 'Home' }, false)
          break;
        // case 1:
        //   // 超值换购
        //   this.$isRouterParams('ValueExchange')
        //   break;
        // case 2:
        //   // 限时秒杀
        //   this.$isRouterParams('Ltfk')
        //   break;
        case 1:
          // 领劵中心
          this.$isRouterParams('Couponcenter', { SecectIndex: 'Couponcenter' }, false)
          break;
        case 2:
          // 商城咨询
          this.$isRouterParams('smc', { SecectIndex: 'smc' }, false)
          break;
        default:
          break;
      }
    },
    searchInputChange() {
      this.$store.commit('setRouterParams', { input: this.input })
    },
    Search_click() {
      if (!this.input && this.$router.currentRoute.name != 'Search') return false

      eventBus.$emit('Search_input', this.input)

      if (this.$router.currentRoute.name == 'Search') {
        this.$store.commit('setRouterParams', { input: this.input })
        this.$router.push({ name: 'Search' })
      } else {
        this.$isRouterParams('Search', { input: this.input })
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ [data-v-61dfc2cb] .el-tabs--card>.el-tabs__header .el-tabs__nav {
  z-index: 1 !important;
  background-color: #ffffff !important;
}

/deep/.el-input__inner {
  border: 1px solid #ff334c;
}

/deep/ .el-icon-arrow-down:before {
  content: none !important;
}

/deep/.el-breadcrumb__inner {
  color: #000000;
}

/deep/.el-tabs__nav {
  padding: 0px 0px 0px 30px;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: none;
}

/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: red;
}

/deep/.el-tabs__item.is-active {
  color: #ff334c;
}

/deep/.el-tabs__item:hover {
  color: #ff334c;
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
  /* color: #000000; */
  font-size: 18px;
  border-left: none;
  margin-right: 40px;
}

.big_box {
  padding: 20px 0px 0px 0px;
  margin-bottom: 20px;
  z-index: 2;
  width: 100%;

  .title {
    font-size: 12px;
    width: 1200px;
    margin: 0 auto;

    .search_butoon {
      border: 1px solid #ff334c;
      background-color: #ff334c;
      height: 40px;
      width: 60px;
    }

    .search_input {
      width: 400px;
    }

    .Shopping_btn {
      position: relative;
      z-index: -1;
      border: 1px solid #ff334c;
      /* border-radius: 5px; */
      color: #ff334c;
      background-color: #ffeeef;
      height: 40px;
      width: 80px;
    }
  }
}

.tab_btn {
  font-size: 18px;
  border: none;
  background-color: #ff334c;
  height: 40px;
  width: 200px;

  label {
    margin: 0px 0px 0px 20px;
  }
}

.tab_index {
  margin-top: 30px;
  background-color: #fff;

  .home_tabs {
    height: 40px;
    margin-left: 170px;
  }
}

.Jump_home {
  cursor: pointer;
}
</style>